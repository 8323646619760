import { Component, OnInit, ElementRef } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MatSnackBar } from '@angular/material';
import { DataService } from '../../shared/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SecurityService } from '../../security/security.service';
import { LoginModel } from '../../shared/login.model';
import { IRentalProductBookings } from '../../shared/Interfaces/ILogin';

@Component({
  selector: 'app-otp-login',
  templateUrl: './otp-login.component.html',
  styleUrls: ['./otp-login.component.css']
})
export class OtpLoginComponent implements OnInit {

  ///api/OtpLogin/?user=&code=
  constructor(private ngxUiLoaderService: NgxUiLoaderService, private _snackBar: MatSnackBar, private element: ElementRef, private route: ActivatedRoute,
    private dataService: DataService, private router: Router, private securityService: SecurityService) {
    this.route.queryParams.subscribe(params => {
      let url = params['returnUrl'];
      this.returnUrl = url;
    });
  }

  loginCred: LoginModel = new LoginModel();
  returnUrl: string;

  comingFromProduct: boolean = false;

  user: number;
  orderId: number;
  code: string;


  ngOnInit() {

    this.code = (this.route.snapshot.params['code']).toString();
    this.user = Number(this.route.snapshot.params['user']);
    this.orderId = Number(this.route.snapshot.params['orderId']);

    this.securityService.logout();

    this.login();
  }


  login(): void {

    this.ngxUiLoaderService.start();
    this.securityService.loginOtp(this.user, this.code)
      .subscribe(
        resp => {
          this.ngxUiLoaderService.stop();
          if (resp.isAuthenticated) {

            try {
              localStorage.setItem("isOTPLoggedIn", "Yes");
            } catch (e) {

            }

            this.router.navigate(["/invoiceSign", this.orderId]);
          }
          else {
            let message = "Invalid email or password";
            this._snackBar.open(message, "OK", {
              duration: 2000,
            });
            this.router.navigate(["/login"]);
          }
        },
        (err: any) => {
          this.ngxUiLoaderService.stop();
        }
      )
  }

  showLoginFailedError() {
    let message = "Invalid email or password";
    this._snackBar.open(message, "OK", {
      duration: 2000,
    });
    this.router.navigate(["/login"]);
  }

}
