import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { ResponseClassWCode, DeliveryAddress } from '../../../shared/Interfaces/ILogin';
import { NgForm, FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DataService } from '../../../shared/data.service';

@Component({
  selector: 'app-edit-delivery-address',
  templateUrl: './edit-delivery-address.component.html',
  styleUrls: ['./edit-delivery-address.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class EditDeliveryAddressComponent implements OnInit {

  address: DeliveryAddress = new DeliveryAddress();

  form: FormGroup;

  driverName: string;

  driverControl = new FormControl('', [Validators.required]);

  PostalCodeDNE = false;

  response;

  constructor(private _snackBar: MatSnackBar, public fb: FormBuilder, private dataService: DataService,
    private dialogRef: MatDialogRef<EditDeliveryAddressComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
    this.address = data.address;
  }

  ngOnInit() {
    this.form = this.fb.group({
      driver: this.address
    });
  }

  onSubmit(form: NgForm) {

    if (this.address.fullName == undefined || this.address.fullName == '') {
      let message = "Please enter fullname";
      let action = "Ok";
      this._snackBar.open(message, action, {
        duration: 20000,
      });
    }
    else if (this.address.addressLine1 == undefined || this.address.addressLine1 == '') {
      let message = "Please enter addressLine 1";
      let action = "Ok";
      this._snackBar.open(message, action, {
        duration: 20000,
      });
    }
    else if (this.address.city == undefined || this.address.city == '') {
      let message = "Please enter city";
      let action = "Ok";
      this._snackBar.open(message, action, {
        duration: 20000,
      });
    }
    else if (this.address.postalCodeZip == undefined || this.address.postalCodeZip.trim() == '') {
      let message = "Please enter postal code";
      let action = "Ok";
      this._snackBar.open(message, action, {
        duration: 20000,
      });
    }
    else if (this.address.stateProvince == undefined || this.address.stateProvince == '') {
      let message = "Please enter State/Province";
      let action = "Ok";
      this._snackBar.open(message, action, {
        duration: 20000,
      });
    }
    else {

      this.saveChanges()
    }
  }

  saveChanges(): void {
    this.dataService.updateCustomerDeliveryAddress(this.address).subscribe(
      (data: ResponseClassWCode) => {
        //this.driver.addressId = data.a;
        //this.dialogRef.close(this.form.value);
        if (data.code == 200) {

          this.dialogRef.close(this.form.value);
          this.PostalCodeDNE = false;
        }
        else {
          this.PostalCodeDNE = true;
          let message = data.result;

          this.response = data.result;

          let action = "Ok";
          this._snackBar.open(message, action, {
            duration: 20000,
          });
        }
      },
      (err: any) => console.log(err)
    );
  }

}
