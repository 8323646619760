import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-delivery-policy',
  templateUrl: './delivery-policy.component.html',
  styleUrls: ['./delivery-policy.component.css']
})
export class DeliveryPolicyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
