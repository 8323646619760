import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-edmonton',
  templateUrl: './edmonton.component.html',
  styleUrls: ['./edmonton.component.css']
})
export class EdmontonComponent implements OnInit {

  constructor(private router: Router) {

    //this.router.navigate(["home", "edmonton"]);
    localStorage.setItem("currentPostalCode", 'T5C');
    localStorage.setItem("comingFromFranchise", 'Yes');
  }

  ngOnInit() {
  }

}
