import { Component, OnInit, ElementRef, OnDestroy, PLATFORM_ID, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { SecurityService } from '../security/security.service';
import { DataService } from '../shared/data.service';
import { ILogin, IRentalProductBookings } from '../shared/Interfaces/ILogin';
import { LoginModel } from '../shared/login.model';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Title } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(@Inject(PLATFORM_ID) private _platformId: Object, private titleService: Title, private ngxUiLoaderService: NgxUiLoaderService, private _snackBar: MatSnackBar, private element: ElementRef, private route: ActivatedRoute,
    private dataService: DataService, private router: Router, private securityService: SecurityService) {
    this.titleService.setTitle("TheRentalGuys.Ca - Please Login");
    this.route.queryParams.subscribe(params => {
      let url = params['returnUrl'];
      this.returnUrl = url;
    });
  }

  loginCred: LoginModel = new LoginModel();
  returnUrl: string;

  comingFromProduct: boolean = false;

  addToCart: boolean = false;

  ngOnInit() {
    if (isPlatformBrowser(this._platformId)) {
      this.comingFromProduct = Boolean(localStorage.getItem("comingFromProduct"));


      this.addToCart = Boolean(localStorage.getItem("addToCart"));

      if (this.comingFromProduct) {
        var retrievedObject = localStorage.getItem('booking');
        let booking: IRentalProductBookings = JSON.parse(retrievedObject);

        this.loginCred.comingFromProduct = this.comingFromProduct;
        this.loginCred.productType = localStorage.getItem("bookingType");
        this.loginCred.days = booking.totalNumberOfDays;
        this.loginCred.location = localStorage.getItem("currentDeliveryCity");
        this.loginCred.productId = booking.productId;
        this.loginCred.quantity = booking.quantity;
        this.loginCred.startDate = booking.startDate;

      }

      let currentUsername = localStorage.getItem("username");
      let currentPassword = localStorage.getItem("password");

      if (currentPassword != undefined && currentPassword != "" &&
        currentUsername != undefined && currentUsername != "") {
        this.loginCred.Email = currentUsername;
        this.loginCred.Password = currentPassword;
      }

      this.securityService.logout();
    }
  }

  responseMsg;

  login(): void {
    localStorage.setItem("RefreshRequired", "Yes");

    if (this.loginCred.Email != ""
      && this.loginCred.Email != undefined
      && this.loginCred.Password != ""
      && this.loginCred.Password != undefined) {
      this.ngxUiLoaderService.start();

      let unloggedInCustomer = Number(localStorage.getItem("unloggedInCustomer"));

      if (unloggedInCustomer != undefined) {
        this.loginCred.tempCustomerId = unloggedInCustomer;
        this.securityService.loginUnloggedIn(this.loginCred)
          .subscribe(
            resp => {
              this.ngxUiLoaderService.stop();
              if (resp.isAuthenticated) {

                if (this.comingFromProduct) {

                  localStorage.removeItem("comingFromProduct");
                  localStorage.removeItem('booking');
                  localStorage.removeItem('addToCart');


                  if (this.addToCart) {
                    this.router.navigate(["/home"]);
                  }
                  else {
                    localStorage.setItem("customerId", JSON.stringify(resp.customer.customerId));
                    this.router.navigate(["/checkout"]);
                  }
                }
                else if (this.returnUrl != "" && this.returnUrl != null) {
                  this.router.navigate([this.returnUrl]);
                } else {
                  this.router.navigate(['/']);
                }

                try {
                  localStorage.removeItem("unloggedInCustomer");
                } catch (e) {

                }

              }
              else {
                this.responseMsg = resp.response;
                let message = resp.response;
                this._snackBar.open(message, "OK", {
                  duration: 2000,
                });
              }
            },
            (err: any) => {
              this.ngxUiLoaderService.stop();
            }
          )
      }
      else {
        this.securityService.login(this.loginCred)
          .subscribe(
            resp => {
              this.ngxUiLoaderService.stop();
              if (resp.isAuthenticated) {

                if (this.comingFromProduct) {

                  localStorage.removeItem("comingFromProduct");
                  localStorage.removeItem('booking');
                  localStorage.removeItem('addToCart');

                  if (this.addToCart) {
                    localStorage.setItem("customerId", JSON.stringify(resp.customer.customerId));
                    this.router.navigate(["/home"]);
                  }
                  else {
                    localStorage.setItem("customerId", JSON.stringify(resp.customer.customerId));
                    this.router.navigate(["/checkout"]);
                  }
                }
                else if (this.returnUrl != "" && this.returnUrl != null) {
                  this.router.navigate([this.returnUrl]);
                } else {
                  localStorage.setItem("customerId", JSON.stringify(resp.customer.customerId));
                  this.router.navigate(['/']);
                }

              }
              else {
                this.responseMsg = resp.response;
                let message = resp.response;
                this._snackBar.open(message, "OK", {
                  duration: 2000,
                });
              }
            },
            (err: any) => {
              this.ngxUiLoaderService.stop();
            }
          )
      }
    }
    else {
      let message = "Please enter email and password";
      this._snackBar.open(message, "OK", {
        duration: 2000,
      });
    }
  }

  showLoginFailedError() {
    let message = "Invalid email or password";
    this._snackBar.open(message, "OK", {
      duration: 2000,
    });
  }
}
