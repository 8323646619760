import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DataService } from '../shared/data.service';
import { Router } from '@angular/router';
import { customerOrdersList } from '../shared/Interfaces/IInterfaces';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class OrdersComponent implements OnInit {

  constructor(private titleService: Title, private ngxUiLoaderService: NgxUiLoaderService,private router: Router, private dataService: DataService) {
    dataService.getCurrentCart('orders');
    this.titleService.setTitle("TheRentalGuys.Ca - All Orders");





  }

  customerId;
  customerOrder: customerOrdersList;
  ngOnInit() {

    
    this.customerId = Number(localStorage.getItem("customerId"));
    this.ngxUiLoaderService.start();
    this.dataService.getCustomerOrders(this.customerId)
      .subscribe(
        (data: customerOrdersList) => {
          this.ngxUiLoaderService.stop();
          this.customerOrder = data;
        },
        (err: any) => console.log(err),
        () => {
          this.ngxUiLoaderService.stop();
          console.log("postalcode Data Recived");
        }
      );
  }

}
