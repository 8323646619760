import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatDialog } from '@angular/material';
import { SecurityService } from '../../security/security.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../shared/data.service';
import { responseCustomerVerify, responseCustomerId } from '../../shared/Interfaces/ILogin';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-itemAddedDialog',
  templateUrl: './itemAddedDialog.component.html',
  styleUrls: ['./itemAddedDialog.component.css'], encapsulation: ViewEncapsulation.None
})
export class ItemAddedDialogComponent implements OnInit {

  constructor(public dialog: MatDialog, private ngxUiLoaderService: NgxUiLoaderService,
    private route: ActivatedRoute,
    private router: Router, private dataService: DataService,
    private securityService: SecurityService) {
  }

  ngOnInit() {
    localStorage.setItem("continueShopping", 'true');
  }

}
