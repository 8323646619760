import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { ICompany, ICompanyData } from '../shared/Interfaces/ILogin';
import { DataService } from '../shared/data.service';
import { Title } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  constructor(@Inject(PLATFORM_ID) private _platformId: Object,private titleService: Title, private dataService: DataService) {
    this.titleService.setTitle("About The Rental Guys in Alberta & Ontario");
  }

  company: ICompany;
  companyData: ICompanyData;

  ngOnInit() {
    if (isPlatformBrowser(this._platformId)) {
      this.getCompany();
    }
    //var retrievedObject = localStorage.getItem('company');
    //if (retrievedObject != undefined && retrievedObject != null) {
    //  this.companyData = JSON.parse(retrievedObject);
    //  this.company = this.companyData.company;
    //}
    //else {
    //  this.getCompany();
    //}
  }

  getCompany() {
    //localStorage.removeItem('company');
    this.dataService.getCompanyData(true)
      .subscribe(
        (data: ICompanyData) => {
          localStorage.setItem('company', JSON.stringify(data));
          localStorage.setItem('headerLogo', data.headerLogo);
          localStorage.setItem('headerLogoMobile', data.headerLogoMobile);
          localStorage.setItem('footerLogo', data.footerLogo);
          localStorage.setItem('contactUsBackgroundUrl', data.contactUsBackgroundUrl);
          localStorage.setItem('videoUrl', data.videoUrl);
          localStorage.setItem('homePageTopBackgroundUrl', data.homePageTopBackgroundUrl);
          this.company = data.company;
        },
        (err: any) => console.log(err),
        () => {
          console.log("");
        }
      );
  }

}
