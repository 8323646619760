import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-rental-agreement',
  templateUrl: './rental-agreement.component.html',
  styleUrls: ['./rental-agreement.component.css']
})
export class RentalAgreementComponent implements OnInit {

  constructor(private titleService: Title, ) {
    this.titleService.setTitle("TheRentalGuys.Ca - Rental Agreement");
  }

  ngOnInit() {
  }

}
