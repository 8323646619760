import { Component, ChangeDetectorRef, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { DataService } from '../shared/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SecurityService } from '../security/security.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { ICheckout, ICompany, ICompanyData } from '../shared/Interfaces/ILogin';
import { Title } from '@angular/platform-browser';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { NgxUiLoaderService } from 'ngx-ui-loader';

declare var $: any;

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit {
  isExpanded = false;
  IsLoggedIn = false;
  noOfItemsInCart = 0;
  total = 0;
  subTotal = 0;
  checkout: ICheckout = new ICheckout();
  IsDeliveryOptionSelected = false;
  IsDeliveryAddressEntered = false;
  IsDeliverySlotSelected = false;
  IsSigned = false;
  IsAgreementChecked = true;
  IsValidDeliveryAddress = false;
  IsLimitedOptionSelected = false;
  IsLimitedAccepetd = false;
  FoundUsOnSelected = false;
  customerId;
  isAlive = true;
  TotalTax = 0;
  TotalCost = 0;
  TotalProductCost = 0;
  DeliverySlotCost = 0;
  DeliveryCharge = 0;
  totalLimitedDamage = 0;
  TaxclassTxt = "";


  showCart() {
    this.dataService.changeShowCartValue(true);
  }

  loadScripts() {

    const dynamicScripts = [
      //'/assets/Scripts/jquery.min.js',
      //'/assets/Scripts/jquery-ui.js',
      //'/assets/Scripts/datepicker.js',
      /*'https://maps.googleapis.com/maps/api/js?key=AIzaSyAqZKz5nGJ1fA5viy9H2qfIuBo7tqEqRas&libraries=places&language=en',*/
      'https://www.googletagmanager.com/gtag/js?id=UA-115005957-1',
      ''
    ];

    for (let i = 0; i < dynamicScripts.length; i++) {
      setTimeout(() => {
        const node = document.createElement('script');
        node.src = dynamicScripts[i];
        node.type = 'text/javascript';
        node.async = true;
        node.defer = true;
        node.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(node);
      }, 6000);
    }


  }

  loadScriptsWithText() {
    const dynamicScripts = [
      "window.dataLayer = window.dataLayer || [];		function gtag() { dataLayer.push(arguments); }gtag('js', new Date());gtag('config', 'UA-115005957-1');",
      "window.__lc = window.__lc || {};  window.__lc.license = 10462882;    (function () {      var lc = document.createElement('script'); lc.type = 'text/javascript'; lc.async = true;      lc.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'cdn.livechatinc.com/tracking.js';      var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(lc, s);  })();"
    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      setTimeout(() => {
        const node = document.createElement('script');
        //node.src = dynamicScripts[i];
        node.type = 'text/javascript';
        node.async = true;
        node.defer = true;
        node.innerText = dynamicScripts[i];
        node.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(node);
      }, 6000);
    }
  }

  loadStyle(styleName: string) {
    const head = this.document.getElementsByTagName('head')[0];

    let themeLink = this.document.getElementById(
      styleName
    ) as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = styleName;
    } else {
      const style = this.document.createElement('link');
      style.id = styleName;
      style.rel = 'stylesheet';
      style.href = `${styleName}`;

      head.appendChild(style);
    }
  }

  unloggedInCustomer = 0;
  unloggedIn = false;

  constructor(private ngxUiLoaderService: NgxUiLoaderService, @Inject(DOCUMENT) private document: Document, @Inject(PLATFORM_ID) private _platformId: Object, changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, private _snackBar: MatSnackBar, private route: ActivatedRoute,
    private dataService: DataService, private router: Router, private securityService: SecurityService) {

    if (isPlatformBrowser(this._platformId)) {
      this.mobileQuery = media.matchMedia('(max-width: 600px)');
      this._mobileQueryListener = () => changeDetectorRef.detectChanges();
      this.mobileQuery.addListener(this._mobileQueryListener);

      this.getcheckout();

      dataService.currentNoInCart.subscribe(total => {
        if (total != undefined) {
          this.noOfItemsInCart = total;
        }
        else {
          this.noOfItemsInCart = 0;
        }
      });

      dataService.totalPriceCart.subscribe(total => {
        if (total != undefined) {
          this.subTotal = total;
        }
        else {
          this.subTotal = 0;
        }
      });

      dataService.currentNoInCart.subscribe(total => this.noOfItemsInCart = total);

      //this.showCart();

      securityService.getLoggedIn.subscribe(name => this.logIn());

      this.unloggedInCustomer = Number(localStorage.getItem("unloggedInCustomer"));

      if (this.unloggedInCustomer != undefined) {
        this.unloggedIn = true;
        this.getCart();
      }

      let ss = securityService.checkLoginStatus();
      if (ss) {
        this.IsLoggedIn = true;
        this.getCart();
      }
    }
  }

  company: ICompany;
  companyWithoutLinks: ICompany;

  videoUrl: string = '';
  contactUsBackgroundUrl: string = '';
  headerLogo: string = '';
  headerLogoMobile: string = '';
  footerLogo: string = '';
  homePageTopBackgroundUrl: string = '';

  numWithoutDashes;

  menuWidth = 167;
  oneMenuTab = 152;

  marginLeft = -75;

  lazyLoadImage(image: string) {
    setTimeout(() => {
      return image;
    }, 3000);
  }

  logo = "../../assets/theme/headerLogo/logo.png";
  mobileLog0 = "../../assets/theme/headerLogoMobile/headerLogoMobile.png";

  home_mobile = "";
  about_mobile = "";
  faq_mobile = "";
  franchise_mobile = "";
  contact_mobile = "";

  account_mobile = "";
  billing_mobile = "";
  orders_mobile = "";
  renewal_mobile = "";
  quote_mobile = "";
  logout_mobile = "";
  login_mobile = "";

  profileImg = "";
  billingImg = "";
  orderImg = "";
  autoImg = "";
  quoteImg = "";
  loginImg = "";
  logoutImg = "";

  termImg = "";
  privacyImg = "";
  cancellationImg = "";
  operationManualImg = "";
  contactUsImg = "";

  isLoaded = false;

  loadCss() {
    this.ngxUiLoaderService.start();
    setTimeout(() => {
      try {
        //this.loadStyle("/assets/css/latofonts.css");
      } catch (e) {

      }

      try {
        this.ngxUiLoaderService.stop();
        //this.loadStyle("/assets/css/fonts/font-awesome/css/font-awesome.css");
        this.isLoaded = true;
      } catch (e) {

      }

      this.isLoaded = true;
    }, 100);
  }

  loadCssMobile() {
    setTimeout(() => {
      this.isLoaded = true;
    }, 100);


    setTimeout(() => {
      try {
        this.loadStyle("/assets/css/latofonts.css");
      } catch (e) {

      }

      try {
        //this.ngxUiLoaderService.stop();
        this.loadStyle("/assets/css/fonts/font-awesome/css/font-awesome.css");
        this.isLoaded = true;
      } catch (e) {

      }

      this.isLoaded = true;
    }, 3000);
  }

  ngOnInit() {
    if (isPlatformBrowser(this._platformId)) {



      //setTimeout(() => { 
      //  try {
      //    this.loadStyle("/assets/css/latofonts.css");
      //  } catch (e) {

      //  }

      //  try {
      //    this.ngxUiLoaderService.stop();
      //    this.loadStyle("/assets/css/fonts/font-awesome/css/font-awesome.css");
      //    this.isLoaded = true;
      //  } catch (e) {

      //  }

      //  this.isLoaded = true;
      //}, 2500);

      try {
        let tempThis = this;
        $(document).ready(function () {

          if (window.matchMedia('(max-width: 380px)').matches) {
            tempThis.loadCssMobile();
          }

          else if (window.matchMedia('(max-width: 580px)').matches) {
            tempThis.loadCssMobile();
          }

          else if (window.matchMedia('(max-width: 680px)').matches) {
            tempThis.loadCssMobile();
          }

          else if (window.matchMedia('(max-width: 850px)').matches) {
            tempThis.loadCssMobile();
          }
          else {
            tempThis.loadCss();
          }
        });

      } catch (e) {

      }

      //setTimeout(() => {
      //  try {

      //  } catch (e) {

      //  }
      //}, 1000);


      //setTimeout(() => {

      //}, 3000);

      //setTimeout(() => {

      //}, 2000);

      setTimeout(() => {
        this.loadScripts();
        this.loadScriptsWithText();
      }, 1000);

      setTimeout(() => {

        try {

          this.logo = "../../assets/theme/headerLogo/logo.png";
          this.mobileLog0 = "../../assets/theme/headerLogoMobile/headerLogoMobile.png";

          this.home_mobile = "../../assets/images/HomeProducts/homen.png";
          this.about_mobile = "../../assets/images/HomeProducts/about.png";
          this.faq_mobile = "../../assets/images/HomeProducts/faq.PNG";
          this.franchise_mobile = "../../assets/images/HomeProducts/franchise.png";
          this.contact_mobile = "../../assets/images/HomeProducts/cu.png";

          this.account_mobile = "../../assets/images/HomeProducts/m1.png";
          this.billing_mobile = "../../assets/images/HomeProducts/m2.png";
          this.orders_mobile = "../../assets/images/HomeProducts/m3.png";
          this.renewal_mobile = "../../assets/images/HomeProducts/renew.PNG";
          this.quote_mobile = "../../assets/images/HomeProducts/m4.png";
          this.logout_mobile = "../../assets/images/HomeProducts/m6.png";
          this.login_mobile = "../../assets/images/HomeProducts/m5.PNG";


          this.profileImg = "../../assets/images/HomeProducts/profile.png";
          this.billingImg = "../../assets/images/HomeProducts/billing.png";
          this.orderImg = "../../assets/images/HomeProducts/orders.png";
          this.autoImg = "../../assets/images/HomeProducts/iauto.PNG";
          this.quoteImg = "../../assets/images/HomeProducts/quotes.png";
          this.loginImg = "../../assets/images/HomeProducts/logingear.png";
          this.logoutImg = "../../assets/images/HomeProducts/Logout.png";

          this.termImg = "../../assets/images/HomeProducts/i1b.png";
          this.privacyImg = "../../assets/images/HomeProducts/i2.png";
          this.cancellationImg = "../../assets/images/HomeProducts/i3.png";
          this.operationManualImg = "../../assets/images/HomeProducts/i5.png";
          this.contactUsImg = "../../assets/images/HomeProducts/i5b.png";
        } catch (e) {

        }

        if (isPlatformBrowser(this._platformId)) {
          var retrievedObject = localStorage.getItem('company');
          if (retrievedObject != undefined && retrievedObject != null) {
            let companydata = JSON.parse(retrievedObject);
            //this.titleService.setTitle(this.company.pageTitle);

            this.company = companydata.company;

            this.headerLogo = localStorage.getItem('headerLogo');
            this.headerLogoMobile = localStorage.getItem('headerLogoMobile');
            this.footerLogo = localStorage.getItem('footerLogo');
            this.contactUsBackgroundUrl = localStorage.getItem('contactUsBackgroundUrl');
            this.videoUrl = localStorage.getItem('videoUrl');
            this.homePageTopBackgroundUrl = localStorage.getItem('homePageTopBackgroundUrl');

            if (this.company.showTermsPage) {
              this.menuWidth = this.menuWidth + this.oneMenuTab;
              this.marginLeft = this.marginLeft - this.oneMenuTab;
            }
            if (this.company.showPrivacyPage) {
              this.menuWidth = this.menuWidth + this.oneMenuTab;
              this.marginLeft = this.marginLeft - this.oneMenuTab;
            }
            if (this.company.showCancellationPage) {
              this.menuWidth = this.menuWidth + this.oneMenuTab;
              this.marginLeft = this.marginLeft - this.oneMenuTab;
            }
            if (this.company.showOperationsManualPage) {
              this.menuWidth = this.menuWidth + this.oneMenuTab;
              this.marginLeft = this.marginLeft - this.oneMenuTab;
            }
          }
          else {
            this.getCompany();
          }
        } else {
          this.getCompany();
        }

        $(document).mouseup(function (e) {
          var container = $(".flyout");

          if (!container.is(e.target) && container.has(e.target).length === 0) {
            container.hide();
          }
        });

        $(document).mouseup(function (e) {
          var container = $(".flyoutMyAccount");

          if (!container.is(e.target) && container.has(e.target).length === 0) {
            container.hide();
          }
        });

        $(document).on('click', function (e) {
          if ($(e.target).closest(".myAccountMobile").length === 0) {
            $(".MyAccountUl").hide();
          }
        });

        $(document).on('click', function (e) {
          if ($(e.target).closest(".MobileMenu").length === 0) {
            $(".MyMenuUl").hide();
          }
        });

        $(document).ready(function () {
          //$('.MyAccountUl').hide();
          //$('.MyAccountUl').show();

          var isVisible = false;
          $(".MyAccountMenu").click(function () {
            if (!isVisible) {
              $('.MyAccountUl').show();
              isVisible = true;
            } else {
              $('.MyAccountUl').hide();
              isVisible = false;
            }
          });


          var isMyMenuVisible = false;
          $(".MyMenu").click(function () {
            if (!isVisible) {
              $('.MyMenuUl').show();
              isMyMenuVisible = true;
            } else {
              $('.MyMenuUl').hide();
              isMyMenuVisible = false;
            }
          });

          $("#menuOpen").click(function () {
            document.getElementById('menu').className = "sub-nav nav-ie" == document.getElementById('menu').className ? "sub-nav" : "sub-nav nav-ie";
            document.getElementById('container').className = "sub-nav nav-ie" == document.getElementById('container').className ? "sub-nav" : "sub-nav nav-ie";
          });


          //$(".MyAccountMenu").mouseleave(function () {
          //  $('.MyAccountUl').hide();
          //  $('.MyAccountMenu').show();
          //});



          $(".flyout").mouseleave(function () {
            $(this).hide();
          });


          $(".flyoutMyAccount").mouseleave(function () {
            $(this).hide();
          });



          $('.flyout').hide();
          $("#About").hover(function () {
            $('.flyoutMyAccount').hide();
            $('.flyout').show();

          }, function () {
            //$('.flyout').hide();
          });


          $('.flyoutMyAccount').hide();
          $("#myAccount").hover(function () {
            $('.flyout').hide();
            $('.flyoutMyAccount').show();

          }, function () {
            //$('.flyoutMyAccount').hide();
          });
        });
      }, 500);
    }
  }


  getCompany() {
    if (isPlatformBrowser(this._platformId)) {
      localStorage.removeItem('company');
    }

    this.dataService.getCompanyData(false)
      .subscribe(
        (data: ICompanyData) => {
          //this.titleService.setTitle(data.company.pageTitle);

          this.videoUrl = data.videoUrl;
          this.contactUsBackgroundUrl = data.contactUsBackgroundUrl;
          this.headerLogo = data.headerLogo;
          this.headerLogoMobile = data.headerLogoMobile;
          this.footerLogo = data.footerLogo;
          this.homePageTopBackgroundUrl = data.homePageTopBackgroundUrl;
          if (isPlatformBrowser(this._platformId)) {
            localStorage.setItem('company', JSON.stringify(data));
            localStorage.setItem('headerLogo', data.headerLogo);
            localStorage.setItem('headerLogoMobile', data.headerLogoMobile);
            localStorage.setItem('footerLogo', data.footerLogo);
            localStorage.setItem('contactUsBackgroundUrl', data.contactUsBackgroundUrl);
            localStorage.setItem('videoUrl', data.videoUrl);
            localStorage.setItem('homePageTopBackgroundUrl', data.homePageTopBackgroundUrl);
          }
          this.company = data.company;
          this.companyWithoutLinks = data.companyWithoutLinks;

          this.numWithoutDashes = data.company.helplineNo.replace('(', '').replace(')', '').replace('-', '').replace('-', '').replace('-', '')

          if (data.company.showTermsPage) {
            this.menuWidth = this.menuWidth + this.oneMenuTab;
            this.marginLeft = this.marginLeft - this.oneMenuTab;
          }
          if (data.company.showPrivacyPage) {
            this.menuWidth = this.menuWidth + this.oneMenuTab;
            this.marginLeft = this.marginLeft - this.oneMenuTab;
          }
          if (data.company.showCancellationPage) {
            this.menuWidth = this.menuWidth + this.oneMenuTab;
            this.marginLeft = this.marginLeft - this.oneMenuTab;
          }
          if (data.company.showOperationsManualPage) {
            this.menuWidth = this.menuWidth + this.oneMenuTab;
            this.marginLeft = this.marginLeft - this.oneMenuTab;
          }

        },
        (err: any) => console.log(err),
        () => {
          console.log("");
        }
      );
  }


  onImgError(event, img: string) {
    event.target.src = img;
  }



  private logIn(): void {
    this.IsLoggedIn = true;
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  logout(): void {
    this.securityService.logout();
    this.IsLoggedIn = false;
    $(document).ready(function () {
      $('.MyAccountUl').hide();
    });
    localStorage.setItem("RefreshRequired", "Yes");
    this.router.navigate(["/"]);
  }

  mobileQuery: MediaQueryList;

  fillerNav = Array.from({ length: 50 }, (_, i) => `Nav Item ${i + 1}`);

  fillerContent = Array.from({ length: 50 }, () =>
    `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
       labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
       laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
       voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
       cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`);

  private _mobileQueryListener: () => void;



  ngOnDestroy(): void {
    if (isPlatformBrowser(this._platformId)) {
      this.mobileQuery.removeListener(this._mobileQueryListener);
    }
  }

  getCart() {
    let customerId = localStorage.getItem("customerId");
    if (customerId != undefined) {
      this.dataService.getItemsInCart('navMenu')
        .subscribe(
          (data: number) => {
            console.log(data, 'cart value');
            this.noOfItemsInCart = data;
          },
          (err: any) => console.log(err),
          () => {
            console.log("cart Data Recived");
          }
        );
    }
  }

  showMenuLink(option: string) {
    this.router.navigate(["/" + option + ""]);
    $(document).ready(function () {
      $('.MyAccountUl').hide();
    });
  }

  goNav() {
    document.getElementById('menu').className = "sub-nav nav-ie" == document.getElementById('menu').className ? "sub-nav" : "sub-nav nav-ie";
    document.getElementById('container').className = "sub-nav nav-ie" == document.getElementById('container').className ? "sub-nav" : "sub-nav nav-ie";
  }

  showMobileMainMenu() {
    $(document).ready(function () {
      $('.MyMenuUl').show();
    });
  }

  getcheckout() {

    this.noOfItemsInCart = 0;
    this.total = 0;
    this.customerId = Number(localStorage.getItem("customerId"));

    this.dataService.getCheckout(this.customerId)
      .subscribe(
        (data: ICheckout) => {

          if (data != null) {
            this.checkout = data;
            this.calculateCost();


            if (this.totalItems != undefined) {
              this.noOfItemsInCart = this.totalItems;
            }
            else {
              this.noOfItemsInCart = 0;
            }

            if (this.totalItems != undefined) {
              this.total = this.subTotal;
            }
            else {
              this.total = 0;
            }

            this.dataService.changeCartValue(this.totalItems);
            this.dataService.changeCartTotalValue(this.subTotal);

          }
        },
        (err: any) => {
          console.log(err);


        },
        () => {
          console.log("checkout Data Recived");
        }
      );

  }

  totalItems;

  calculateCost(): void {
    try {
      var totalProduct = 0;

      for (let item of this.checkout.data) {
        totalProduct = totalProduct + Number(item.totalCost);
      }

      try {
        this.totalItems = 0;
        for (var orders of this.checkout.data) {
          for (var product of orders.order) {

            this.totalItems++;
          }
        }
      }
      catch (e) { }



      this.TotalProductCost = totalProduct;
      let tax = Number(this.checkout.franchiseTax.tax);
      this.TaxclassTxt = this.checkout.franchiseTax.name + "(" + tax + "%)";
      this.subTotal = this.TotalProductCost + this.DeliverySlotCost + this.DeliveryCharge + this.totalLimitedDamage;

      this.TotalTax = this.subTotal * tax / 100;
      this.TotalCost = this.subTotal + this.TotalTax;

    } catch (e) {

    }
  }




}


