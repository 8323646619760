import { Component, OnInit } from '@angular/core';
import { ICompany, ICompanyData } from '../shared/Interfaces/ILogin';
import { DataService } from '../shared/data.service';

@Component({
  selector: 'app-operations-manual',
  templateUrl: './operations-manual.component.html',
  styleUrls: ['./operations-manual.component.css']
})
export class OperationsManualComponent implements OnInit {

  constructor(private dataService: DataService) { }

  company: ICompany;
  companyData: ICompanyData;

  ngOnInit() {
    //var retrievedObject = localStorage.getItem('company');
    //if (retrievedObject != undefined && retrievedObject != null) {
    //  this.companyData = JSON.parse(retrievedObject);
    //  this.company = this.companyData.company;
    //}
    //else {
    //  this.getCompany();
    //}
  }

  getCompany() {
    localStorage.removeItem('company');
    this.dataService.getCompanyData(false)
      .subscribe(
        (data: ICompanyData) => {
          //data.company.headerLogo = this.dataService.baseurlAdmin + '/theme/headerLogo/' + data.company.headerLogo;
          //data.company.headerLogoMobile = this.dataService.baseurlAdmin + '/theme/headerLogoMobile/' + data.company.headerLogoMobile;
          //data.company.footerLogo = this.dataService.baseurlAdmin + '/theme/footerLogo/' + data.company.footerLogo;
          localStorage.setItem('company', JSON.stringify(data));
          this.company = data.company;
        },
        (err: any) => console.log(err),
        () => {
          console.log("");
        }
      );
  }

}
