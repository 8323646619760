import { Component, OnInit } from '@angular/core';
import { IContact, responseMsg } from '../../shared/Interfaces/IInterfaces';
import { DataService } from '../../shared/data.service';
import { MatSnackBar } from '@angular/material';
import { ICompanyData, ICompany, ICustomer } from '../../shared/Interfaces/ILogin';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';

declare var $: any;

@Component({
  selector: 'app-add-mobile',
  templateUrl: './add-mobile.component.html',
  styleUrls: ['./add-mobile.component.css']
})
export class AddMobileComponent implements OnInit {

  customer: ICustomer = new ICustomer();

  constructor(private ngxUiLoaderService: NgxUiLoaderService,private titleService: Title, private router: Router,  private _snackBar: MatSnackBar, private dataService: DataService) {
    this.titleService.setTitle("TheRentalGuys");
  }
  customerId;
  ngOnInit() {

    let tempThis = this;
    this.customerId = localStorage.getItem("customerId");

    this.ngxUiLoaderService.start();

    this.dataService.getCustomer(this.customerId)
      .subscribe(
        (data: ICustomer) => {
          this.ngxUiLoaderService.stop();
          this.customer = data;
        },
        (err: any) => console.log(err),
        () => {
          console.log("Data Recived");
        }
      );
  }

  UpdateCustomerMobile() {
    if (this.customer.mobileNo1 != undefined && this.customer.mobileNo1 !="") {
      this.ngxUiLoaderService.start();
      this.dataService.updateCustomer(this.customer)
        .subscribe(
          (data: responseMsg) => {
            let message = "Successfuly Updated";
            let action = "Ok";
            this._snackBar.open(message, action, {
              duration: 2000,
            });

            this.ngxUiLoaderService.stop();

            this.router.navigate(["/checkout"]);

          },
          (err: any) => console.log(err),
          () => {
            console.log("Data Recived");
          }
        );
    }
    else {
      let message = "Please enter Mobile Number";
      let action = "Ok";
      this._snackBar.open(message, action, {
        duration: 2000,
      });
    }
  }

}
  

 





