import { Component, ViewEncapsulation, OnInit, Inject, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { DeliveryAddress, ResponseClassWCode, ICustomer } from '../../../shared/Interfaces/ILogin';
import { NgForm, FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { DataService } from '../../../shared/data.service';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';

@Component({
  selector: 'app-add-delivery-address',
  templateUrl: './add-delivery-address.component.html',
  styleUrls: ['./add-delivery-address.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AddDeliveryAddressComponent implements OnInit {

  address: DeliveryAddress = new DeliveryAddress();

  form: FormGroup;

  driverName: string;

  driverControl = new FormControl('', [Validators.required]);

  customerId: number;
  franchsieId: number;

  PostalCodeDNE = false;

  response;

  @ViewChild("placesRef", { static: true }) placesRef: GooglePlaceDirective;

  public handleAddressChange(address: Address) {
    console.log(address);

    for (let component of address.address_components) {
      for (let type of component.types) {
        if (type == "postal_code") {
          this.address.postalCodeZip = component.long_name;
        }

        if (type == "locality") {
          this.address.city = component.long_name;
        }

        if (type == "administrative_area_level_1") {
          this.address.stateProvince = component.long_name;
        }

        if (type == "street_number") {
          this.address.addressLine1 = component.long_name;
        }

        if (type == "route") {
          this.address.addressLine1 = this.address.addressLine1 + " " + component.long_name;
        }

      }
    }
  }

  

  constructor(private _snackBar: MatSnackBar, public fb: FormBuilder, private dataService: DataService,
    private dialogRef: MatDialogRef<AddDeliveryAddressComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
    this.customerId = data.customerId;
    this.franchsieId = data.franchsieId;
  }

  ngOnInit() {

    this.address.fullName = "";

    this.form = this.fb.group({
      driver: this.address
    });

    try {
      this.dataService.getCustomer(this.customerId)
        .subscribe(
          (data: ICustomer) => {
            this.address.fullName = data.firstName + " " + data.lastName;
            this.address.telephoneNumber = data.phone;
          },
          (err: any) => console.log(err),
          () => {
            console.log("Data Recived");
          }
        );
    } catch (e) {

    }
  }

  onSubmit(form: NgForm) {
    if (this.address.fullName == undefined || this.address.fullName == '') {
      let message = "Please enter fullname";
      let action = "Ok";
      this._snackBar.open(message, action, {
        duration: 20000,
      });
    }
    else if (this.address.addressLine1 == undefined || this.address.addressLine1 == '') {
      let message = "Please enter addressLine 1";
      let action = "Ok";
      this._snackBar.open(message, action, {
        duration: 20000,
      });
    }
    else if (this.address.city == undefined || this.address.city == '') {
      let message = "Please enter city";
      let action = "Ok";
      this._snackBar.open(message, action, {
        duration: 20000,
      });
    }
    else if (this.address.postalCodeZip == undefined || this.address.postalCodeZip.trim() == '') {
      let message = "Please enter postal code";
      let action = "Ok";
      this._snackBar.open(message, action, {
        duration: 20000,
      });
    }
    else if (this.address.stateProvince == undefined || this.address.stateProvince == '') {
      let message = "Please enter State/Province";
      let action = "Ok";
      this._snackBar.open(message, action, {
        duration: 20000,
      });
    }
    else {

      this.saveChanges()
    }
  }

  saveChanges(): void {

    this.address.customerId = this.customerId;
    this.address.franchiseId = this.franchsieId;
    this.address.isDefault = true;

    this.dataService.addCustomerDeliveryAddress(this.address).subscribe(
      (data: ResponseClassWCode) => {

        if (data.code == 200) {

          this.dialogRef.close(this.form.value);
          this.PostalCodeDNE = false;
        }
        else {
          this.PostalCodeDNE = true;

          this.response = data.result;

          let message = data.result;
          let action = "Ok";
          this._snackBar.open(message, action, {
            duration: 20000,
          });
        }
      },
      (err: any) => console.log(err)
    );
  }


}
