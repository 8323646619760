import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { ICheckout, ICustomer } from '../../shared/Interfaces/ILogin';
import { DataService } from '../../shared/data.service';
import { Title } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-order-confirmation',
  templateUrl: './order-confirmation.component.html',
  styleUrls: ['./order-confirmation.component.css']
})
export class OrderConfirmationComponent implements OnInit {

  checkout: ICheckout = new ICheckout();

  constructor(@Inject(PLATFORM_ID) private _platformId: Object, private titleService: Title, private dataService: DataService) {
    if (isPlatformBrowser(this._platformId)) {
      var retrievedObject = localStorage.getItem('checkout');
      this.checkout = JSON.parse(retrievedObject);
      dataService.getCurrentCart('orderConfirmation');
    }
    this.titleService.setTitle("TheRentalGuys.Ca - Order Confirmation");

  }

  customerName;
  productsList="";
  customerId;

  ngOnInit() {

    if (isPlatformBrowser(this._platformId)) {

      this.customerId = Number(localStorage.getItem("customerId"));
      if (this.customerId != undefined) {
        this.dataService.getCustomer(this.customerId)
          .subscribe(
            (data: ICustomer) => {
              this.customerName = data.firstName.toUpperCase() + " " + data.lastName.toUpperCase();

            },
            (err: any) => console.log(err),
            () => {
              console.log("Data Recived");
            }
          );

      }
    }
  }

  downloadRecipt(id) {
    this.dataService.downloadPDF(id);
  }

}
