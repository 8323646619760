import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, NgForm } from '@angular/forms';
import { MatSnackBar, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DataService } from '../../shared/data.service';
import { IFranchsiePosalCode } from '../../shared/Interfaces/ILogin';
import { responseId } from '../../shared/Interfaces/IInterfaces';
import { Router } from '@angular/router';

@Component({
  selector: 'app-postal-code-franchise-dialog',
  templateUrl: './postal-code-franchise-dialog.component.html',
  styleUrls: ['./postal-code-franchise-dialog.component.css']
})
export class PostalCodeFranchiseDialogComponent implements OnInit {

  form: FormGroup;
  location;
  HasResult = false;
  postalcodeResult: IFranchsiePosalCode = new IFranchsiePosalCode();
  customerId;


  constructor(private router: Router,private dataService: DataService, private _snackBar: MatSnackBar,
    public fb: FormBuilder,
    private dialogRef: MatDialogRef<PostalCodeFranchiseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
    dataService.getCurrentCart('postalCode');
    //this.location = data.driver;
  }

  ngOnInit() {
    this.customerId = Number(localStorage.getItem("customerId"));

    this.form = this.fb.group({
      location: this.location
    });

    let temp = localStorage.getItem("currentPostalCode");

    if (temp != "" || temp != null)
    {
      this.searchPostalCode();
    }
  }

  onSubmit(form: NgForm) {
    if (form.valid) {
      this.searchPostalCode()
    }
  }

  searchPostalCode(): void {
    this.dataService.getAllPostalCode(this.location)
      .subscribe(
        (data: IFranchsiePosalCode) => {
          this.postalcodeResult = data;
          this.HasResult = true;
        },
        (err: any) => console.log(err),
        () => {
          console.log("postalcode Data Recived");
        }
      );
  }

  selectFranchise()
  {
    this.dialogRef.close();
    this.dataService.startQoute(this.customerId,this.postalcodeResult.franchiseId)
      .subscribe(
        (data: responseId) => {
          this.router.navigate(["/qouteDetails", data.id]);
        },
        (err: any) => console.log(err),
        () => {
          console.log("");
        }
      );
  }

}
