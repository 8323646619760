import { Component, OnInit } from '@angular/core';
import { SecurityService } from '../../security/security.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../../shared/data.service';
import { MatSnackBar } from '@angular/material';
import { responseMsg } from '../../shared/Interfaces/IInterfaces';
import { NgxUiLoaderService } from 'ngx-ui-loader';

//declare var $: any;

@Component({
  selector: 'app-complete-standard-rental',
  templateUrl: './complete-standard-rental.component.html',
  styleUrls: ['./complete-standard-rental.component.css']
})
export class CompleteStandardRentalComponent implements OnInit {

  constructor(private ngxUiLoaderService: NgxUiLoaderService, private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router, private dataService: DataService,
    private securityService: SecurityService) {
    dataService.getCurrentCart('changeDeliveryDate');
    this.orderId = parseInt(this.route.snapshot.params['id']);
  }
  
  orderId;
  currentDeliveryDate;
  newDeliveryDate: string;

  ngOnInit() {
    this.newDeliveryDate = localStorage.getItem("endBillingDate");
  }


  completeStandardRental() {
    if (this.newDeliveryDate != null) {
      this.ngxUiLoaderService.start();
      this.dataService.completeStandardRental(this.orderId, this.newDeliveryDate)
        .subscribe(
          (data: responseMsg) => {
            this.ngxUiLoaderService.stop();
            let message = "Successfully Updated";
            let action = "Ok";
            this._snackBar.open(message, action, {
              duration: 2000,
            });

            this.router.navigate(['/ordersDetail/' + this.orderId]);
          },
          (err: any) => {
            this.ngxUiLoaderService.stop();
            console.log(err);
          },
          () => {
            console.log("Data Recived");
          }
        );
    }
    else {
      let message = "Please select date";
      let action = "Ok";
      this._snackBar.open(message, action, {
        duration: 2000,
      });
    }
  }

}
