import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar, MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { SecurityService } from '../../security/security.service';
import { DataService } from '../../shared/data.service';
import { responseMsg } from '../../shared/Interfaces/IInterfaces';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { FormBuilder } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DeliveryAddress } from '../../shared/Interfaces/ILogin';
import { EditDeliveryAddressComponent } from '../../my-dashboard/delivery-address-list/edit-delivery-address/edit-delivery-address.component';
import { AddDeliveryAddressOrderComponent } from './add-delivery-address-order/add-delivery-address-order.component';
import { ChangeDeliveryAddressOrderComponent } from './change-delivery-address-order/change-delivery-address-order.component';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-change-delivery-info',
  templateUrl: './change-delivery-info.component.html',
  styleUrls: ['./change-delivery-info.component.css']
})
export class ChangeDeliveryInfoComponent implements OnInit {

  deliveryAddress:string;
  postalCode: string;
  siteContactNumber: string;
  orderNotes: string;
  orderId;
  IsDockDelivery = false;
  IsGroundDelivery = true;

  franchiseId;
  address: DeliveryAddress;

  @ViewChild("placesRef", { static: true }) placesRef: GooglePlaceDirective;

  public handleAddressChange(address: Address) {
    console.log(address);

    for (let component of address.address_components) {
      for (let type of component.types) {
        if (type == "postal_code") {
          this.postalCode = component.long_name;
        }
      }
    }

    this.deliveryAddress = address.formatted_address;
  }

  dockDelivery(e: any) {
    this.dataService.dockDelivery(this.orderId, e.checked)
      .subscribe(
        (data: responseMsg) => {
          let message = "Successfully Updated";
          let action = "Ok";
          this._snackBar.open(message, action, {
            duration: 2000,
          });
        },
        (err: any) => console.log(err),
        () => {
          console.log("Data Recived");
        }
      );
  }

  myForm;

  constructor(private titleService: Title, private ngxUiLoaderService: NgxUiLoaderService,private fb: FormBuilder,private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router, private dataService: DataService,
    private securityService: SecurityService, public dialog: MatDialog) {
    dataService.getCurrentCart('changeDeliveryInfo');
    this.orderId = parseInt(this.route.snapshot.params['id']);
    this.titleService.setTitle("TheRentalGuys.Ca - Change Delivery Info");
    this.myForm = this.fb.group({
      options: ['0']
    })
  }

  ngOnInit() {
    this.deliveryAddress = localStorage.getItem("deliveryAddress");
    this.postalCode = localStorage.getItem("postalCode");
    this.siteContactNumber = localStorage.getItem("siteContactNumber");
    this.orderNotes = localStorage.getItem("orderNotes");

    if (this.orderNotes == 'null')
    {
      this.orderNotes = '';
    }

    if (this.postalCode == 'null') {
      this.postalCode = '';
    }

    let dock = localStorage.getItem("isDockDelivery");


    if (dock === 'true') {
      this.IsDockDelivery = true;
    }
    else
    {
      this.IsDockDelivery = false;
    }
    
    if (this.siteContactNumber == null || this.siteContactNumber == "null" )
    {
      this.siteContactNumber = "";
    }

    this.getOrderData();
  }

  getOrderData() {
    this.ngxUiLoaderService.start();

    this.dataService.SingleDeliveryAddresses(this.orderId)
      .subscribe(
        (data: DeliveryAddress) => {
          this.ngxUiLoaderService.stop();
          this.address = data;

          this.address.addressId;
        },
        (err: any) => {
          this.ngxUiLoaderService.stop();
          console.log(err);
        },
        () => {
          this.ngxUiLoaderService.stop();
          console.log("units Data Recived");
        }
      );
  }

  //updateDeliveryInfo() {
  //  this.ngxUiLoaderService.start();
  //  this.dataService.updateDelivery(this.orderId, this.deliveryAddress, this.postalCode, this.orderNotes, this.siteContactNumber)
  //    .subscribe(
  //      (data: responseMsg) => {
  //        this.ngxUiLoaderService.stop();
  //        let message = "Successfully Updated";
  //        let action = "Ok";
  //        this._snackBar.open(message, action, {
  //          duration: 2000,
  //        });

  //        this.router.navigate(['/orders/orderdetails/' + this.orderId]);
  //      },
  //      (err: any) => {
  //        console.log(err);
  //        this.ngxUiLoaderService.stop();
  //      },
  //      () => {
  //        console.log("Data Recived");
  //      }
  //    );
  //}

  editDeliveryAddress(): void {
    const dialogRef = this.dialog.open(EditDeliveryAddressComponent, {
      height: '750px',
      width: '510px',
      disableClose: true,
      data: {
        address: this.address
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if (result) {
        let message = "Update Successfully";
        let action = "Ok";
        this._snackBar.open(message, action, {
          duration: 20000,
        });

        this.getOrderData();
      }
    });
  }

  addDeliveryAddress(): void {
    const dialogRef = this.dialog.open(AddDeliveryAddressOrderComponent, {
      height: '750px',
      width: '510px',
      disableClose: true,
      data:
      {
        customerId: this.address.customerId,
        franchsieId: this.franchiseId,
        orderId: this.orderId
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if (result) {
        let message = "Added Successfully";
        let action = "Ok";
        this._snackBar.open(message, action, {
          duration: 20000,
        });
        this.getOrderData();
      }
    });
  }

  changeDeliveryAddress(): void {
    const dialogRef = this.dialog.open(ChangeDeliveryAddressOrderComponent, {
      height: '750px',
      width: '510px',
      disableClose: true,
      data:
      {
        customerId: this.address.customerId,
        franchiseId: this.franchiseId,
        addressId: this.address.addressId,
        orderId: this.orderId
      }
    });

    dialogRef.afterClosed().subscribe(result => {

      let message = "Changed Successfully";
      let action = "Ok";
      this._snackBar.open(message, action, {
        duration: 20000,
      });

      this.getOrderData();

    });
  }

  updateDeliveryInfo() {
    this.ngxUiLoaderService.start();
    this.dataService.updateDelivery(this.orderId, this.deliveryAddress, this.postalCode, this.orderNotes, this.siteContactNumber)
      .subscribe(
        (data: responseMsg) => {
          this.ngxUiLoaderService.stop();
          let message = "Successfully Updated";
          let action = "Ok";
          this._snackBar.open(message, action, {
            duration: 2000,
          });

          this.router.navigate(['/ordersDetail/' + this.orderId]);
        },
        (err: any) => {
          console.log(err);
          this.ngxUiLoaderService.stop();
        },
        () => {
          console.log("Data Recived");
        }
      );
  }

}
