import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import Swal from 'sweetalert2'
import { NgxUiLoaderService, Loader } from 'ngx-ui-loader';
import { BannerComponent } from '../../banner/banner.component';
import { IRentalProducts, IRentalProductBookings } from '../../shared/Interfaces/ILogin';
import { DataService } from '../../shared/data.service';
import { SecurityService } from '../../security/security.service';
import { Meta, Title } from '@angular/platform-browser';

declare var $: any;

@Component({
  selector: 'app-standard-rental',
  templateUrl: './standard-rental.component.html',
  styleUrls: ['./standard-rental.component.css']
})
export class StandardRentalComponent implements OnInit {

  @ViewChild('bannerIdentifier', { static: true }) banner: BannerComponent;

  ProductModel: IRentalProducts = new IRentalProducts();

  constructor(private titleService: Title, private meta: Meta,private _snackBar: MatSnackBar, private ngxUiLoaderService: NgxUiLoaderService,
    private route: ActivatedRoute,
    private router: Router, private dataService: DataService,
    private securityService: SecurityService) {
    this.ngxUiLoaderService.getDefaultConfig();

    dataService.getCurrentCart('rental');
    let ss = securityService.checkLoginStatus();
    if (ss) {
      this.IsLoggedIn = true;
    }
  }

  IsPageLoaded = false;
  productId = 0;
  franchiseId;
  DeliveryCharge = "$0";
  IsLoggedIn = false;
  booking: IRentalProductBookings = new IRentalProductBookings();
  DeliveryCity = "";

  franchiseName;
  productName;

  resetStartDate() {
    this.booking.startDate = '';
  }

  ngOnInit() {
    try {
      this.franchiseName = (this.route.snapshot.params['city']).toString();
    } catch (e) {

    }

    try {
      this.productName = (this.route.snapshot.params['product']).toString();
    } catch (e) {

    }

    this.productId = Number(localStorage.getItem("productId"));
    this.DeliveryCity = localStorage.getItem("currentDeliveryCity");
    this.DeliveryCharge = localStorage.getItem("currentDeliveryCharge");
    this.franchiseId = Number(localStorage.getItem("franchiseId"));

    this.booking.customerId = Number(localStorage.getItem("customerId"));
    this.booking.productId = this.productId;
    this.booking.franchiseId = this.franchiseId;
    this.booking.bookingDate = new Date();
    this.booking.totalNumberOfDays = 1;
    this.booking.quantity = 1;
    this.booking.productSerialNo = "";
    this.booking.ordersNotes = "";
    this.booking.paymentStatus = "";
    this.booking.ipaddress = "";

    this.ngxUiLoaderService.start();
    if (this.productId != undefined && this.productId != 0) {
      this.loadCalender();
      this.dataService.getRentalProducts(this.productId)
        .subscribe(
          (data: IRentalProducts) => {
            this.ngxUiLoaderService.stop();
            this.ProductModel = data;

            this.ProductModel.weeklyRentalPrice = data.factorPrice[6].toString();
            this.ProductModel.monthlyRentalPrice = data.factorPrice[27].toString();
            this.franchiseId = data.franchiseId;
            this.booking.franchiseId = this.franchiseId;
            this.booking.productId = data.productId;
            this.IsPageLoaded = true;

            this.booking.totalAmount = data.factorPrice[data.minBlockCount - 1].toString();

            this.booking.totalNumberOfDays = data.minBlockCount;         

            try {
              this.titleService.setTitle(data.pageTitle);
              this.meta.updateTag({ name: 'keywords', content: this.ProductModel.keywords });
              this.meta.updateTag({ name: 'description', content: this.ProductModel.metaDescription });
            } catch (e) {

            }

            if (this.ProductModel.totalAvb <= 0)
            {
              alert("Sorry we dont have any units avaliable right now.");
            }

          },
          (err: any) => console.log(err),
          () => {
            console.log("rental product Data Recived");
          }
        );
    }
    else {
      this.dataService.getRentalProductsWName(this.franchiseName, this.productName)
        .subscribe(
          (data: IRentalProducts) => {
            this.ngxUiLoaderService.stop();

            if (data == null) {
              this.router.navigate(["/"]);
            } else {



              this.productId = data.productId;
              this.franchiseId = data.franchiseId;
              this.booking.franchiseId = this.franchiseId;
              this.booking.productId = data.productId;
              this.ProductModel = data;
              this.booking.totalAmount = data.price.toString();
              this.IsPageLoaded = true;

              if (this.franchiseName.toLowerCase() == 'calgary') {
                localStorage.setItem("currentDeliveryCity", this.franchiseName);
                localStorage.setItem("currentPostalCode", "T3k");
                localStorage.setItem("currentDeliveryCharge", "0");
              }
              else if (this.franchiseName.toLowerCase() == 'edmonton') {
                localStorage.setItem("currentDeliveryCity", this.franchiseName);
                localStorage.setItem("currentPostalCode", "T5C");
                localStorage.setItem("currentDeliveryCharge", "0");
              }
              else if (this.franchiseName.toLowerCase() == 'vancouver') {
                localStorage.setItem("currentDeliveryCity", this.franchiseName);
                localStorage.setItem("currentPostalCode", "V7P");
                localStorage.setItem("currentDeliveryCharge", "0");
              }
              else if (this.franchiseName.toLowerCase() == 'guelph') {
                localStorage.setItem("currentDeliveryCity", this.franchiseName);
                localStorage.setItem("currentPostalCode", "N1C");
                localStorage.setItem("currentDeliveryCharge", "0");
              }
              else if (this.franchiseName.toLowerCase() == 'mississauga') {
                localStorage.setItem("currentDeliveryCity", this.franchiseName);
                localStorage.setItem("currentPostalCode", "L4X");
                localStorage.setItem("currentDeliveryCharge", "0");
              }
              this.banner.generateBanner();

              try {
                this.titleService.setTitle(data.pageTitle);
                this.meta.updateTag({ name: 'keywords', content: this.ProductModel.keywords });
                this.meta.updateTag({ name: 'description', content: this.ProductModel.metaDescription });
              } catch (e) {

              }

              this.loadCalender();
            }
          },
          (err: any) => console.log(err),
          () => {
            console.log("rental product Data Recived");
          }
        );
    }





  }

  loadCalender() {
    let currentUnavaliable = [];
    var blockDays = [];
    let tmpThis = this;

    var disabledDays = [];
    var maxium_quantity = 1;

    $(document).ready(function () {

      var flag = true;
      $("#datepicker_div").hide();

      $("#TotalNumberOfDays").val('1');
      var Quantity = $("#Quantity").val("1");

      if (flag == true) {
        InitilizeDisableDates();
        flag = false;
      }

      $("#datepicker").datepicker({

        minDate: 0,
        beforeShowDay: function (date) {

          var today = new Date();

          try {

            var string = $.datepicker.formatDate('mm-dd-yy', date);

            if (string == "11-25-2020") {
              var i = 0;
            }

            var isDisabled = ($.inArray(string, blockDays) != -1);

            if (isDisabled) {
              //return [false, ".ui-datepicker td.ui-state-disabled > span", "Franchise Closed"];
              return [false, ".ui-datepicker td.ui-state-disabled > span", "Franchise Closed"];
            }
          } catch (e) {

          }

          if (today.getDate() == date.getDate() && today.getMonth() == date.getMonth() && today.getFullYear() == date.getFullYear()) {
            try {
              var todayavb = $("#IsSameDayDeliveryAvb").val();
              var string = $.datepicker.formatDate('mm-dd-yy', date);
              var IsAfterCutOffTime = $("#IsAfterCutOffTime").val();
              var isDisabled = ($.inArray(string, disabledDays) != -1);
              if (todayavb == "false" || todayavb == false || isDisabled || IsAfterCutOffTime) {
                return [false, ".ui-datepicker td.ui-state-disabled-previous-dates > span", "Already Booked"];
              } else {
                return [true, "Avaliable", "This day is avaliable for booking"];
              }
            } catch (e) { }
          }

          try {
            var days = 15;
            var result15Days = new Date();
            result15Days.setDate(result15Days.getDate() + days);            

            if (date.getDate() == 22)
            {
              var varbreak=0;
            }

            var resultDay = result15Days.getDate();
            var resultMonth = result15Days.getMonth();
            var resultYear = result15Days.getFullYear();

            var currentDay = date.getDate();
            var currentMonth = date.getMonth();
            var currentYear = date.getFullYear();

            if (resultDay == currentDay && resultMonth == currentMonth && resultYear == currentYear)
            {
              var i = 0;
            }

            if (currentYear > resultYear) {
              return [false, ".ui-datepicker td.ui-state-disabled > span", "Already Booked"];
            }
            if (currentMonth > resultMonth) {
              return [false, ".ui-datepicker td.ui-state-disabled > span", "Already Booked"];
            }

            if (currentDay > resultDay && currentMonth >= resultMonth && currentYear >=  resultYear) {
              //if (date.getMonth() > result15Days.getMonth()) {
                return [false, ".ui-datepicker td.ui-state-disabled > span", "Already Booked"];
             // }
            }
          } catch (e) {

          }


          var Quantity = $("#TotalNumberOfDays").val();
          var checkdate = new Date(date);
          var result = IsInBetween(checkdate, disabledDays);

          if (result) {
            var string = $.datepicker.formatDate('mm-dd-yy', date);
            var isDisabled = ($.inArray(string, disabledDays) != -1);

            if (isDisabled) {
              return [false, ".ui-datepicker td.ui-state-disabled > span", "Already Booked"];
            }
            return [false, ".ui-datepicker td.ui-state-disabled > span", "Sorry we are already booked for this day"];
            //return [true, "dp-highlight-unselectable", "Sorry we are already booked for this day"];
          }

          var StartDate = $("#StartDate").val();
          var EndDate = $("#EndDate").val();
          var date1 = $.datepicker.parseDate($.datepicker._defaults.dateFormat, StartDate);
          var date2 = $.datepicker.parseDate($.datepicker._defaults.dateFormat, EndDate);



          var day = date.getDay();
          var string = $.datepicker.formatDate('mm-dd-yy', date);

          var isDisabled = ($.inArray(string, disabledDays) != -1);

          var isAvaliable = true;

          var isHighlight = date1 && ((date.getTime() == date1.getTime()) || (date2 && date >= date1 && date <= date2));

          if (isDisabled) {
            return [!isDisabled, ".ui-datepicker td.ui-state-disabled > span", "Sorry we are already booked for this day"];
          }
          else if (isHighlight) {

            return [true, isHighlight ? "dp-highlight" : ""];
          }
          else {
            return [isAvaliable, "Avaliable", "This day is avaliable for booking"];
          }
        }
      })
        .on("change", function () {

          var date = new Date($(this).val());
          var string = $.datepicker.formatDate('mm-dd-yy', date);

          var isDisabled = ($.inArray(string, this.currentUnavaliable) != -1);


          if (isDisabled) {
            $("#datepicker_message").css("visibility", "visible");
            $("#datepicker_message").css("position", "relative");
            $("#datepicker_message").text("Sorry this date isnt avalible for your date range");
          }
          else {
            $("#datepicker_message").css("position", "absolute");
            $("#datepicker_message").css("visibility", "hidden");

            flag = true;

            var Quantity = $("#TotalNumberOfDays").val();

            if (tmpThis.ProductModel.factorType == 'Week') {
              Quantity = Number($("#TotalNumberOfDays").val()) * 7;
            }
            else if (tmpThis.ProductModel.factorType == '4Week') {
              Quantity = Number($("#TotalNumberOfDays").val()) * 7 * 4;
            }

            $("#StartDate").val($(this).val());

            tmpThis.booking.startDate = $(this).val();

            var enddate = new Date(date);


            var fullDate = new Date(enddate);

            fullDate.setDate(fullDate.getDate() + Number(Quantity));

            var twoDigitMonth = (fullDate.getMonth() + 1) + "";
            if (twoDigitMonth.length == 1) {
              twoDigitMonth = "0" + twoDigitMonth;
            }
            var twoDigitDate = fullDate.getDate() + "";
            if (twoDigitDate.length == 1) {
              twoDigitDate = "0" + twoDigitDate;
            }
            var currentDate = twoDigitMonth + "/" + twoDigitDate + "/" + fullDate.getFullYear();

            $("#EndDate").val(currentDate);

            tmpThis.booking.endDate = currentDate;

            $(this).datepicker();
            $("#RentNow").prop('disabled', false);
            $("#RentNowUnloggedIn").prop('disabled', false);
            $("#AddtoCart").css('pointer-events', 'visible');
            $("#AddtoCart").css('opacity', '1');

            $("#AddtoCartUnloggedIn").css('pointer-events', 'visible');
            $("#AddtoCartUnloggedIn").css('opacity', '1');

          }

        });

      $("#TotalNumberOfDays").on("change", function () {
        tmpThis.resetStartDate();
        if (Number($("#TotalNumberOfDays").val()) <= 28 && Number($("#TotalNumberOfDays").val()) >= tmpThis.ProductModel.minBlockCount) {
          update_price();
          UpdateNumberOfDays();
          $("#DaysMaxReached").css("visibility", "hidden");
        }
        else if (Number($("#TotalNumberOfDays").val()) < tmpThis.ProductModel.minBlockCount) {
          $("#TotalNumberOfDays").val(tmpThis.ProductModel.minBlockCount);
          update_price();
          UpdateNumberOfDays();
          //$("#DaysMaxReached").css("visibility", "visible");
        }
        else {
          $("#TotalNumberOfDays").val("28");
          update_price();
          UpdateNumberOfDays();
          $("#DaysMaxReached").css("visibility", "visible");
        }
        tmpThis.booking.totalNumberOfDays = Number($("#TotalNumberOfDays").val());
      });

      $("#Quantity").on("change", function () {
        tmpThis.resetStartDate();
        var qua = Number($("#Quantity").val());
        if (qua < maxium_quantity) {
          update_price();
          UpdateNumberOfDays();
        } else {
          $("#Quantity").val("" + maxium_quantity);
          update_price();
          UpdateNumberOfDays();
          $("#MaxReached").css("visibility", "visible");
        }
        tmpThis.booking.quantity = Number($("#Quantity").val());
      });

      $("#EndDate").change("change", function () {

        $("#RentNow").prop('disabled', false);
      });

      $('#plus_days').click(function (e) {
        tmpThis.resetStartDate();
        if (Number($("#TotalNumberOfDays").val()) < 28) {
          $("#TotalNumberOfDays").val(Number($("#TotalNumberOfDays").val()) + 1);
          UpdateNumberOfDays();
          update_price()
          $("#DaysMaxReached").css("visibility", "hidden");
        }
        else {

          $("#DaysMaxReached").css("visibility", "visible");
        }
        tmpThis.booking.totalNumberOfDays = Number($("#TotalNumberOfDays").val());
      });

      $('#minus_days').click(function (e) {
        tmpThis.resetStartDate();
        if (Number($("#TotalNumberOfDays").val()) > tmpThis.ProductModel.minBlockCount) {
          $("#TotalNumberOfDays").val(Number($("#TotalNumberOfDays").val()) - 1);
          UpdateNumberOfDays();
          update_price();
          $("#DaysMaxReached").css("visibility", "hidden");
        }
        if (Number($("#TotalNumberOfDays").val()) > 28) {
          $("#DaysMaxReached").css("visibility", "visible");
        }
        tmpThis.booking.totalNumberOfDays = Number($("#TotalNumberOfDays").val());
      });

      $('#plus_quantity').click(function (e) {
        tmpThis.resetStartDate();
        var qua = Number($("#Quantity").val());
        if (qua < maxium_quantity) {
          $("#Quantity").val(Number($("#Quantity").val()) + 1);
          $("#MaxReached").css("visibility", "hidden");
          UpdateNumberOfDays();
          update_price();

        }
        else {
          $("#MaxReached").css("visibility", "visible");
        }
        tmpThis.booking.quantity = Number($("#Quantity").val());
        InitilizeDisableDates();
      });

      $('#minus_quantity').click(function (e) {
        tmpThis.resetStartDate();
        if (Number($("#Quantity").val()) > 1) {
          $("#Quantity").val(Number($("#Quantity").val()) - 1);
          UpdateNumberOfDays();
          update_price();
          $("#MaxReached").css("visibility", "hidden");
        }

        var qua = Number($("#Quantity").val());
        if (qua > maxium_quantity) { $("#MaxReached").css("visibility", "visible"); }
        tmpThis.booking.quantity = Number($("#Quantity").val());
        InitilizeDisableDates();
      });

      function update_price() {
        var quantity = Number($("#Quantity").val());
        var TotalNumberOfDays = Number($("#TotalNumberOfDays").val());
        var temp = $("#Day_" + TotalNumberOfDays).val();
        var Computed_Unit_Price = Number(temp.replace('$', ''));

        var Total_ammount: number = quantity * Computed_Unit_Price;

        var num = Total_ammount;// parseFloat(Total_ammount);
        var new_num = num.toFixed(2);

        $("#Total").text('$' + new_num)
      }

      function UpdateNumberOfDays() {
        //Loader(true);
        $("#datepicker").datepicker('setDate', null);
        $("#StartDate").val("");
        $("#EndDate").val("");
        $("#EndDate").text("");

        flag = true;

        var Quantity = $("#TotalNumberOfDays").val();

        var date = $("#datepicker").val();


        currentUnavaliable = [];
        $("#datepicker").datepicker("setDate", date);

        //Loader(false);
        var enddate = new Date(date);
        var fullDate = new Date(enddate);

        fullDate.setDate(fullDate.getDate() + Number(Quantity - 1));

        var twoDigitMonth = (fullDate.getMonth() + 1) + "";
        if (twoDigitMonth.length == 1) {
          twoDigitMonth = "0" + twoDigitMonth;
        }
        var twoDigitDate = fullDate.getDate() + "";
        if (twoDigitDate.length == 1) {
          twoDigitDate = "0" + twoDigitDate;
        }
        var currentDate = twoDigitMonth + "/" + twoDigitDate + "/" + fullDate.getFullYear();


      }


      function InitilizeDisableDates() {

        //maxium_quantity = tmpThis.ProductModel.totalAvb;
        //currentUnavaliable = [];
        //disabledDays = [];
        //$('#AvaliableSerialsList').val("");
        //$('#datepicker').datepicker();
        //var date = $("#datepicker").val();
        //$("#datepicker").datepicker("setDate", date);
        //$('#ProductID').val(tmpThis.productId);
        //$("#datepicker_div").show();

        var ProductID = tmpThis.productId;
        var Quantity = tmpThis.booking.quantity;
        var FranchiseId = Number(localStorage.getItem("franchiseId"));
        currentUnavaliable = [];
        disabledDays = [];

        $.ajax({
          type: "GET",
          url: `${tmpThis.dataService.baseurl}/api/StandardRentalBooking/?ProductID=${ProductID}&Quantity=${Quantity}&FranchiseId=${FranchiseId}`,
          contentType: "application/json",
          dataType: "json",
          success: function (data) {
            if (data.maxQuantity > 0) {
              disabledDays = data.bookingslist;
              try {
                blockDays = data.bLockDayUnitlist;
              } catch (e) {

              }
              maxium_quantity = data.maxQuantity;
              $('#AvaliableSerialsList').val(data.serialsFor);
              $('#datepicker').datepicker();
              var date = $("#datepicker").val();
              $("#datepicker").datepicker("setDate", date);
              $('#ProductID').val(data.productID);
              $("#datepicker_div").show();
            }
            else {
              var franchiseName = $('#FranchiseId :selected').text();
              //alert("Sorry for inconvience, We dont have this product in stock for " + franchiseName);
              $("#datepicker_div").hide();
            }
          },
          error: function (data) {
            //alert("Error occured on Initilize Datebase API");
          }

        });
      }
    });
    $(function () {
      $("#datepicker").datepicker();
    });

    function makeDate(Objdate) {
      var fullDate = new Date(Objdate);

      var twoDigitMonth = (fullDate.getMonth() + 1) + "";
      if (twoDigitMonth.length == 1) {
        twoDigitMonth = "0" + twoDigitMonth;
      }
      var twoDigitDate = fullDate.getDate() + "";
      if (twoDigitDate.length == 1) {
        twoDigitDate = "0" + twoDigitDate;
      }
      var currentDate = twoDigitMonth + "/" + twoDigitDate + "/" + fullDate.getFullYear();

      return currentDate;
    }

    function IsInBetween(date, disabledDays) {
      var Quantity = $("#TotalNumberOfDays").val();
      if (Quantity > 1) {
        var possibleStartdate = new Date(date);

        var possibleEnddate = new Date(date);

        possibleEnddate.setDate(possibleEnddate.getDate() + Number(Quantity - 1));


        for (var chk = 0; chk < disabledDays.length; chk++) {

          var c = disabledDays[chk].split("-");

          var day = c[1];
          var year = c[2];
          var month = c[0] - 1;

          var check = new Date(year, month, day);
          {

            if (check.getDate() == possibleStartdate.getDate() && check.getMonth() == possibleStartdate.getMonth() && check.getFullYear() == possibleStartdate.getFullYear()) {
              return true;
            }
            else if (check.getDate() == possibleEnddate.getDate() && check.getMonth() == possibleEnddate.getMonth() && check.getFullYear() == possibleEnddate.getFullYear()) {
              var fullDate = new Date(possibleStartdate);


              var twoDigitMonth = (fullDate.getMonth() + 1) + "";
              if (twoDigitMonth.length == 1) {
                twoDigitMonth = "0" + twoDigitMonth;
              }
              var twoDigitDate = fullDate.getDate() + "";
              if (twoDigitDate.length == 1) {
                twoDigitDate = "0" + twoDigitDate;
              }
              var currentDate = twoDigitMonth + "-" + twoDigitDate + "-" + fullDate.getFullYear();

              currentUnavaliable.push(currentDate);
              return true;
            }
            else if (check > possibleStartdate && check < possibleEnddate) {

              var fullDate = new Date(possibleStartdate);

              var twoDigitMonth = (fullDate.getMonth() + 1) + "";
              if (twoDigitMonth.length == 1) {
                twoDigitMonth = "0" + twoDigitMonth;
              }
              var twoDigitDate = fullDate.getDate() + "";
              if (twoDigitDate.length == 1) {
                twoDigitDate = "0" + twoDigitDate;
              }
              var currentDate = twoDigitMonth + "-" + twoDigitDate + "-" + fullDate.getFullYear();

              currentUnavaliable.push(currentDate);

              return true;
            }
          }

        }
      }

      return false;
    }
  }


  addToCart = false;
  bookProductCart() {
    this.addToCart = true;
    this.bookProduct();
  }

  bookProduct(): void {
    if (this.booking.startDate == undefined || this.booking.startDate == '') {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Please select Delivery Date',
        showConfirmButton: false,
        timer: 1500
      });
    } else {
      //this.booking.totalNumberOfDays = this.booking.totalNumberOfDays * 7;
      this.ngxUiLoaderService.start();
      this.franchiseId = Number(localStorage.getItem("Banner_franchiseId"));
      this.dataService.bookStandardRentalProduct(this.booking).subscribe(
        (data: void) => {
          //let message = "Successfully Booked";
          //let action = "Ok";
          //this._snackBar.open(message, action, {
          //  duration: 2000,
          //});

          //Swal.fire({
          //  position: 'top-end',
          //  icon: 'success',
          //  title: 'Booking Placed',
          //  showConfirmButton: false,
          //  timer: 1500
          //})
          if (this.addToCart) {
            this.ngxUiLoaderService.stop();
            this.router.navigate(["/"]);
          } else {
            this.ngxUiLoaderService.stop();
            this.router.navigate(["/checkout"]);
          }
        },
        (err: any) => console.log(err)
      );
    }
  }

  gotoLogin() {

    localStorage.setItem("comingFromProduct", 'true');
    localStorage.setItem("bookingType", 'SRP');
    localStorage.setItem('booking', JSON.stringify(this.booking));

    this.router.navigate(['/login']);

    //let prodName = this.ProductModel.name.replace(/\s/g, '').trim();

    //let url = `/productdetails/${prodName}`;
    //this.router.navigate(['/login'], {
    //  queryParams: { returnUrl: url }
    //});
  }

}
