import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { SecurityService } from '../../security/security.service';
import { DataService } from '../../shared/data.service';
import { responseMsg } from '../../shared/Interfaces/IInterfaces';
import { ICustomer } from '../../shared/Interfaces/ILogin';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  constructor(private titleService: Title, private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router, private dataService: DataService,
    private securityService: SecurityService) {

    dataService.getCurrentCart('dashboard');
    this.titleService.setTitle("TheRentalGuys.Ca - Change Password");

  }

  customerId;
  customer: ICustomer = new ICustomer();

  ngOnInit() {
    this.customerId = localStorage.getItem("customerId");

    this.dataService.getCustomer(this.customerId)
      .subscribe(
        (data: ICustomer) => {
          this.customer = data;
        },
        (err: any) => console.log(err),
        () => {
          console.log("Data Recived");
        }
      );
  }

  password;
  npassword;
  cpassword

  changePassword()
  {
    if (this.npassword == this.cpassword) {
      this.dataService.changePassword(this.customer.emailAddress, this.password, this.npassword)
        .subscribe(
          (data: responseMsg) => {

            if (data.msg.includes("Successfully"))
            {
              this.password='';
              this.npassword = '';
              this.cpassword = '';
            }
            let message = data.msg;
            let action = "Ok";
            this._snackBar.open(message, action, {
              duration: 2000,
            });
          },
          (err: any) => console.log(err),
          () => {
            console.log("Data Recived");
          }
        );
    }
    else
    {
      let message = "New and Confirm doesnt match";
      let action = "Ok";
      this._snackBar.open(message, action, {
        duration: 2000,
      });
    }
  }
}
