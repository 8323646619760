import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { DataService } from '../shared/data.service';
import { Router } from '@angular/router';
import { IRegister, ICustomer, IRegisterBooking, IRentalProductBookings } from '../shared/Interfaces/ILogin';
import { NgxUiLoaderService, Loader } from 'ngx-ui-loader';
import Swal from 'sweetalert2';
import { Title } from '@angular/platform-browser';
import { SecurityService } from '../security/security.service';
import { LoginModel } from '../shared/login.model';
import { FoundUsOn } from '../shared/Interfaces/IInterfaces';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  constructor(private titleService: Title, private ngxUiLoaderService: NgxUiLoaderService, private _snackBar: MatSnackBar, private dataService: DataService, private router: Router, private securityService: SecurityService) {
    dataService.getCurrentCart('register');
    this.titleService.setTitle("TheRentalGuys.Ca - Register");
  }

  EmailExists = false;

  registerData: IRegister = new IRegister();

  registerBooking: IRegisterBooking = new IRegisterBooking();

  comingFromProduct: boolean = false;

  foundUsOn: FoundUsOn[] = [];

  ngOnInit() {

    this.comingFromProduct = Boolean(localStorage.getItem("comingFromProduct"));

    if (this.comingFromProduct) {
      var retrievedObject = localStorage.getItem('booking');
      let booking: IRentalProductBookings = JSON.parse(retrievedObject);

      this.registerBooking.comingFromProduct = this.comingFromProduct;
      this.registerBooking.productType = localStorage.getItem("bookingType");
      this.registerBooking.days = booking.totalNumberOfDays;
      this.registerBooking.location = localStorage.getItem("currentDeliveryCity");
      this.registerBooking.productId = booking.productId;
      this.registerBooking.quantity = booking.quantity;
      this.registerBooking.startDate = booking.startDate;
    }

    try {
      this.ngxUiLoaderService.start();
      this.dataService.foundUsOn()
        .subscribe(
          (data: FoundUsOn[]) => {
            this.ngxUiLoaderService.stop();
            this.foundUsOn = data;
          },
          (err: any) => console.log(err),
          () => {
            this.ngxUiLoaderService.stop();
            console.log("Data Recived");
          }
        );
    } catch (e) {

    }

  }

  selected: string;

  
  public validateFormData(email: string, selected: string, password: string, firstName: string, lastName: string, phone: string ): string {
    var isEmailValid: boolean = false;
    var isSelected: boolean = false;
    var isPasswordValid: boolean = false;
    var isFirstNameValid: boolean = false;
    var isLastNameValid: boolean = false;
    var isPhoneValid: boolean = false;
    if(email){
      var pattern =/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      isEmailValid = pattern.test(email);
    }
    if(!isEmailValid){
      return "Invalid Email";
    }
    if(selected && isEmailValid){
      isSelected = true;
    }
    if(!isSelected){
      return "Please select where you found us";
    }
    if(isSelected && password.length > 5)
    {
      isPasswordValid= true;
    }
    if(!isPasswordValid){
      return "Please provide a password with length of atleast 6";
    }
    if(isPasswordValid && firstName.length > 0)
    {
      isFirstNameValid= true;
    }
    if(!isFirstNameValid){
      return "Please provide a valid first name";
    }
    if(isFirstNameValid && lastName.length > 0)
    {
      isLastNameValid= true;
    }
    if(!isLastNameValid){
      return "Please provide a last name";
    }
    if(isLastNameValid && phone.length > 9)
    {
      isPhoneValid = true;
    }
    if(!isPhoneValid){
      return "Please provide a valid phone number";
    }

    return "Ok";
  }
  register(): void {

    //if (this.registerData.password.includes('&') || this.registerData.password.includes('#') || this.registerData.password.includes('$') || this.registerData.password.includes('%'))
    //{
    //  let message = "Special characters are not allowed in password";
    //  this._snackBar.open(message, "OK", {
    //    duration: 10000,
    //  });
    //}
    //else
      var validateFormResp = this.validateFormData(this.registerData.email, this.selected, this.registerData.password, this.registerData.firstName, this.registerData.lastName, this.registerData.phone);
      if(validateFormResp !="Ok")
      {
        let message = validateFormResp;
              this._snackBar.open(message, "OK", {
                duration: 5000,
              });
        return;
      }
      if (validateFormResp == "Ok") {

      let unloggedInCustomer = Number(localStorage.getItem("unloggedInCustomer"));

      this.registerData.foundUsOn = this.selected;

      if (unloggedInCustomer != undefined) {
        this.ngxUiLoaderService.start();
        this.dataService.registerCustomerUnLoggedIn(this.registerData, unloggedInCustomer)
          .subscribe(
            (data: ICustomer) => {
              console.log(data);
              if (data.result != "Already Exists") {

                Swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  title: 'Account Created',
                  showConfirmButton: false,
                  timer: 1500
                })

                this.ngxUiLoaderService.stop();

                let loginCred: LoginModel = new LoginModel();
                loginCred.Email = this.registerData.email;
                loginCred.Password = this.registerData.password;

                try {
                  this.comingFromProduct = Boolean(localStorage.getItem("comingFromProduct"));

                  if (this.comingFromProduct) {
                    var retrievedObject = localStorage.getItem('booking');
                    let booking: IRentalProductBookings = JSON.parse(retrievedObject);

                    loginCred.comingFromProduct = this.comingFromProduct;
                    loginCred.productType = localStorage.getItem("bookingType");
                    loginCred.days = booking.totalNumberOfDays;
                    loginCred.location = localStorage.getItem("currentDeliveryCity");
                    loginCred.productId = booking.productId;
                    loginCred.quantity = booking.quantity;
                    loginCred.startDate = booking.startDate;

                  }
                } catch (e) {

                }
                try {
                  localStorage.removeItem("unloggedInCustomer");
                } catch (e) {

                }
                this.securityService.login(loginCred)
                  .subscribe(
                    resp => {
                      this.ngxUiLoaderService.stop();
                      if (resp.isAuthenticated) {

                        if (this.comingFromProduct) {

                          localStorage.removeItem("comingFromProduct");
                          localStorage.removeItem('booking');

                          this.router.navigate(["/checkout"]);
                        }
                        else {
                          this.router.navigate(['/']);
                        }

                      }
                      else {
                      }
                    },
                    (err: any) => {
                      this.ngxUiLoaderService.stop();
                    }
                  )

              }
              else {
                this.EmailExists = true;
                let message = "Account Already Exists";
                this._snackBar.open(message, "OK", {
                  duration: 10000,
                });
              }
            },
            (err: any) => {
              this.ngxUiLoaderService.stop();
              let message = "Invalid email or password";
              this._snackBar.open(message, "OK", {
                duration: 2000,
              });
            },
            () => {
              this.ngxUiLoaderService.stop();
              console.log("projects Data Recived");

            }
          );
      }
      else {
        this.ngxUiLoaderService.start();
        this.dataService.registerCustomer(this.registerData)
          .subscribe(
            (data: ICustomer) => {
              console.log(data);
              if (data.result != "Already Exists") {

                Swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  title: 'Account Created',
                  showConfirmButton: false,
                  timer: 1500
                })

                this.ngxUiLoaderService.stop();

                let loginCred: LoginModel = new LoginModel();
                loginCred.Email = this.registerData.email;
                loginCred.Password = this.registerData.password;

                try {
                  this.comingFromProduct = Boolean(localStorage.getItem("comingFromProduct"));

                  if (this.comingFromProduct) {
                    var retrievedObject = localStorage.getItem('booking');
                    let booking: IRentalProductBookings = JSON.parse(retrievedObject);

                    loginCred.comingFromProduct = this.comingFromProduct;
                    loginCred.productType = localStorage.getItem("bookingType");
                    loginCred.days = booking.totalNumberOfDays;
                    loginCred.location = localStorage.getItem("currentDeliveryCity");
                    loginCred.productId = booking.productId;
                    loginCred.quantity = booking.quantity;
                    loginCred.startDate = booking.startDate;

                  }
                } catch (e) {

                }

                try {
                  localStorage.removeItem("unloggedInCustomer");
                } catch (e) {

                }

                this.securityService.login(loginCred)
                  .subscribe(
                    resp => {
                      this.ngxUiLoaderService.stop();
                      if (resp.isAuthenticated) {

                        if (this.comingFromProduct) {

                          localStorage.removeItem("comingFromProduct");
                          localStorage.removeItem('booking');

                          this.router.navigate(["/checkout"]);
                        }
                        else {
                          this.router.navigate(['/']);
                        }

                      }
                      else {
                      }
                    },
                    (err: any) => {
                      this.ngxUiLoaderService.stop();
                    }
                  )

              }
              else {
                this.EmailExists = true;
                let message = "Account Already Exists";
                this._snackBar.open(message, "OK", {
                  duration: 10000,
                });
              }
            },
            (err: any) => {
              this.ngxUiLoaderService.stop();
              let message = "Invalid email or password";
              this._snackBar.open(message, "OK", {
                duration: 2000,
              });
            },
            () => {
              this.ngxUiLoaderService.stop();
              console.log("projects Data Recived");

            }
          );
      }
      //}
    }
    else {
      let message = "Please enter all fields";
      this._snackBar.open(message, "OK", {
        duration: 2000,
      });
    }

  }

}
