import { Component, OnInit } from '@angular/core';
import { ICheckout, ICustomer } from '../../shared/Interfaces/ILogin';
import { Title } from '@angular/platform-browser';
import { DataService } from '../../shared/data.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SingleOrder, SingleTotalOrder } from '../../shared/Interfaces/IInterfaces';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-signature-confirmation',
  templateUrl: './signature-confirmation.component.html',
  styleUrls: ['./signature-confirmation.component.css']
})
export class SignatureConfirmationComponent implements OnInit {

  //checkout: ICheckout = new ICheckout();

  constructor(private route: ActivatedRoute,private titleService: Title, private ngxUiLoaderService: NgxUiLoaderService, private dataService: DataService) {
    var retrievedObject = localStorage.getItem('checkout');
    //this.checkout = JSON.parse(retrievedObject);
    dataService.getCurrentCart('orderConfirmation');
    this.titleService.setTitle("TheRentalGuys.Ca - Order Confirmation");

  }

  customerName;
  productsList = "";
  customerId;

  ngOnInit() {

    this.customerId = Number(localStorage.getItem("customerId"));

    this.orderId = this.route.snapshot.params['id'];

    //this.dataService.getCustomer(this.customerId)
    //  .subscribe(
    //    (data: ICustomer) => {
    //      this.customerName = data.firstName.toUpperCase() + " " + data.lastName.toUpperCase();

    //    },
    //    (err: any) => console.log(err),
    //    () => {
    //      console.log("Data Recived");
    //    }
    //  );

    this.getOrder();
  }

  order: SingleTotalOrder;
  orderId;
  tOrder;

  getOrder() {
    this.ngxUiLoaderService.start();
    let code = localStorage.getItem(`${this.orderId}_code`);

    this.dataService.getOrderSignature(code)
      .subscribe(
        (data: SingleOrder) => {
          this.ngxUiLoaderService.stop();
          this.order = data.totalOrder;
          this.tOrder = data;
        },
        (err: any) => {
          this.ngxUiLoaderService.stop();
          console.log(err);
        },
        () => {
          console.log("Data Recived");
        }
    );

  }

  downloadRecipt(id) {
    this.dataService.downloadPDF(id);
  }


}
