import { MatSnackBar } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { SecurityService } from '../security/security.service';
import { DataService } from '../shared/data.service';
import { SingleOrder } from '../shared/Interfaces/IInterfaces';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { IFranchise, ICompany, ICheckout, ICustomer } from '../shared/Interfaces/ILogin';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { SignaturePartComponent } from '../checkout/signature-part/signature-part.component';
import { Title } from '@angular/platform-browser';

declare var $: any;

@Component({
  selector: 'app-order-signature',
  templateUrl: './order-signature.component.html',
  styleUrls: ['./order-signature.component.css']
})
export class OrderSignatureComponent implements OnInit {

  constructor(private titleService: Title, private ngxUiLoaderService: NgxUiLoaderService, private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router, private dataService: DataService) {
    this.titleService.setTitle("TheRentalGuys.Ca - Signature Link");

    try {
      $(document).ready(function () {
        $('#navBarComponentId').hide();
      });

      $(document).ready(function () {
        $('#footerComponentId').hide();
      });
    } catch (e) {

    }
  }

  orderId;
  singleOrder: SingleOrder;
  franchiseId;
  order;

  customerId;
  customer: ICustomer = new ICustomer();

  code;

  hasExpired = false;

  message = "Sorry this link has expired.Please call us at 1-(888) 354-7368 for another link.Thank you";

  ngOnInit() {
    this.dataService.changeShowCartValue(false);
    
    localStorage.removeItem("SignedUrl");
    localStorage.removeItem("SignedInitUrl");

    this.today = new Date();

    this.code = this.route.snapshot.params['id'];
    this.orderId = this.route.snapshot.params['orderId'];

    localStorage.setItem("comingFromInvoiceSignature", "true");
    localStorage.setItem("InvoiceSignatureOrderID", this.orderId);

    localStorage.setItem(`${this.orderId}_code`, this.code);

    this.ngxUiLoaderService.start();

    this.dataService.getOrderSignature(this.code)
      .subscribe(
        (data: SingleOrder) => {
          this.ngxUiLoaderService.stop();
          if (data == undefined || data == null) {
            this.hasExpired = true;
            let action = "Ok";
            this._snackBar.open(this.message, action, {
              duration: 200000,
            });

            try {
              $(document).ready(function () {
                $('#navBarComponentId').show();
              });

              $(document).ready(function () {
                $('#footerComponentId').show();
              });
            } catch (e) {

            }
          }
          else {
            if (data.totalOrder.orderId.toString() != this.orderId) {
              this.hasExpired = true;
            }
            else {
              this.hasExpired = false;
              this.order = data;
              this.orderId = data.totalOrder.orderId;
              this.franchiseId = data.totalOrder.franchiseId;
              this.limitedDamage = data.totalOrder.acceptLimitedWavier;
            }
          }
        },
        (err: any) => {
          this.ngxUiLoaderService.stop();
          console.log(err);
        },
        () => {
          console.log("Data Recived");
        }
      );
  }


  //franchiseId;
  //orderId;
  currentStep = 1;
  //order: SingleOrder;
  company: ICompany;

  signTermRequested1 = false;
  signTermRequested2 = false;
  signTermRequested3 = false;
  signTermRequested4 = false;
  signTermRequested5 = false;
  signTermRequested6 = false;
  signTermRequested7 = false;
  signTermRequested8 = false;
  signTermRequested9 = false;
  signTermRequested10 = false;
  signTermRequested11 = false;
  signAerialRequested = false;
  signFull = false;
  agreementChecked = false;

  signatureImageTerms = '';
  signatureImageTermsInit = '';

  franchise: IFranchise = new IFranchise();

  limitedDamage = false;

  today;

  customerName;

  @ViewChild("signatureSlotDiv", { static: true }) signature: SignaturePartComponent;

  //constructor(private ngxUiLoaderService: NgxUiLoaderService, private _snackBar: MatSnackBar, public fb: FormBuilder, private dataService: DataService,
  //  private dialogRef: MatDialogRef<SignDialogComponent>,
  //  @Inject(MAT_DIALOG_DATA) data) {
  //  this.franchiseId = data.franchiseId;
  //  this.orderId = data.orderId;
  //  this.checkout = data.checkout;
  //  this.company = data.company;
  //  this.limitedDamage = data.limitedDamage;

  //  this.today = new Date();

  //  this.customerName = this.checkout.customer.firstName + " " + this.checkout.customer.lastName
  //}

  agreementCheck() {
    this.agreementChecked = true;
  }

  signTerm(id: number) {
    this.signatureImageTerms = this.dataService.baseurl + "/Signature/" + localStorage.getItem("SignedUrl");
    this.signatureImageTermsInit = this.dataService.baseurl + "/Initials/" + localStorage.getItem("SignedInitUrl");

    if (id == 1) {
      this.signTermRequested1 = true;
    }
    if (id == 2) {
      this.signTermRequested2 = true;
    }
    if (id == 3) {
      this.signTermRequested3 = true;
    }
    if (id == 4) {
      this.signTermRequested4 = true;
    }
    if (id == 5) {
      this.signTermRequested5 = true;
    }
    if (id == 6) {
      this.signTermRequested6 = true;
    }
    if (id == 7) {
      this.signTermRequested7 = true;
    }
    if (id == 8) {
      this.signTermRequested8 = true;
    }
    if (id == 9) {
      this.signTermRequested9 = true;
    }
    if (id == 10) {
      this.signTermRequested10 = true;
    }
    if (id == 11) {
      this.signTermRequested11 = true;
    }
    if (id == 12) {
      this.signFull = true;
    }


    //if (!this.checkout.franchise.hasAerialContract) {
    //  this.signAerialRequested = true;
    //}
  }

  signAerial() {
    //this.signatureImageTerms = this.dataService.baseurl + "/Signature/" + localStorage.getItem("SignedUrl");
    this.signAerialRequested = true;
  }

  nextStep(id) {

    let result = true;

    if (id == 2) {
      this.ngxUiLoaderService.start();
      result = this.signature.sign();

      setTimeout(() => {
        this.ngxUiLoaderService.stop();

        try {
          this.signatureImageTerms = this.dataService.baseurl + "/Signature/" + localStorage.getItem("SignedUrl");
          this.signatureImageTermsInit = this.dataService.baseurl + "/Initials/" + localStorage.getItem("SignedInitUrl");
        } catch (e) {

        }
      }, 3000);

      this.customerName = this.signature.fullname;
    }

    if (id == 0) {
      let flag = false;

      if (!this.signTermRequested1) {
        flag = true;
        $(document).ready(function () {
          $('#signTermRequested1').focus();
        });
      }

      if (!this.signTermRequested2) {
        flag = true;
        $(document).ready(function () {
          $('#signTermRequested2').focus();
        });
      }

      if (!this.signTermRequested3) {
        flag = true;
        $(document).ready(function () {
          $('#signTermRequested3').focus();
        });
      }

      if (!this.signTermRequested4) {
        flag = true;
        $(document).ready(function () {
          $('#signTermRequested4').focus();
        });
      }

      if (!this.signTermRequested5) {
        flag = true;
        $(document).ready(function () {
          $('#signTermRequested5').focus();
        });
      }

      if (!this.signTermRequested6 && this.limitedDamage) {
        flag = true;
        $(document).ready(function () {
          $('#signTermRequested6').focus();
        });
      }

      if (!this.signTermRequested7 && !this.limitedDamage) {
        flag = true;
        $(document).ready(function () {
          $('#signTermRequested7').focus();
        });
      }

      if (!this.signTermRequested8) {
        flag = true;
        $(document).ready(function () {
          $('#signTermRequested8').focus();
        });
      }

      if (!this.signTermRequested9) {
        flag = true;
        $(document).ready(function () {
          $('#signTermRequested9').focus();
        });
      }

      if (!this.signFull) {
        flag = true;
        $(document).ready(function () {
          $('#signTermRequested10').focus();
        });
      }

      if (!this.signFull) {
        flag = true;
        $(document).ready(function () {
          $('#signTermRequested11').focus();
        });
      }

      if (!this.signFull) {
        flag = true;
        $(document).ready(function () {
          $('#signTermRequested12').focus();
        });
      }

      if (flag) {
        let message = "Please sign all initials.";
        let action = "Ok";
        this._snackBar.open(message, action, {
          duration: 20000,
        });
      }
      else {

        try {
          $(document).ready(function () {
            $('#navBarComponentId').show();
          });

          $(document).ready(function () {
            $('#footerComponentId').show();
          });
        } catch (e) {

        }

        let message = "Thankyou for signing.";
        let action = "Ok";
        this._snackBar.open(message, action, {
          duration: 20000,
        });

        this.router.navigate(["/signatureConfirmation", this.orderId]);
      }
    }
    else if (result) {

      if (id == 1) {
        this.signTermRequested1 = false;
        this.signTermRequested2 = false;
        this.signTermRequested3 = false;
        this.signTermRequested4 = false;
        this.signTermRequested5 = false;
        this.signTermRequested6 = false;
        this.signTermRequested7 = false;
        this.signTermRequested8 = false;
        this.signTermRequested9 = false;
        this.signTermRequested10 = false;
        this.signTermRequested11 = false;
        this.signFull = false;
      }

      this.currentStep = id;
    }
  }

  cancel() {
    this.currentStep = 1;
    //this.dialogRef.close(false);
  }


}
