import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-guelph',
  templateUrl: './guelph.component.html',
  styleUrls: ['./guelph.component.css']
})
export class GuelphComponent implements OnInit {

  constructor(private router: Router) {

    localStorage.setItem("currentPostalCode", 'N1C');
    localStorage.setItem("comingFromFranchise", 'Yes');
    //this.router.navigate(["home", "guelph"]);
  }

  ngOnInit() {
  }

}
