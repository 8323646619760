import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { DataService } from '../shared/data.service';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import Swal from 'sweetalert2';
import { ResetCode } from '../shared/Interfaces/ILogin';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  constructor(private titleService: Title,private ngxUiLoaderService: NgxUiLoaderService, private _snackBar: MatSnackBar, private dataService: DataService, private router: Router)
  {
    //dataService.getCurrentCart('forgotPassword');
    this.titleService.setTitle("TheRentalGuys.Ca - Forgot your password?");
  }
  //constructor(private titleService: Title, private ngxUiLoaderService: NgxUiLoaderService, private _snackBar: MatSnackBar, private dataService: DataService, private router: Router) {
  //  //dataService.getCurrentCart('forgotPassword');
  //  this.titleService.setTitle("TheRentalGuys.Ca - Forgot your password?");
  //}

  email="";
  mobile="";

  resetWithMobile=false;
  processed = false;
  codeSend = false;

  ngOnInit() {
  }

  resetPhone()
  {
    this.resetWithMobile = true;
  }

  sendCode() {

    let flag = true;

    if (this.resetWithMobile == true) {
      if (this.mobile == undefined || this.mobile == "") {
        flag = false;
        let message = "Please enter mobile no.";
        this._snackBar.open(message, "OK", {
          duration: 2000,
        });
      }
    }

    if (this.email == undefined || this.email == "") {
      flag = false;
      let message = "Please enter email address.";
      this._snackBar.open(message, "OK", {
        duration: 2000,
      });
    }

    if (flag) {
      this.ngxUiLoaderService.start();
      this.dataService.validateCode(this.email, this.mobile, this.resetWithMobile)
        .subscribe(
          (data: void) => {
            this.ngxUiLoaderService.stop();
            this.codeSend = true;
          },
          (err: any) => {
            this.ngxUiLoaderService.stop();
            let message = "Invalid email or password";
            this._snackBar.open(message, "OK", {
              duration: 2000,
            });
          },
          () => {
            console.log("");
          }
        );
    }
  }


  code;

  forgotPassword() {

    let flag = true;

    if (this.code == undefined || this.code == "") {
      flag = false;
      let message = "Please enter code.";
      this._snackBar.open(message, "OK", {
        duration: 2000,
      });
    }

    if (flag) {
      this.ngxUiLoaderService.start();
      this.dataService.forgotPassword(this.email, this.mobile, this.resetWithMobile, this.code)
        .subscribe(
          (data: ResetCode) => {
            this.ngxUiLoaderService.stop();

            if (data.response == "Invalid Code")
            {
              let message = "Invalid code";
              this._snackBar.open(message, "OK", {
                duration: 2000,
              });
            }
            else {
              this.processed = true;
            }
          },
          (err: any) => {
            this.ngxUiLoaderService.stop();
            let message = "Invalid email or password";
            this._snackBar.open(message, "OK", {
              duration: 2000,
            });
          },
          () => {
            console.log("");
          }
        );
    }
  }


}
