import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ICareer } from '../shared/Interfaces/IInterfaces';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DataService } from '../shared/data.service';
import { SecurityService } from '../security/security.service';
import { MatSnackBar } from '@angular/material';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-careerdetails',
  templateUrl: './careerdetails.component.html',
  styleUrls: ['./careerdetails.component.css']
})
export class CareerdetailsComponent implements OnInit {

  div1: boolean = false;
 
  constructor(private titleService: Title,private ngxUiLoaderService: NgxUiLoaderService, private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router, private dataService: DataService,
    private securityService: SecurityService) {
    this.titleService.setTitle("TheRentalGuys.Ca - Career Details");
  }

  careerId;

  FirstName: string;
  LastName: string;
  Email: string;
  Phone: string;
  Message: string;

  fileName: string;

  ngOnInit() {
    this.getCareerDetail();
  }

  career: ICareer = new ICareer();

  getCareerDetail() {
    this.ngxUiLoaderService.start();
    this.careerId = parseInt(this.route.snapshot.params['id']);

    this.dataService.getCareerDetails(this.careerId)
      .subscribe(
        (data: ICareer) => {
          this.ngxUiLoaderService.stop();
          this.career = data;
        },
        (err: any) => {
          this.ngxUiLoaderService.stop();
          console.log(err);
        },
        () => {
          console.log("Data Recived");
        }
      );
  }

  div1Function() {
    if (this.div1 == true) {
      this.div1 = false;
    }

    else {
      this.div1 = true;
    }
  }  

  selectFile(event) {
    this.files = event.target.files;
    this.fileName = this.files[0].name;
  }

  files: FileList;

  applyJob() {
    this.uploadFile();
  }

  @ViewChild('myInput', { static: true }) myInputVariable: ElementRef;

  reset() {

    this.fileName = '';

    try {
      this.myInputVariable.nativeElement.value = "";
    } catch (e) {

    }  
  
  }

  uploadFile() {

    if (this.files.length == 0) {
      let message = "Please select file";
      let action = "Ok";
      this._snackBar.open(message, action, {
        duration: 20000,
      });
    }
    else {
      this.files[0].name;
      if (this.FirstName != undefined && this.FirstName != ''
        && this.LastName != undefined && this.LastName != ''
        && this.Email != undefined && this.Email != ''
        && this.Phone != undefined && this.Phone != '')
        //&& this.Message != undefined && this.Message != '')
      {

        if (this.Message == undefined) {
          this.Message = '';
        }

        if (this.files.length == 0) {
          console.log("No file selected!");
          return

        }
        let file: File = this.files[0];

        let name = this.FirstName + " " + this.LastName;

        let url = this.dataService.baseurl + `/api/ApplyJobFranchise/?Job=${this.career.title}&Name=${name}&Email=${this.Email}&Phone=${this.Phone}&Message=${this.Message}&FranchiseId=${this.career.franchiseId}`

        const uploadData = new FormData();

        uploadData.append('Resume', file, file.name);

        this.dataService.uploadResumeFile(url, uploadData).subscribe(async res => {

          try {
            if (res.status != undefined && res.status == 200) {
              let message = "Uploaded Successfully";
              let action = "Ok";
              this._snackBar.open(message, action, {
                duration: 20000,
              });

              this.reset();
            }
          } catch (e) {

          }

          this.FirstName = '';
          this.LastName = '';
          this.Email = '';
          this.Phone = '';
          this.Message = '';
        })
      }
      else {
        let message = "Please enter all fields";
        let action = "Ok";
        this._snackBar.open(message, action, {
          duration: 20000,
        });
      }
    }
  }
}
