import { ICustomerProfile } from "./IInterfaces";

export class ILogin {
  Email: string;
  Password: string;
}

export class ILoginResult {
  token: string;
  expiration: Date;
  isAuthenticated: boolean;
  franchiseName: string;
  franchiseId: number;
  customer: ICustomer;
  response: string;
  password: string;
}

export class IRegister {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  password: string;
  foundUsOn: string;
  tempCustomerId: number;
}

export class IRegisterBooking {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  password: string;

  comingFromProduct: Boolean;
  startDate: string;
  productId: number;
  days: number;
  quantity: number;
  productType: string;
  location: string;
}

export class IRegisterResult { }

export class ICatagory {
  catagoryId: number;
  franchiseId: number;
  catagoryName: string;
  catagoryImage: string;
  catagoryText: string;
}

export class IFranchsiePosalCode {
  franchiseId: number;
  franchiseName: string;
  title: string;
  isFree: boolean;
  offersDockDelivery: boolean;
  deliveryCharge: number;
  postalCode: string;
  franchiseCatagories: ICatagory[];
  franchiseProducts: RentalProductsCatagory[];
}

export class ICatagoryWithProduct {
  catagoryName: string;
  currentFranchise: string;
  catagoryText: string;
  numberOfItemsInCart: number;

  pageTitle: string;
  metaDescription: string;
  keyword: string;
  pageDescription: string;
  showAllProducts: boolean;
  blog: string;

  standardProductsCatagory: IStandardProductCatagory[];
  rentalProductsCatagory: RentalProductsCatagory[];
  kitProductsCatagory: IKitCatagory[];
}

export class IKitCatagory {
  packageId: number;
  packageName: string;
  image: string;
  productImagePath: string;
  packagePrice: number;
  sameDayDeliveryAvaliable: boolean;
  weeklyRentalPrice: number;
  monthlyRentalPrice: number;
  kitsProductList: string;
}


export class RentalProductsCatagory {
  productId: number;
  name: string;
  productImage: string;
  productImagePath: string;
  price: number;
  factorId: number;
  description: string;
  weeklyRentalPrice: string;
  monthlyRentalPrice: string;
  franchiseId: number;
  sameDayDeliveryAvaliable: boolean;
  totalUnits: number;
  isWeeklyProduct: boolean;
  shortName: string;

  isStandardRental: boolean;
  autoBillingBlock: number;
  blockChargeAmount: number;
  dontShowWeekly: boolean;
  dontShow4Weekly: boolean;
  overrideDailyTag: string;

  factorType: string;
  minBlockCount: number;
  maxBlockCount: number;

  factor: IFactor;
}

export class IFactor {
  factorId: number;
  factorGroupName: string;
  franchiseId: number;
  type: string;
  minBlockCount: number;
  maxBlockCount: number;
}

export class IStandardProductCatagory {
  productId: number;
  name: string;
  productImage: string;
  productImagePath: string;
  type: string;
  price: number;
  description: string;
  taxId: number;
  franchiseId: number;
}


export class IRentalProducts {
  productId: number;
  name: string;
  shortName: string;
  video: string;
  productImage: string;
  price: number;
  description: string;
  weeklyRentalPrice: string;
  monthlyRentalPrice: string;
  websiteText: string;
  sameDayDeliveryAvaliable: boolean;
  isAfterCutOffTime: boolean;
  numberOfItemsInCart: number;
  isWeeklyProduct: boolean;
  franchiseId: number;
  metaDescription: string;
  pageTitle: string;
  keywords: string;
  limitedPercentage: number;
  dontShowWeekly: boolean;
  dontShow4Weekly: boolean;
  overrideDailyTag: string;

  spec1Name: string;
  spec1Val: string;
  spec2Name: string;
  spec2Val: string;
  spec3Name: string;
  spec3Val: string;
  spec4Name: string;
  spec4Val: string;
  spec5Name: string;
  spec5Val: string;
  spec6Name: string;
  spec6Val: string;
  spec7Name: string;
  spec7Val: string;

  isStandardRental: boolean;
  autoBillingBlock: number;
  blockChargeAmount: number;

  factorType: string;
  minBlockCount: number;
  maxBlockCount: number;

  totalAvb: number;
  totalUnits: number;

  blog: string;

  factor: IFactor;
  factorPrice: number[];
  tax: ITaxclass;
  rentalProductsFiles: IRentalProductsFiles[];
  relatedRentalProducts: RentalProductsCatagory[];
  vpProducts: IVirtualProducts[];

  productExtraImages: ProductExtraImages[];

}

export class ProductExtraImages {
  id: number;
  image: string;
  addedBy: string;
  productId: number;
}

export class ITaxclass {
  taxId: number;
  name: string;
  tax: string;
}

export class IRentalProductsFiles {
  id: number;
  productId: number;
  fileName: string;
  fileUrl: string;
}

export class IVirtualProducts {
  productId: number;
  name: string;
  productImage: string;
  price: number;
  description: string;
}

export class IRentalProductBookings {
  id: number;
  startDate: string;
  endDate: string;
  customerId: number;
  productId: number;
  franchiseId: number;
  totalNumberOfDays: number;
  totalAmount: string;
  bookingDate: Date;
  productSerialNo: string;
  quantity: number;
  orderId: number;
  deliveryAddress: string;
  ordersNotes: string;
  paymentStatus: string;
  ipaddress: string;
  bookingPlatform: string;
}

export class ResetCode {
  response: string;
}

export class ICustomer {
  customerId: number;
  emailAddress: string;
  firstName: string;
  lastName: string;
  companyNmae: string;
  phone: string;
  postalCode: string;
  foundUsOn: string;
  streetAddress: string;
  route: string;
  currentFranchise: string;
  currentFranchiseId: number;
  email2: string;
  email3: string;
  hasPaymentProfile: string;
  result: string;
  mobileNo1: string;
  mobileNo2: string;

  customerEmails: ICustomerEmails[];
}

export class ICustomerEmails {
  id: number;
  customerId: number;
  emailAddress: string;
}

export class IResponse {
  result: string;
}

export class IFranchise {
  franchiseId: number;
  franchiseName: string;
  shopAddress: string;
  offersDockDelivery: boolean;
  rentalContract: string;
  hasAerialContract: boolean;
  aerialContract: string;
  hasLimitedDamage: boolean;
  limitedDamage: string;
  limitedPercentage: number;
  buisnessNo: string;
  provinceName: string;
}

/*
 *          public double totalSales { get; set; }
            public double DiscountPercentage { get; set; }
            public double NextRange { get; set; }
            public double NextRangeDiscount { get; set; }
            public double PreviousRange { get; set; }
            public double PreviousRangeDiscount { get; set; }
            public bool IsEnabledForFranchise { get; set; }
            public string nextRewardDifference { get; set; }
 * */
export class ProModel {
  totalSales: number;
  discountPercentage: number;
  totalSaved: number;
  nextRange: number;
  nextRangeDiscount: number;
  previousRange: number;
  previousRangeDiscount: number;
  isEnabledForFranchise: boolean;
  nextRewardDifference: string;
  percentageReached: number;

  firstRange: string;
  secondRange: string;
  thirdRange: string;
  fourthRange: string;
  fifthRange: string;
  sixthRange: string;
  seventhRange: string;

  firstDiscountRange: string;
  secondDiscountRange: string;
  thirdDiscountRange: string;
  fourthDiscountRange: string;
  fifthDiscountRange: string;
  sixthDiscountRange: string;
}

export class ICartWTotal {
  noOfItems: number;
  total: number;
}

export class ICheckout {
  data: ITotalOrder[];
  franchise: IFranchise;
  franchiseTax: ITaxclass;
  profileExists: boolean;
  bookingDate: Date;
  offersDockDelivery: boolean;
  hasDefaultAddress: boolean;
  deliveryAddress: DeliveryAddress;
  billingProfiles: ICustomerProfile[];
  customer: ICustomer;
  proModel: ProModel;
  minutesLeft: number;
  secondsLeft: number;
  miliSecondsLeft: number;
}

export class IDeliverybyOrder {
  orderId: number;
  deliverySlotId: number;
  slotCost: number;
}

export class ITotalOrder {
  orderId: number;
  orderDate: Date;
  customerId: number;
  franchiseId: number;
  deliveryDate: Date;
  pickupDates: string;
  totalCost: string;
  totalTax: string;
  taxClass: string;
  compiledTotal: string;
  order: IOrder[];
  total_DeliverySlots: number;
  deliveryslotslist: IDeliverySlots[];
  index: number;
  deliverySlotId: number;
  allSameDayDelivery: boolean;
  isDockDeliveryAvaliable: boolean;
  deliveryCharges: string;
  isStandardRentalOrder: boolean;
  acceptLimitedWavier: boolean;
}

export class DeliveryAddress {
  addressId: number;
  fullName: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  stateProvince: string;
  postalCodeZip: string;
  telephoneNumber: string;
  isDefault: boolean;
  customerId: number;
  franchiseId: number;
  isDeleted: boolean;
}

export class ResponseClass {
  result: string;
}

export class ResponseClassWCode {
  result: string;
  code: number;
}

export class ResponseClassWCodeAddress {
  result: string;
  code: number;
  addressId: number;
}

export class IDeliverySlots {
  id: number;
  orderID: number;
  deliveryTime: Date;//timespan check if it works okay
  deliveryTimeStr: string;
  deliveryCost: string;
  defaultSlot: boolean;
  type: string;
}

export class IOrder {
  orderDetailId: number;
  orderId: number;
  productType: string;
  deliveryDate: Date;
  pickupDate: Date;
  nextBillingDate: Date;
  orderStatus: string;
  quantity: number;
  totalPrice: string;
  totalDays: number;
  rentalProductId: number;
  standardProductId: number;
  virtualProductId: number;
  kitsProductId: number;
  isWeekendPickup: boolean;
  standardProduct: IStandardProducts;
  rentalProduct: IRentalProducts;
  kitsProduct: IKitProducts;
  virtualProductlist: IVirtualProducts;
}

export class responseCustomerId {
  customerId: number;
}

export class responseCustomerVerify {
  response: boolean;
}

export class IKitProducts {
  packageId: number;
  packageName: string;
  image: string;
  packagePrice: number;
  sameDayDeliveryAvaliable: boolean;
  weeklyRentalPrice: number;
  monthlyRentalPrice: number;
  websiteDescription: string;
  kitsProductList: string;
  limitedPercentage: number;

  vpProducts: IVirtualProducts[];
}

export class IStandardProducts {
  productId: number;
  name: string;
  productImage: string;
  price: number;
  description: string;
  isAfterCutOffTime: boolean;
  stock: number;
}

export class IBannerData {
  productsRented: number;
  citiesServed: number;
  trucksOnRoad: number;
  totalCustomersServed: number;
}

export class ICompanyData {
  company: ICompany;
  companyWithoutLinks: ICompany;
  catagories: ICatagory[];
  products: RentalProductsCatagory[];

  videoUrl: string;
  contactUsBackgroundUrl: string;
  headerLogo: string;
  headerLogoMobile: string;
  footerLogo: string;
  homePageTopBackgroundUrl: string;
  mobileBanner: string;
}

export class ICompany {
  id: number;
  shortForm: string;
  companyName: string;
  logo: string;
  longBanner: string;
  fromEmail: string;
  projectUrl: string;
  adminApiURL: string;
  filepath: string;
  headerLogo: string;
  headerLogoMobile: string;
  hasFranchiseOpportunity: boolean;
  franchiseSiteLink: string;
  helplineNo: string;
  mainTagLineHomePage: string;
  homePageTopBackgroundUrl: string;
  videoTagLine: string;
  videoUrl: string;
  hasVideo: string;
  sendEmailForContactRequest: string;
  contactUsBackgroundUrl: string;
  footerCurrencyText: string;
  contactInfoNameLine1: string;
  contactInfoNameLine2: string;
  contactInfoNameLine3: string;
  contactInfoNameLine4: string;
  contactInfoNameLine5: string;

  contactInfoNumberLine1: string;
  contactInfoNumberLine2: string;
  contactInfoNumberLine3: string;
  contactInfoNumberLine4: string;
  contactInfoNumberLine5: string;

  contactInfoEmailLine1: string;
  contactInfoEmailLine2: string;
  contactInfoEmailLine3: string;
  contactInfoEmailLine4: string;
  contactInfoEmailLine5: string;

  twitterUrl: string;
  intagramUrl: string;
  facebookUrl: string;
  linkedInUrl: string;
  footerLogo: string;


  themeColor1: string;
  themeColor2: string;
  aboutUsText: string;
  aboutUsBackgroundImageUrl: string;
  termsText: string;
  privacyPolicyText: string;
  cancellationPolicyText: string;


  showStaticCatagory: boolean;
  showStaticProduct: boolean;

  showOperationsManualPage: boolean;
  showTermsPage: boolean;
  showPrivacyPage: boolean;
  showCancellationPage: boolean;

  bodyTagLine: string;
  bookingTagLine: string;


  pageTitle: string;
  seoDescription: string;
  seoKeywords: string;

  mobileBanner: string;
}


export class UpcomingRenewalsTemp {
  customerName: string;
  orderImage: string;
  franchiseName: string;
  customerId: number;
  orderId: number;
  productId: number;
  name: string;
  autoBillingBlock: number;
  blockType: string;
  blockChargeAmount: number;
  nextBillingDate: Date;
}
