import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Inject } from '@angular/core';
import { FormControl, FormBuilder, Validators, FormGroup, NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-postalcodedialog',
  templateUrl: './postalcodedialog.component.html',
  styleUrls: ['./postalcodedialog.component.css'], encapsulation: ViewEncapsulation.None,
})
export class PostalcodedialogComponent implements OnInit {

  form: FormGroup;
  location;

  constructor(private _snackBar: MatSnackBar, public fb: FormBuilder,
    private dialogRef: MatDialogRef<PostalcodedialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
    //this.location = data.driver;
  }

  ngOnInit() {
    this.form = this.fb.group({
      location: this.location
    });
  }

  onSubmit(form: NgForm) {
    if (form.valid) {
      this.searchPostalCode();
      window.scrollTo(0, 0);
    }
  }

  searchPostalCode(): void
  {
    this.form.value.location = this.location;
    console.log(this.location);
    this.dialogRef.close(this.form.value);
  }

}
