import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../shared/data.service';
import { IQoute } from '../shared/Interfaces/IInterfaces';
import { MatDialog } from '@angular/material';
import { PostalCodeFranchiseDialogComponent } from './postal-code-franchise-dialog/postal-code-franchise-dialog.component';
import { IFranchsiePosalCode } from '../shared/Interfaces/ILogin';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-qoutations',
  templateUrl: './qoutations.component.html',
  styleUrls: ['./qoutations.component.css'], encapsulation: ViewEncapsulation.None
})
export class QoutationsComponent implements OnInit {

  constructor(private titleService: Title, private router: Router, private dataService: DataService, public dialog: MatDialog) {
    dataService.getCurrentCart('qoutations');
    this.titleService.setTitle("TheRentalGuys.Ca - Quotations");}

  customerId;
  customerQoutes: IQoute[];
  postalcodeResult: IFranchsiePosalCode;
  location;

  ngOnInit() {
    //this.openNewDialog();
    this.customerId = Number(localStorage.getItem("customerId"));

    this.dataService.getCustomerQoutations(this.customerId)
      .subscribe(
        (data: IQoute[]) => {
          this.customerQoutes = data;
        },
        (err: any) => console.log(err),
        () => {
          console.log("postalcode Data Recived");
        }
      );
  }

  startQoute()
  {
    this.openNewDialog();
  }

  showDialog(): void {
    this.openNewDialog();
  }

  openNewDialog(): void {
    const dialogRef = this.dialog.open(PostalCodeFranchiseDialogComponent, {
      //height: '350px',
      width: '510px',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let result_location: string = result.location;
        if (result_location != undefined) {
          this.location = result_location;
          this.searchPostalCode();
        }
      }
    });
  }



  searchPostalCode(): void {
    this.dataService.getAllPostalCode(this.location)
      .subscribe(
        (data: IFranchsiePosalCode) => {
          this.postalcodeResult = data;
          localStorage.setItem("currentPostalCode", data.postalCode);
          localStorage.setItem("currentDeliveryCity", data.title);
          localStorage.setItem("currentDeliveryCharge", data.deliveryCharge.toString());
          
        },
        (err: any) => console.log(err),
        () => {
          console.log("postalcode Data Recived");
        }
      );
  }


}
