import { Injectable, NgModule, PLATFORM_ID, Inject } from '@angular/core';
import { Observable, BehaviorSubject, pipe, throwError } from 'rxjs';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { tap, catchError, switchMap, finalize, filter, take } from 'rxjs/operators';
import { SecurityService } from './security.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MatSnackBar } from '@angular/material';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  private isTokenRefreshing: boolean = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(@Inject(PLATFORM_ID) private _platformId: Object, private _snackBar: MatSnackBar, private ngxUiLoaderService: NgxUiLoaderService, private acct: SecurityService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    //var token = localStorage.getItem("token");
    //if(token) {
    //  const newReq = req.clone(
    //    { 
    //       headers: req.headers.set('Authorization',
    //                'Bearer ' + token)
    //    });

    //    return next.handle(newReq);
    //}
    //else {
    //  return next.handle(req);
    //}

    //console.log("has Token expired:" + this.acct.hasTokenExpied());

    return next.handle(this.attachTokenToRequest(req)).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          console.log("Success");
        }
      }),
      catchError((err): Observable<any> => {
        if (err instanceof HttpErrorResponse) {
          switch ((<HttpErrorResponse>err).status) {
            case 401:
              console.log("Token expired. Attempting refresh ...");
              return this.handleHttpResponseError(req, next);
            case 400:
              {
                console.log(err);
                this.ngxUiLoaderService.stopAll();
                let message = 'Sorry something went wrong';
                let action = "Ok";
                this._snackBar.open(message, action, {
                  duration: 10000, horizontalPosition: "center", verticalPosition: "top"
                });
              }
            case 500:
              {
                console.log(err);
                this.ngxUiLoaderService.stopAll();
                let message = 'Sorry something went wrong';
                let action = "Ok";
                this._snackBar.open(message, action, {
                  duration: 10000, horizontalPosition: "center", verticalPosition: "top"
                });
              }
            case 403:
              {
                console.log(err);
                this.ngxUiLoaderService.stopAll();
                let message = 'Sorry something went wrong';
                let action = "Ok";
                this._snackBar.open(message, action, {
                  duration: 10000, horizontalPosition: "center", verticalPosition: "top"
                });
              }
          }
        } else {
          return throwError(this.handleError);
        }
      })

    );

  }

  // Global error handler method 
  private handleError(errorResponse: HttpErrorResponse) {
    let errorMsg: string;

    if (errorResponse.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMsg = "An error occured : " + errorResponse.error.message;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMsg = `Backend returned code ${errorResponse.status}, body was: ${errorResponse.error}`;
    }

    return throwError(errorMsg);
  }


  // Method to handle http error response
  private handleHttpResponseError(request: HttpRequest<any>, next: HttpHandler) {

    // First thing to check if the token is in process of refreshing
    if (!this.isTokenRefreshing)  // If the Token Refresheing is not true
    {
      this.isTokenRefreshing = true;

      // Any existing value is set to null
      // Reset here so that the following requests wait until the token comes back from the refresh token API call
      this.tokenSubject.next(null);

      /// call the API to refresh the token
      return this.acct.getNewRefreshToken().pipe(
        switchMap((tokenresponse: any) => {
          if (tokenresponse) {
            this.tokenSubject.next(tokenresponse.token);
            console.log("Token refreshed...");
            return next.handle(this.attachTokenToRequest(request));

          }
          return <any>this.acct.logout();
        }),
        catchError(err => {
          console.log(err);
          this.acct.logout();
          return this.handleError(err);
        }),
        finalize(() => {
          this.isTokenRefreshing = false;
        })
      );

    }
    else {
      this.isTokenRefreshing = false;
      return this.tokenSubject.pipe(filter(token => token != null),
        take(1),
        switchMap(token => {
          return next.handle(this.attachTokenToRequest(request));
        }));
    }
  }


  private attachTokenToRequest(request: HttpRequest<any>) {

    var token = '';
    if (isPlatformBrowser(this._platformId)) {
      token = localStorage.getItem("token");
    }
    return request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });

  }
};

@NgModule({
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true
    }
  ]
})
export class HttpInterceptorModule { }
