export class LoginModel {
  Password: string;
  Email: string;
  RememberMe: Boolean;
  tempCustomerId: number;
  comingFromProduct: Boolean;
  startDate: string;
  productId: number;
  days: number;
  quantity: number;
  productType: string;
  location: string;
}
