import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-vancouver',
  templateUrl: './vancouver.component.html',
  styleUrls: ['./vancouver.component.css']
})
export class VancouverComponent implements OnInit {

  constructor(private router: Router) {

    localStorage.setItem("currentPostalCode", 'V7P');
    localStorage.setItem("comingFromFranchise", 'Yes');
    //this.router.navigate(["home", "vancouver"]);
  }

  ngOnInit() {
  }

}
