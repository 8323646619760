import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../shared/data.service';
import { SecurityService } from '../security/security.service';
import { SingleOrder, responseMsg, SingleOrderElement } from '../shared/Interfaces/IInterfaces';
import { CommonModule } from '@angular/common';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-orders-details',
  templateUrl: './orders-details.component.html',
  styleUrls: ['./orders-details.component.css']
})
export class OrdersDetailsComponent implements OnInit {

  constructor(private titleService: Title, private ngxUiLoaderService: NgxUiLoaderService,private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router, private dataService: DataService,
    private securityService: SecurityService) {
    dataService.getCurrentCart('orderDetail');
    this.titleService.setTitle("TheRentalGuys.Ca - Order Details");
  }

  orderId;

  displayedColumns: string[] = ['amount', 'type', 'date'];

  order: SingleOrder;

  ngOnInit() {
    this.orderId = parseInt(this.route.snapshot.params['id']);

    localStorage.removeItem("isDockDelivery");

    this.getOrder();
  }

  getOrder() {
    this.ngxUiLoaderService.start();
    let customerId = Number(localStorage.getItem("customerId"));
    this.dataService.getSingleOrder(this.orderId, customerId)
      .subscribe(
        (data: SingleOrder) => {
          this.ngxUiLoaderService.stop();
          this.order = data;

          localStorage.setItem("orderId", data.totalOrder.orderId.toString());

          localStorage.setItem("deliveryAddress", data.totalOrder.deliveryAddress);
          localStorage.setItem("postalCode", data.totalOrder.postalCode);
          localStorage.setItem("siteContactNumber", data.totalOrder.siteContactNumber);
          localStorage.setItem("orderNotes", data.totalOrder.orderNotes);

          localStorage.setItem("currentDeliveryDate", data.totalOrder.deliveryDate);

          localStorage.setItem("taxPerCentage", data.totalOrder.taxPerCentage);
          localStorage.setItem("taxName", data.totalOrder.taxName);

          localStorage.setItem("franchiseId", data.totalOrder.franchiseId.toString());
          try {
            localStorage.setItem("isDockDelivery", data.totalOrder.isDockDelivery.toString());
          } catch (e) {

          }
          
          localStorage.setItem("currentOrder_franchiseId", data.totalOrder.franchiseId.toString());
        },
        (err: any) => {
          this.ngxUiLoaderService.stop();
          console.log(err);
        },
        () => {
          console.log("Data Recived");
        }
      );
  }

  dockDelivery(e: any)
  {  
    this.dataService.dockDelivery(this.orderId, e.checked)
    .subscribe(
      (data: responseMsg) => {
        let message = "Successfully Updated";
        let action = "Ok";
        this._snackBar.open(message, action, {
          duration: 2000,
        });
      },
      (err: any) => console.log(err),
      () => {
        console.log("Data Recived");
      }
    );
  }

  sameDayPickup(e: any,id) {
    this.dataService.sameDayPickup(id, e.checked)
      .subscribe(
        (data: responseMsg) => {
          let message = "Successfully Updated";
          let action = "Ok";
          this._snackBar.open(message, action, {
            duration: 2000,
          });
        },
        (err: any) => console.log(err),
        () => {
          console.log("Data Recived");
        }
      );
  }

  extendDay(order: SingleOrderElement) {
    localStorage.setItem("currentDays", order.totalDays.toString());
    localStorage.setItem("productType", "RP");

    this.router.navigate(['/extendProduct/' + order.orderDetailId + '/' + order.rentalProductId]);
  }


  downloadRecipt() {
    this.dataService.downloadPDF(this.order.totalOrder.orderId);
  }

  cancelRenewal()
  {
    let nextBilling = this.order.totalOrder.order[0].nextBillingDate.toString();
    localStorage.setItem("endBillingDate", nextBilling);
    this.router.navigate(['/completeStandardRental/' + this.order.totalOrder.orderId]);
  }

  cancelPickup()
  {
    this.ngxUiLoaderService.start();
    this.dataService.cancelPickup(this.orderId)
      .subscribe(
        (data: responseMsg) => {
          this.ngxUiLoaderService.stop();
          let message = "Successfully Updated";
          let action = "Ok";
          this._snackBar.open(message, action, {
            duration: 2000,
          });
          this.getOrder();
        },
        (err: any) => console.log(err),
        () => {
          this.ngxUiLoaderService.stop();
          console.log("postalcode Data Recived");
        }
      );
  }
}
