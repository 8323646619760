import { Component, ViewEncapsulation, OnInit, ViewChild } from '@angular/core';
import { SecurityService } from '../../security/security.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { DataService } from '../../shared/data.service';
import { ITotalQoutation, IQoutationDetails, responseMsg } from '../../shared/Interfaces/IInterfaces';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-qoute-details',
  templateUrl: './qoute-details.component.html',
  styleUrls: ['./qoute-details.component.css'],encapsulation: ViewEncapsulation.None
})
export class QouteDetailsComponent implements OnInit {

  deliveryAddress: string;
  postalCode: string;
  orderNotes: string;

  @ViewChild("placesRef", { static: true }) placesRef: GooglePlaceDirective;

  public handleAddressChange(address: Address) {
    console.log(address);

    for (let component of address.address_components) {
      for (let type of component.types) {
        if (type == "postal_code") {
          this.postalCode = component.long_name;
        }
      }
    }


    this.deliveryAddress = address.formatted_address;
    this.qouteDetails.deliveryLocation = address.formatted_address;
  }


  constructor(
    private titleService: Title, private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router, private dataService: DataService,
    private securityService: SecurityService) {
    dataService.getCurrentCart('qouteDetails');
    this.titleService.setTitle("TheRentalGuys.Ca - Quote Details");

  }
  qouteId;

  qouteDetails: ITotalQoutation = new ITotalQoutation();

  ngOnInit() {
    this.qouteId = parseInt(this.route.snapshot.params['id']);

    this.getQoute();
  }

  getQoute()
  {
    this.dataService.getQouteDetails(this.qouteId)
      .subscribe(
        (data: ITotalQoutation) => {
          this.qouteDetails = data;

          if (data.deliveryCharges == null)
          {
            this.qouteDetails.deliveryCharges = "0";
          }

          try {
            localStorage.setItem("quote_franchiseId", data.franchiseId.toString());
          } catch (e) {

          }

          this.deliveryAddress = data.deliveryLocation;
          this.postalCode = data.postalCode;
          this.orderNotes = data.orderNotes;
          
        },
        (err: any) => console.log(err),
        () => {
          console.log("");
        }
      );
  }

  removeProd(item: IQoutationDetails)
  {
    this.dataService.removeProd(item.orderDetailId)
      .subscribe(
        (data: responseMsg) => {
          this.getQoute();
        },
        (err: any) => console.log(err),
        () => {
          console.log("");
        }
      );
  }

  downloadRecipt() {
    this.dataService.downloadQouteInvoice(this.qouteId);
  }
  //downloadQouteInvoice


  updateDeliveryInfo() {
    if (this.deliveryAddress == null || this.postalCode == null)
    {
      let message = "Please fill delivery address and postal code.Thankyou";
      let action = "Ok";
      this._snackBar.open(message, action, {
        duration: 10000, verticalPosition: "top"
      });
    }
    else {

      try {
        if (this.orderNotes == undefined || this.orderNotes == null)
        {
          this.orderNotes = "";
        }
      } catch (e) {

      }

      this.dataService.updateDeliveryQoute(this.qouteId, this.deliveryAddress, this.postalCode, this.orderNotes)
        .subscribe(
          (data: responseMsg) => {
            this.getQoute();
            let message = "Successfully Updated";
            let action = "Ok";
            this._snackBar.open(message, action, {
              duration: 5000, verticalPosition: "top"
            });
          },
          (err: any) => console.log(err),
          () => {
            console.log("Data Recived");
          }
        );
    }
  }

  AcceptLimited(flag: boolean) {
    this.dataService.updatelimtedQoute(this.qouteId, flag)
      .subscribe(
        (data: responseMsg) => {
          this.getQoute();
          let message = "Successfully Updated";
          let action = "Ok";
          this._snackBar.open(message, action, {
            duration: 5000, verticalPosition: "top"
          });
        },
        (err: any) => console.log(err),
        () => {
          console.log("Data Recived");
        }
      );
  }
}
