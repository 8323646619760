import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { ICompany, ICompanyData } from '../shared/Interfaces/ILogin';
import { DataService } from '../shared/data.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-appfooter',
  templateUrl: './appfooter.component.html',
  styleUrls: ['./appfooter.component.css']
})
export class AppfooterComponent implements OnInit {

  constructor(@Inject(PLATFORM_ID) private _platformId: Object,private dataService: DataService) { }

  company: ICompany;
  companyData: ICompanyData;

  ngOnInit() {
    if (isPlatformBrowser(this._platformId)) {
      var retrievedObject = localStorage.getItem('company');
      if (retrievedObject != undefined && retrievedObject != null) {
        this.companyData = JSON.parse(retrievedObject);
        this.company = this.companyData.company;
      }
      else {
        this.getCompany();
      }
    }
  }

  phone1;
  phone2;
  phone3;
  phone4;
  phone5;

  getCompany() {
    localStorage.removeItem('company');
    this.dataService.getCompanyData(false)
      .subscribe(
        (data: ICompanyData) => {

          localStorage.setItem('company', JSON.stringify(data));
          localStorage.setItem('headerLogo', data.headerLogo);
          localStorage.setItem('headerLogoMobile', data.headerLogoMobile);
          localStorage.setItem('footerLogo', data.footerLogo);
          localStorage.setItem('contactUsBackgroundUrl', data.contactUsBackgroundUrl);
          localStorage.setItem('videoUrl', data.videoUrl);
          localStorage.setItem('homePageTopBackgroundUrl', data.homePageTopBackgroundUrl);
          this.company = data.company;
        },
        (err: any) => console.log(err),
        () => {
          console.log("");
        }
      );
  }

}
