import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, Renderer2, AfterViewInit, OnDestroy } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DataService } from '../shared/data.service';
import { ICartWTotal, ICheckout, IResponse } from '../shared/Interfaces/ILogin';
import { HelperService } from '../shared/Helper.service';

@Component({
    selector: 'app-cart-sidebar',
    templateUrl: './cart-sidebar.component.html',
    styleUrls: ['./cart-sidebar.component.css']
})
export class CartSideBarComponent implements OnInit, AfterViewInit, OnDestroy {

    ngOnInit(): void {
        this.dataService.changeShowCartValue(false);
        this.customerId = Number(localStorage.getItem("customerId"));

        if (this.customerId == 0 || this.customerId == undefined || this.customerId == null) {
            this.customerId = Number(localStorage.getItem("unloggedInCustomer"));
        }

        this.getcheckout();

        // this.checkout.deliveryAddress.fullName = "";
        // this.checkout.deliveryAddress.addressLine1 = "";
        // this.checkout.deliveryAddress.addressLine2 = "";
        // this.checkout.deliveryAddress.stateProvince = "";
        // this.checkout.deliveryAddress.postalCodeZip = "";
        // this.checkout.deliveryAddress.telephoneNumber = "";
        try {
            this.dataService.showCart.subscribe(show => show === true ? this.getcheckout() : {})
        } catch (error) {

        }
      window.scrollTo(0, 0);
      this.checkout.deliveryAddress = null;
      this.emptyDeLiveryAddress();
    }
    ngAfterViewInit(): void {
    this.checkout.deliveryAddress = null;
  }
    ngOnDestroy(): void {
        this.checkout.deliveryAddress = null;
    }
    constructor(@Inject(PLATFORM_ID) private _platformId: Object, private titleService: Title,
        private ngxUiLoaderService: NgxUiLoaderService, private renderer: Renderer2,
        private _snackBar: MatSnackBar,
        private router: Router, private dataService: DataService, public dialog: MatDialog,
        private helperService: HelperService) {
        this.titleService.setTitle("Landscape Supply Guys - Checkout");
        dataService.getCurrentCart('checkout');

    }

    private emptyDeLiveryAddress():void{
    
        // this.checkout.deliveryAddress.fullName = null;
        // this.checkout.deliveryAddress.addressLine1 = null;
        // this.checkout.deliveryAddress.addressLine2 =null;
        // this.checkout.deliveryAddress.stateProvince = null;
        // this.checkout.deliveryAddress.postalCodeZip = null;
        // this.checkout.deliveryAddress.telephoneNumber = null;
        for (var key in this.checkout.deliveryAddress) {
          delete this.checkout.deliveryAddress[key];
      }
      if(this.checkout.deliveryAddress)
        {this.checkout.deliveryAddress = null;}
      }


    hideSideBar() {
        this.dataService.changeShowCartValue(false);
        localStorage.setItem('order_deliveryAddress', 'false');
        localStorage.setItem('deliveryAddress_status', 'true');
        this.helperService.changeDeliveryAddress.next();
    }

    customerId;
    EmptyCart;
    checkout: ICheckout;

    getcheckout(): void {

        this.checkout = new ICheckout();

        if (isPlatformBrowser(this._platformId)) {


            if (this.customerId == 0 || this.customerId == undefined || this.customerId == null) {
                this.customerId = Number(localStorage.getItem("unloggedInCustomer"));
            }

            if (this.customerId != 0) {
                this.ngxUiLoaderService.start();
                this.dataService.getCheckout(this.customerId)
                    .subscribe(
                        (data: ICheckout) => {
                            this.ngxUiLoaderService.stop();
                            if (data != null) {

                                this.checkout = data;
                                this.calculateCost();
                                var temp: ICartWTotal = new ICartWTotal();
                                temp.noOfItems = this.totalItems;
                                temp.total = this.subTotal;
                                this.dataService.changeCartValue(temp.noOfItems);
                                this.dataService.changeCartTotalValue(temp.total);

                            }
                            else {
                                this.EmptyCart = true;
                                try {
                                    var temp: ICartWTotal = new ICartWTotal();
                                    temp.noOfItems = 0;
                                    temp.total = 0;
                                    this.dataService.changeCartValue(temp.noOfItems);
                                    this.dataService.changeCartTotalValue(temp.total);
                                } catch (e) {

                                }
                            }
                        },
                        (err: any) => {
                            console.log(err);
                            this.ngxUiLoaderService.stop();
                            this.EmptyCart = true;
                        },
                        () => {
                            console.log("checkout Data Recived");
                        }
                    );
            }
        }
    }


    TotalProductCost = 0;
    DeliveryCharge = 0;
    DeliverySlotCost = 0;
    TaxclassTxt = "";
    TotalTax = 0;
    TotalCost = 0;
    subTotal = 0;
    ProContractorDiscount = 0;
    totalLimitedDamage = 0;
    IsLimitedAccepetd = false;

    totalItems = 0;

    calculateCost(): void {
        try {
            var totalProduct = 0;

            for (let item of this.checkout.data) {
                totalProduct = totalProduct + Number(item.totalCost);
            }

            try {
                this.totalItems = 0;
                for (var orders of this.checkout.data) {
                    for (var product of orders.order) {

                        this.totalItems++;
                    }
                }
            }
            catch (e) { }

            try {
                this.totalLimitedDamage = 0;
                if (this.IsLimitedAccepetd) {
                    for (var orders of this.checkout.data) {
                        for (var product of orders.order) {
                            if (product.productType == 'RP') {
                                this.totalLimitedDamage = this.totalLimitedDamage + (Number(product.totalPrice) * product.rentalProduct.limitedPercentage) / 100
                            }
                            if (product.productType == 'KP') {
                                this.totalLimitedDamage = this.totalLimitedDamage + (Number(product.totalPrice) * product.kitsProduct.limitedPercentage) / 100
                            }

                            this.totalItems++;
                        }
                    }
                }
            } catch (e) {

            }

            this.TotalProductCost = totalProduct;
            let tax = Number(this.checkout.franchiseTax.tax);
            this.TaxclassTxt = this.checkout.franchiseTax.name + "(" + tax + "%)";
            this.subTotal = this.TotalProductCost + this.DeliverySlotCost + this.DeliveryCharge + this.totalLimitedDamage;

            try {
                if (this.checkout.proModel.isEnabledForFranchise) {
                    if (this.checkout.proModel.discountPercentage != 0) {
                        this.ProContractorDiscount = (this.subTotal * this.checkout.proModel.discountPercentage / 100);

                        this.subTotal = this.subTotal - this.ProContractorDiscount;
                    }
                }
            } catch (e) {

            }

            this.TotalTax = this.subTotal * tax / 100;
            this.TotalCost = this.subTotal + this.TotalTax;

        } catch (e) {

        }
    }

    delete_item(id): void {

        this.TotalProductCost = 0;
        this.DeliveryCharge = 0;
        this.DeliverySlotCost = 0;
        this.TaxclassTxt = "";
        this.TotalTax = 0;
        this.TotalCost = 0;
        this.subTotal = 0;
        this.ProContractorDiscount = 0;
        this.totalLimitedDamage = 0;
        this.IsLimitedAccepetd = false;


        this.dataService.removeFromCart(id)
            .subscribe(
                (data: IResponse) => {
                    this.getcheckout();
                },
                (err: any) => {
                },
                () => {
                    console.log("delivery options selected");
                }
            );
    }

}
