import { Component, OnInit, Input } from '@angular/core';
import { DataService } from '../shared/data.service';
import { ProModel } from '../shared/Interfaces/ILogin';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit {

  @Input() fromHome = false;

  constructor(private dataService: DataService) { }
  bannerText;
  deliveryText;

  DeliveryCity;
  PostalCode;
  DeliveryCharge;

  bannerReady = false;

  ngOnInit() {
    this.generateBanner();

    this.getProContractorBannerData()
  }

  proModel: ProModel;
  customerId;
  franchiseId;

  getProContractorBannerData()
  {
    this.customerId = Number(localStorage.getItem("customerId"));
    this.franchiseId = Number(localStorage.getItem("Banner_franchiseId"));
    try {
      if (this.franchiseId != null && this.franchiseId != 0) {
        this.dataService.getProContractorBannerData(this.customerId, this.franchiseId)
          .subscribe(
            (data: ProModel) => {
              this.proModel = data;
            },
            (err: any) => console.log(err),
            () => {
              console.log("Data Recived");
            }
          );
      }
    } catch (e) {

    }
  }

  generateBanner()
  {
    this.DeliveryCity = localStorage.getItem("currentDeliveryCity");
    this.PostalCode = localStorage.getItem("currentPostalCode");
    this.DeliveryCharge = localStorage.getItem("currentDeliveryCharge");

    if (this.DeliveryCharge != null && this.DeliveryCity != null && this.PostalCode != null) {
      if (this.DeliveryCharge === "0" || this.DeliveryCharge === "") {
        this.deliveryText = "Free Delivery ";//+ this.DeliveryCity + "(" + this.PostalCode + ")";
      }
      else {
        this.deliveryText = "Equipment Rental ";//"$" + this.DeliveryCharge + " Delivery in ";//+ this.DeliveryCity + "(" + this.PostalCode + ")";
      }
      this.bannerText = "You are shopping in " + this.DeliveryCity + ", click here to change your locations";

      this.bannerReady = true;
    }
  }

}
