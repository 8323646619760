import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar, MatTabGroup } from '@angular/material';
import { Router } from '@angular/router';
import { ICustomer, IResponse } from '../../shared/Interfaces/ILogin';
import { DataService } from '../../shared/data.service';
import { DrawSignatureComponent } from './draw-signature/draw-signature.component';
import { debug } from 'node:console';


declare var $: any;

@Component({
  selector: 'app-signature-part',
  templateUrl: './signature-part.component.html',
  styleUrls: ['./signature-part.component.css'], encapsulation: ViewEncapsulation.None,
})
export class SignaturePartComponent implements OnInit {

  @ViewChild("SignatureTabs", { static: true }) SignatureTabs: MatTabGroup;

  @ViewChild('drawComp', { static: true }) drawComp: DrawSignatureComponent;

  @ViewChild('myCanvasPreSet', { static: true }) myCanvas: ElementRef;

  @ViewChild('myCanvasPreSetInitial', { static: true }) myCanvasInitial: ElementRef;

  public context: CanvasRenderingContext2D;
  public contextInitials: CanvasRenderingContext2D;

  initials = "";
  fullname = "";
  customerId;
  isCanvas = true;

  isUploadedSuccessfully = false;



  IsCanvas() {
    this.isCanvas = true;
  }

  IsDraw() {
    this.isCanvas = false;
  }

  constructor(private router: Router, private _snackBar: MatSnackBar, private dataService: DataService, private http: HttpClient) {
    dataService.getCurrentCart('signComp');
  }
  orderId;
  comingFromInvoiceSignature = false;
  invoiceSignatureOrderID = 0;

  ngOnInit() {

    try {
      let temp1 = localStorage.getItem("comingFromInvoiceSignature");
      let temp2 = localStorage.getItem("InvoiceSignatureOrderID");


      if (temp1 != undefined) {
        this.comingFromInvoiceSignature = (temp1 === 'true');
      }

      if (temp2 != undefined) {
        this.invoiceSignatureOrderID = parseInt(temp2);
      }


    } catch (e) {

    }

    localStorage.setItem("IsSigned", 'false');

    this.context = (<HTMLCanvasElement>this.myCanvas.nativeElement).getContext('2d');

    this.contextInitials = (<HTMLCanvasElement>this.myCanvasInitial.nativeElement).getContext('2d');

    this.context.canvas.width = 350;
    this.contextInitials.canvas.width = 100;

    this.context.canvas.height = 70;
    this.contextInitials.canvas.height = 70;

    this.customerId = Number(localStorage.getItem("customerId"));

    let tempThis = this;

    $(document).ready(function () {

      function checkPaddingForInvoice() {
        if (tempThis.comingFromInvoiceSignature) {
          $('.checkingboxpadding').css({ 'padding-left': '45px' });
          $('.checkingboxpadding').css({ 'padding-right': '30px' });
        }
      }
      function setDrawTab() {
      }

      if (window.matchMedia('(max-width: 380px)').matches) {
        setDrawTab();
        checkPaddingForInvoice();
        tempThis.context.canvas.width = 180;
        tempThis.contextInitials.canvas.width = 150;
      }

      else if (window.matchMedia('(max-width: 580px)').matches) {
        setDrawTab();
        checkPaddingForInvoice();
        tempThis.context.canvas.width = 190;
        tempThis.contextInitials.canvas.width = 160;
      }

      else if (window.matchMedia('(max-width: 680px)').matches) {
        setDrawTab(); checkPaddingForInvoice();
      }

      else if (window.matchMedia('(max-width: 850px)').matches) {
        setDrawTab(); checkPaddingForInvoice();
      }
    });

    this.placeText();

  }

  draw() {
    if (this.selectedTabIndex == 1) {
      this.drawComp.draw();
    }
  }

  ngOnInitView() {
  }

  selectedTabIndex;



  nameChange() {
    this.placeText();
  }

  initialsChange() {
    this.placeText();
  }

  placeText() {
    this.context.clearRect(0, 0, this.context.canvas.width, this.context.canvas.height);
    this.contextInitials.clearRect(0, 0, this.context.canvas.width, this.context.canvas.height);

    try {

      var today = new Date();

      var mnth = (today.getMonth() + 1);
      var month = mnth.toString();

      if (month.toString().length == 1) {
        month = "0" + month;
      }

      var dy = today.getDate();

      var day = dy.toString();

      if (day.toString().length == 1) {
        day = "0" + day;
      }

      var date = today.getFullYear() + '-' + month + '-' + day;
      var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      var dateTime = date;//+ ' ' + time;

      this.context.font = "1000 15px italic";
      this.context.fillText("Date: " + dateTime, 5, 58);

      this.contextInitials.font = "1000 10px italic";
      this.contextInitials.fillText("Date: " + dateTime, 5, 58);

    } catch (e) {

    }

    try {
      this.context.fillText("________________________________________", 2, 27);
      this.contextInitials.fillText("_____________________", 5, 32);
      this.contextInitials.fillText("(initial here)", 17, 42);
      this.context.font = "1000 15px SCRIPTIN";
      this.contextInitials.font = "1000 20px SCRIPTIN";
      this.context.fillText(this.fullname.toUpperCase(), 2, 25);
      this.contextInitials.fillText(this.initials.toUpperCase(), 5, 30);
    } catch (e) {

    }

  }

  sign(): boolean {
    if (this.fullname == undefined || this.fullname.trim() == '' || this.initials == undefined || this.initials.trim() == '') {
      let message = "Please enter fullname and initials";
      let action = "Ok";
      this._snackBar.open(message, action, {
        duration: 5000,
      });
      return false;
    }
    else {

      try {
        this.placeText();
      } catch (e) {

      }


      let url = `${this.dataService.baseurl}/api/saveSignatureWeb/?id=${this.customerId}&PrintedName=${this.fullname}&PrintedInit=${this.initials}`;
      let urlInitials = `${this.dataService.baseurl}/api/SaveInitials/?id=${this.customerId}&PrintedName=${this.fullname}&PrintedInit=${this.initials}`;

      if (this.comingFromInvoiceSignature) {
        url = `${this.dataService.baseurl}/api/InvoiceSignature/?OrderID=${this.invoiceSignatureOrderID}&PrintedName=${this.fullname}&PrintedInit=${this.initials}`;
        urlInitials = `${this.dataService.baseurl}/api/InvoiceSignatureInitials/?OrderID=${this.invoiceSignatureOrderID}&PrintedName=${this.fullname}&PrintedInit=${this.initials}`;
        
      }

      if (this.SignatureTabs.selectedIndex == 0) {

        const formDataInitials = new FormData();
        let fileDataInitials = this.contextInitials.canvas.toDataURL();
        debugger
        formDataInitials.append('file', fileDataInitials);
        this.http.post<IResponse>(urlInitials, formDataInitials).subscribe(res => {
          localStorage.setItem("SignedInitUrl", res.result);

          const formData = new FormData();
          let fileData = this.context.canvas.toDataURL();
          formData.append('file', fileData);
          this.http.post<IResponse>(url, formData)
            .subscribe(res2 => {
              localStorage.setItem("SignedUrl", res2.result);
              localStorage.setItem("IsSigned", 'true');
              if (this.comingFromInvoiceSignature) {
              }

            });
        });

        setTimeout(() => { }, 15000);
        return true;
      }
      else {

        this.drawComp.sign();

        return true;
      }
    }
  }
}
