import { Component, OnInit, ViewEncapsulation, PLATFORM_ID, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DataService } from '../../shared/data.service';
import { ICustomerProfile, responseMsg, IFranchiseProfileList } from '../../shared/Interfaces/IInterfaces';
import { MatDialog } from '@angular/material';
import { DialogContentDeleteDialogComponent } from './dialog-content-delete-dialog/dialog-content-delete-dialog.component';
import { Title } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-billing-profiles',
  templateUrl: './billing-profiles.component.html',
  styleUrls: ['./billing-profiles.component.css']
})
export class BillingProfilesComponent implements OnInit {

  constructor(@Inject(PLATFORM_ID) private _platformId: Object,private titleService: Title, private ngxUiLoaderService: NgxUiLoaderService, private router: Router, private dataService: DataService, public dialog: MatDialog) {
    this.titleService.setTitle("TheRentalGuys.Ca - Billing Profiles");
  }

  customerId;

  customerBillingProfiles: IFranchiseProfileList[];

  ngOnInit() {
    if (isPlatformBrowser(this._platformId)) {
      this.customerId = Number(localStorage.getItem("customerId"));
      this.ngxUiLoaderService.start();

      localStorage.removeItem('comingFromCheckout');

      this.getBillingProfile();
    }
  }

  getBillingProfile()
  {
    this.dataService.getBillingProfileList(this.customerId)
      .subscribe(
        (data: IFranchiseProfileList[]) => {
          this.ngxUiLoaderService.stop();
          this.customerBillingProfiles = data;
        },
        (err: any) => console.log(err),
        () => {
          this.ngxUiLoaderService.stop();
          console.log("postalcode Data Recived");
        }
      );
  }

  SetDefault(item: ICustomerProfile) {
    this.ngxUiLoaderService.start();
    this.dataService.makeDefaultCustomerProfile(item.id, item.franchiseId)
      .subscribe(
        (data: responseMsg) => {
          this.getBillingProfile();
          this.ngxUiLoaderService.stop();
        },
        (err: any) => console.log(err),
        () => {
          console.log("units Data Recived");
        }
      );
  }

  deleteBilling(id) {
    this.dataService.deleteCustomerBilling(id)
      .subscribe(
        (data: void) => {
          this.getBillingProfile();
        },
        (err: any) => console.log(err),
        () => {
          console.log("driver deleted");
        }
      );
  }

  openDeleteDialog(id) {
    const dialogRef = this.dialog.open(DialogContentDeleteDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if (result) {
        this.deleteBilling(id)
      }
    });
  }

}
