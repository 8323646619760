import { Component, ChangeDetectorRef, PLATFORM_ID, Inject } from '@angular/core';
import { DataService } from './shared/data.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { MediaMatcher } from '@angular/cdk/layout';
import { SecurityService } from './security/security.service';
import { MatSnackBar } from '@angular/material';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { ICompanyData } from './shared/Interfaces/ILogin';
import { NgxUiLoaderService } from 'ngx-ui-loader';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';

  // /assets/Scripts/jquery.min.js
  // /assets/Scripts/jquery-ui.js
  // /assets/Scripts/datepicker.js
  isLoaded = false;

  opened: boolean = true;
  events: string[] = [];

  constructor(private ngxUiLoaderService: NgxUiLoaderService, @Inject(DOCUMENT) private document: Document, @Inject(PLATFORM_ID) private _platformId: Object, changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, private _snackBar: MatSnackBar, private route: ActivatedRoute,
    private dataService: DataService, private router: Router, private securityService: SecurityService) {

    if (isPlatformBrowser(this._platformId)) {
      this.mobileQuery = media.matchMedia('(max-width: 600px)');
      this._mobileQueryListener = () => changeDetectorRef.detectChanges();
      this.mobileQuery.addListener(this._mobileQueryListener);


      try {
        $(document).ready(function () {
          $("#rootApp").css("background-image", "");
        });
      } catch (e) {

      }


      //require.ensure(['https://fonts.googleapis.com/icon?family=Material+Icons'], require => {
      //  let yourModule = require('https://fonts.googleapis.com/icon?family=Material+Icons');
      //  yourModule.someFunction();
      //});
    }
  }

  message;

  ngOnInit() {

    try {
      localStorage.removeItem("currentPostalCode");
    } catch (e) {

    }

    try {
      this.dataService.showCart.subscribe(show => this.opened = show)
    } catch (error) {

    }

    //this.msgService.getPermission()
    //this.msgService.receiveMessage()
    //this.message = this.msgService.currentMessage

    //this._messaging.messages.subscribe((message: { notification }) => {
    //  console.log(message.notification.title);
    //  console.log(message.notification.body);
    //});

    //this._messaging.messages.subscribe((message: { notification }) => {
    //  console.log(message.notification.title);
    //  console.log(message.notification.body);
    //  if (message.notification.body != null) {
    //    let notimsg = message.notification.body;
    //    let action = "Ok";
    //    this._snackBar.open(notimsg, action, {
    //      duration: 2000,
    //    });
    //  }
    //});

    //this.ngxUiLoaderService.start();

    //setTimeout(() => {
    //  this.ngxUiLoaderService.stop();
    //this.getCompany();
    //  }, 5000);

    if (isPlatformBrowser(this._platformId)) {
      localStorage.removeItem('company');
      this.isLoaded = true;
      //setTimeout(() => {
      this.getCompany();
      //}, 5000);

    }
  }

  getCompany() {
    localStorage.removeItem('company');
    this.dataService.getCompanyData(false)
      .subscribe(
        (data: ICompanyData) => {
          localStorage.setItem('company', JSON.stringify(data));
        },
        (err: any) => console.log(err),
        () => {
          console.log("");
        }
      );
  }


  mobileQuery: MediaQueryList;

  fillerNav = Array.from({ length: 50 }, (_, i) => `Nav Item ${i + 1}`);

  fillerContent = Array.from({ length: 50 }, () =>
    `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
       labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
       laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
       voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
       cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`);

  private _mobileQueryListener: () => void;



  ngOnDestroy(): void {
    if (isPlatformBrowser(this._platformId)) {
      this.mobileQuery.removeListener(this._mobileQueryListener);
      try {
        localStorage.removeItem("unloggedInCustomer");
      } catch (e) {

      }
    }
  }
}
