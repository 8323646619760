import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivatedRoute, Router } from '@angular/router';
import { SecurityService } from '../security/security.service';
import { DataService } from '../shared/data.service';
import { MatSnackBar } from '@angular/material';
import { ICareerBundle, PositionType, ICareer } from '../shared/Interfaces/IInterfaces';
import { IFranchise } from '../shared/Interfaces/ILogin';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.css'], encapsulation: ViewEncapsulation.None
})
export class CareerComponent implements OnInit {

  positions: PositionType[] = [];
  locations: IFranchise[] = [];
  careers: ICareer[] = [];

  FirstName: string;
  LastName: string;
  Email: string;
  Phone: string;
  Message: string;

  div1: boolean = false;
  //httpClient: any;
  //http: any;
  //apiEndPoint: any;
  //appCfg: any;
  //upload: any;


  constructor(private titleService: Title,private ngxUiLoaderService: NgxUiLoaderService, private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router, private dataService: DataService,
    private securityService: SecurityService) {
    this.titleService.setTitle("TheRentalGuys.Ca - Career");
  }

  careerBundle: ICareerBundle;

  getJobs() {
    this.ngxUiLoaderService.start();

    this.dataService.getCareer()
      .subscribe(
        (data: ICareerBundle) => {
          this.ngxUiLoaderService.stop();
          this.careerBundle = data;

          this.careers = data.careers;
          this.positions = data.positions;
          this.locations = data.franchises;
        },
        (err: any) => {
          this.ngxUiLoaderService.stop();
          console.log(err);
        },
        () => {
          console.log("Data Recived");
        }
      );
  }

  currentPosition: PositionType;
  currentFranchise: IFranchise;

  onChangePosition() {
    this.careers = this.careerBundle.careers.filter(p => p.positionId == this.currentPosition.id);
  }

  onChangeFranchise() {
    this.careers = this.careerBundle.careers.filter(p => p.franchiseId == this.currentFranchise.franchiseId);
  }

  ngOnInit() {
    //this.div1Function();
    this.getJobs();
  }

  selectFile(event) {
    this.files = event.target.files;
  }

  files: FileList;

  clearFile()
  {
    try {
      this.files[0] = null;
    } catch (e) {

    }
  }

  uploadFile() {

    if (this.files.length == 0) {
      let message = "Please select file";
      let action = "Ok";
      this._snackBar.open(message, action, {
        duration: 20000,
      });
    }
    else {
      this.files[0].name;
      if (this.FirstName != undefined && this.FirstName != ''
        && this.LastName != undefined && this.LastName != ''
        && this.Email != undefined && this.Email != ''
        && this.Phone != undefined && this.Phone != '')
      //&& this.Message != undefined && this.Message != '')
      {

        if (this.Message == undefined)
        {
          this.Message = '';
        }

        if (this.files.length == 0) {
          console.log("No file selected!");
          return

        }
        let file: File = this.files[0];

        let name = this.FirstName + " " + this.LastName;

        let url = this.dataService.baseurl + `/api/ApplyForJob/?Name=${name}&Email=${this.Email}&Phone=${this.Phone}&Message=${this.Message}`

        const uploadData = new FormData();

        uploadData.append('myFile', file, file.name);

        this.dataService.uploadResumeFile(url, uploadData).subscribe(async res => {

          try {
            if (res.status != undefined && res.status == 200) {
              let message = "Uploaded Successfully";
              let action = "Ok";
              this._snackBar.open(message, action, {
                duration: 20000,
              });
            }
          } catch (e) {

          }

          this.FirstName = '';
          this.LastName = '';
          this.Email = '';
          this.Phone = '';
          this.Message = '';
        })
      }
      else {
        let message = "Please enter all fields";
        let action = "Ok";
        this._snackBar.open(message, action, {
          duration: 20000,
        });
      }
    }
  }



  div1Function() {
    if (this.div1 == true) {
      this.div1 = false;
    }

    else {
      this.div1 = true;
    }
  }


  applyJob() {
    this.uploadFile();
  }

}
