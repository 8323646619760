import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-calgary',
  templateUrl: './calgary.component.html',
  styleUrls: ['./calgary.component.css']
})
export class CalgaryComponent implements OnInit {

  constructor(private router: Router) {
    localStorage.setItem("currentPostalCode", 'T3K');
    localStorage.setItem("comingFromFranchise", 'Yes');
  }

  ngOnInit() {
  }

}
