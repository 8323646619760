import { IKitCatagory, IStandardProductCatagory, IVirtualProducts, ITaxclass, IFranchise } from "./ILogin";


export interface IQoute {
  orderId: number;
  customerName: string;
  orderImage: string;
  customerId: number;
  productsList: string;
  franchiseName: string;
  franchiseId: number;
}

export interface customerOrdersList {
  awaitingDeliveries: OrderSP[];
  awaitingPickups: OrderSP[];
  completedOrders: OrderSP[];
}

export interface customerOrdersList {
  awaitingDeliveries: OrderSP[];
  awaitingPickups: OrderSP[];
  completedOrders: OrderSP[];
}

export interface OrderSP {
  orderId: number;
  franchiseName: string;
  deliveryDate: Date;
  isSigned: boolean;
  deliveryTime: string;
  colorCode: string;
  deliveryAddress: string;
  postalCode: string;
  customerName: string;
  orderImage: string;
  customerId: number;
  productsList: string;
  isDockDelivery: boolean;
  orderDate: Date;
  orderStatus: string;
  signatureImageUrl: string;
  pickupDates: Date;
  siteContactNumber: string;
  franchiseId: number;
  orderNotes: string;
  deliverySlotId: number;
  deliveryCharges: string;
  preOrderDiscount: number;
  totalCost: string;
  totalTax: string;
  subTotal: number;
}

export class ICareerBundle {
  positions: PositionType[];
  franchises: IFranchise[];
  careers: ICareer[];
}

export class FoundUsOn {
  id: number;
  name: string;
}

export class PositionType {
  id: number;
  positionName: string;
}

export class ICareer {
  id: number;
  positionId: number;
  franchiseId: number;
  positionDate: Date;
  positionDeadline: Date;
  title: string;
  summary: string;
  responsibilities: string;
  qualifications: string;
  benefits: string;
  employmentType: string;
  isActive: boolean;
}

export interface SingleOrder {
  transcations: Transcation[];
  transcationMessage: null;
  orderMessage: null;
  totalOrder: SingleTotalOrder;
}

export interface SingleTotalOrder {
  orderId: number;
  orderDate: Date;
  customerId: number;
  franchiseId: number;
  totalRentals: number;
  orderStatus: string;
  deliveryAddress: string;
  productsList: string;
  deliveryDate: string;
  totalCost: string;
  isDeleted: boolean;
  totalTax: string;
  transcationId: string;
  pickupDates: string;
  deliveryCharges: string;
  deliverySlotId: number;
  allSmallEquipment: boolean;
  orderNotes: string;
  totalCustomCost: number;
  preOrderDiscount: number;
  totalProductsCost: number;
  totalVirtualCost: number;
  subTotal: number;
  totalRentalProductsCost: number;
  totalStandardProductsCost: number;
  totalKitProductsCost: number;
  salesPersonalId: number;
  orderImage: string;
  siteContactNumber: string;

  taxPerCentage: string;
  taxName: string;

  couponDiscount: number;
  isDockDelivery: boolean;
  paymentProcess: boolean;
  monerisOrderId: string;
  isSigned: boolean;
  signatureImageUrl: string;
  postalCode: string;

  acceptLimitedWavier: boolean;

  isStandardRentalOrder: boolean;
  autoRenewalCanceled: boolean;
  deliverySlot: SingleDeliverySlot;
  franchise: SingleFranchise;
  order: SingleOrderElement[];
}

export interface SingleDeliverySlot {
  id: number;
  orderID: number;
  deliveryTime: string;
  deliveryTimeStr: string;
  deliveryCost: string;
  defaultSlot: boolean;
  type: string;
  colorCode: string;
}

export interface SingleFranchise {
  franchiseId: number;
  franchiseName: string;
  franchiseText: string;
  deliveryRate: null;
  buisnessNo: string;
  provinceName: string;
}

export interface SingleOrderElement {
  orderDetailId: number;
  orderId: number;
  productType: string;
  rentalProductId: number;
  pickupDate: Date;
  nextBillingDate: Date;
  deliveryDate: Date;
  orderStatus: string;
  createdDate: Date;
  deliveryAddress: string;
  quantity: number;
  totalPrice: string;
  totalDays: number;
  isdeleted: boolean;
  sameDayPickup: boolean;
  kitsProduct: IKitCatagory;
  rentalProduct: SingleRentalProduct;
  standardProduct: IStandardProductCatagory;
  virtualProduct: IVirtualProducts;
}

export interface SingleRentalProduct {
  productID: number;
  name: string;
  productImage: string;
  price: number;
  description: string;
  franchiseID: number;
  video: string;
}

export interface Transcation {
  id: number;
  orderID: number;
  authTranscationID: string;
  amount: string;
  type: string;
  purpose: string;
  date: Date;
  franchiseName: null;
  customerName: null;
  transcationTypeName: null;
}


export interface responseMsg {
  msg: string;
  status: number;
}

export interface responseId {
  id: number;
}

export interface extendDaysResponse {
  maxQuantity: number;
  factorPrice: number[];
  tax: ITaxclass;
}


export interface ProductAvalibilityResponse {
  virtualProductTotal: number;
  productTotal: number;
  total: number;
  totalTax: number;
  totalCost: number;
  totalSerialAvb: number;
}


export class ICustomerBilling {
  customerId: number;
  cardNumber: string;
  expMm: string;
  expYy: string;
  cVV: string;
  currentFranchiseId: number;
  postalCode: string;
  streetAddress: string;
  route: string;
  franchisePostalCode: string;
}


export class ITotalQoutation {
  orderId: number;
  orderDate: Date;
  franchiseId: number;
  productsList: string;
  totalCost: string;
  isDeleted: boolean;
  totalTax: string;
  deliveryCharges: string;
  orderNotes: string;
  preOrderDiscount: number;
  totalProductsCost: number;
  totalVirtualCost: number;
  subTotal: number;
  totalRentalProductsCost: number;
  totalStandardProductsCost: number;
  totalKitProductsCost: number;
  totalCustomCost: number;
  customerId: number;
  orderImage: string;
  isCompleted: boolean;
  acceptLimitedWavier: boolean;
  totalLimitedWavier: number;
  deliveryLocation: string;
  postalCode: string;

  qouteProduct: IQoutationDetails[];
}

export class IQoutationDetails {
  orderDetailId: number;
  orderId: number;
  productType: string;
  rentalProductId: number;
  standardProductId: number;
  virtualProductId: number;
  kitsProductId: number;
  quantity: number;
  totalPrice: number;
  totalDays: number;
  isdeleted: boolean;

  kitsProduct: IKitCatagory;
  rentalProduct: SingleRentalProduct;
  standardProduct: IStandardProductCatagory;
  virtualProduct: IVirtualProducts;
}


//export class IContact {
//  firstName: string;
//  lastName: string;
//  email: string;
//  phone: string;
//  message: number;
//  currentFranchise: string;
//}


export class IContact {
  fullname: string;
  email: string;
  phone: string;
  location: string;
  message: string;
  currentFranchise: string;
}

export class IFranchiseProfileList {
  franchiseName: string;
  customerProfile: ICustomerProfile[];
}


export class ICustomerProfile {
  id: number;
  franchiseName: string;
  card4Digits: string;
  isDefault: boolean;
  franchiseId: number;
}

export class IResponseClass {
  result: string;
}
