import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../shared/data.service';
import { ICustomer } from '../shared/Interfaces/ILogin';
import { MatSnackBar, MatTabGroup } from '@angular/material';
import { Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-signaturecomp',
  templateUrl: './signaturecomp.component.html',
  styleUrls: ['./signaturecomp.component.css']
})
export class SignaturecompComponent implements OnInit {

  @ViewChild("SignatureTabs", { static: true }) SignatureTabs: MatTabGroup;

  @ViewChild(SignaturePad, { static: true }) signaturePad: SignaturePad;

  @ViewChild('myCanvasPreSet', { static: true }) myCanvas: ElementRef;

  public context: CanvasRenderingContext2D;

  initials = "";
  fullname = "";
  customerId;
  isCanvas = true;
  customer;

  isUploadedSuccessfully = false;



  IsCanvas() {
    this.isCanvas = true;
  }

  IsDraw() {
    this.isCanvas = false;
  }

  constructor(private router: Router,private _snackBar: MatSnackBar, private dataService: DataService, private http: HttpClient) {
    dataService.getCurrentCart('signComp');
  }

  comingFromInvoiceSignature = false;
  invoiceSignatureOrderID = 0;

  ngOnInit() {

    try {
      let temp1=localStorage.getItem("comingFromInvoiceSignature");
      let temp2 = localStorage.getItem("InvoiceSignatureOrderID");

      if (temp1 != undefined) {
        this.comingFromInvoiceSignature = (temp1 === 'true');
      }

      if (temp2 != undefined) {
        this.invoiceSignatureOrderID = parseInt(temp2);
      }


    } catch (e) {

    }

    localStorage.setItem("IsSigned", 'false');

    this.context = (<HTMLCanvasElement>this.myCanvas.nativeElement).getContext('2d');

    this.customerId = Number(localStorage.getItem("customerId"));

    this.dataService.getCustomer(this.customerId)
      .subscribe(
        (data: ICustomer) => {
          this.customer = data;

          this.fullname = data.firstName.toUpperCase() + " " + data.lastName.toUpperCase();

          this.initials = data.firstName.toUpperCase().charAt(0) + "" + data.lastName.toUpperCase().charAt(0);
          this.placeText();


        },
        (err: any) => console.log(err),
        () => {
          console.log("Data Recived");
        }
      );

    let tempThis = this;

    $(document).ready(function () {

      function checkPaddingForInvoice()
      {
        if (tempThis.comingFromInvoiceSignature) {
          $('.checkingboxpadding').css({ 'padding-left': '45px' });
          $('.checkingboxpadding').css({ 'padding-right': '30px' });
        }
      }
      function setDrawTab() {
        try {
          tempThis.draw();
          const tabGroup = tempThis.SignatureTabs;
          tabGroup.selectedIndex = 2;
        } catch (e) { }
      }

      if (window.matchMedia('(max-width: 380px)').matches) {
        setDrawTab();
        checkPaddingForInvoice();
      }

      else if (window.matchMedia('(max-width: 580px)').matches) {
        setDrawTab(); checkPaddingForInvoice();
      }

      else if (window.matchMedia('(max-width: 680px)').matches) {
        setDrawTab(); checkPaddingForInvoice();
      }

      else if (window.matchMedia('(max-width: 850px)').matches) {
        setDrawTab(); checkPaddingForInvoice();
      }
    });

    this.placeText();
    this.clearSignature();
  }

  ngOnInitView() {
    //this.signaturePad.clear();
    //this.signaturePad.set('minWidth', 5);
    //$(document).ready(function () {
    //  $('canvas').delay(50000).css({ 'background': 'white' });
    //  $('canvas').delay(50000).css({ 'border': '1px black solid' });
    //});
  }

  draw() {
    this.clearSignature();
  }

  nameChange() {
    this.placeText();
  }

  initialsChange() {
    this.placeText();
  }

  placeText() {
    this.context.clearRect(0, 0, this.context.canvas.width, this.context.canvas.height);
    this.context.font = "1000 28px scriptin";
    this.context.fillText(this.fullname.toUpperCase(), 10, 40);
    this.context.fillText(this.initials.toUpperCase(), 10, 85);

    var today = new Date();
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    var dateTime = date + ' ' + time;

    this.context.font = "1000 15px italic";
    this.context.fillText(dateTime, 10, 135);

  }

  clearSignature() {
    try {
      this.signaturePad.clear();
    } catch (e) {

    }
    let tempThis = this;

    $(document).ready(function () {


      if (window.matchMedia('(max-width: 380px)').matches) {
        
        tempThis.signaturePad.set('canvasWidth', 300);
        tempThis.signaturePad.set('canvasHeight', 200);
        $('canvas').delay(500).css({ 'background': 'white' });
        $('canvas').delay(500).css({ 'border': '1px  #8BC400 solid' });

      }

      else if (window.matchMedia('(max-width: 580px)').matches) {
        

        tempThis.signaturePad.set('canvasWidth', 300);
        tempThis.signaturePad.set('canvasHeight', 200);
        $('canvas').delay(500).css({ 'margin-left': '5%' });
        $('canvas').delay(500).css({ 'background': 'white' });
        $('canvas').delay(500).css({ 'border': '1px  #8BC400 solid' });



      }

      else if (window.matchMedia('(max-width: 680px)').matches) {
        
        tempThis.signaturePad.set('canvasWidth', 400);
        tempThis.signaturePad.set('canvasHeight', 235);
        $('canvas').delay(500).css({ 'margin-left': '15%' });
        $('canvas').delay(500).css({ 'background': 'white' });
        $('canvas').delay(500).css({ 'border': '1px  #8BC400 solid' });

      }

      else if (window.matchMedia('(max-width: 850px)').matches) {
        
        tempThis.signaturePad.set('canvasWidth', 400);
        tempThis.signaturePad.set('canvasHeight', 235);
        $('canvas').delay(500).css({ 'margin-left': '15%' });
        $('canvas').delay(500).css({ 'background': 'white' });
        $('canvas').delay(500).css({ 'border': '1px  #8BC400 solid' });

      }
      else {
        tempThis.signaturePad.set('canvasWidth', 450);
        tempThis.signaturePad.set('canvasHeight', 280);
        $('canvas').delay(500).css({ 'background': 'white' });
        $('canvas').delay(500).css({ 'border': '1px  #8BC400 solid' });
        $('canvas').delay(500).css({ 'margin-left': '25%' });

        var today = new Date();
        var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        var dateTime = date + ' ' + time;

        var my_canvas = $('canvas').get(1);
        var gctx = my_canvas.getContext("2d");
        gctx.font = '1000 15px italic';
        gctx.fillText(dateTime, 10, 250);
      }
    });
  }


  drawComplete() {
    // will be notified of szimek/signature_pad's onEnd event
    console.log(this.signaturePad.toDataURL());
  }

  drawStart() {
    // will be notified of szimek/signature_pad's onBegin event
    console.log('begin drawing');
  }

  checked(values: any) {
    if (values.currentTarget.checked) {
      let url = `${this.dataService.baseurl}/api/saveSignatureWeb/?id=${this.customerId}`;

      if (this.comingFromInvoiceSignature)
      {
        url = `${this.dataService.baseurl}/api/InvoiceSignature/?OrderID=${this.invoiceSignatureOrderID}`;
      }

      if (this.isCanvas) {
        const formData = new FormData();

        let fileData = this.context.canvas.toDataURL();
        formData.append('file', fileData);
        this.http.post(url, formData)
          .subscribe(res => {
            console.log(res);
            let message = "Successfully Signed, Thankyou";
            let action = "Ok";
            this._snackBar.open(message, action, {
              duration: 5000,
            });
            this.isUploadedSuccessfully = true;
            localStorage.setItem("IsSigned", 'true');


            if (this.comingFromInvoiceSignature)
            {
              this.router.navigate(["/"]);
            }
          })
      }
      else {
        const formData = new FormData();

        let fileData = this.signaturePad.toDataURL();
        formData.append('file', fileData);
        this.http.post(url, formData)
          .subscribe(res => {
            console.log(res);
            let message = "Successfully Signed, Thankyou";
            let action = "Ok";
            this._snackBar.open(message, action, {
              duration: 5000,
            });
            this.isUploadedSuccessfully = true;
            localStorage.setItem("IsSigned", 'true');
            if (this.comingFromInvoiceSignature) {
              this.router.navigate(["/"]);
            }
          })
      }
    }
  }

}
