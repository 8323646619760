import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SecurityService } from './security.service';
import { ILoginResult, ILogin } from '../shared/Interfaces/ILogin';
import { tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DataService } from '../shared/data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate
{

   securityObject: ILoginResult = null;

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {

      //if (this.securityService.checkExpiry()) {

      if (this.securityService.checkLoginStatus()) {
          return true;
        }
        else {
          this.router.navigate(['/login'], {
            queryParams: { returnUrl: state.url }
          });
        }
      //}
      //else
      //{
      //  this.router.navigate(['/pages/login'], {
      //    queryParams: { returnUrl: state.url }
      //  });
      //}
      return ;
    }

  constructor(private securityService: SecurityService, private router: Router, private http: HttpClient, private dataservice: DataService)
  {
    this.securityObject = securityService.authResult;
  }

}
