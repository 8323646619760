import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { DeliveryAddress, ICheckout, IFranchsiePosalCode, ResponseClass } from '../../shared/Interfaces/ILogin';
import { MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DataService } from '../../shared/data.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { debounceTime } from 'rxjs/operators';
import { HelperService } from 'src/app/shared/Helper.service';

@Component({
  selector: 'app-select-delivery-address-dialog',
  templateUrl: './select-delivery-address-dialog.component.html',
  styleUrls: ['./select-delivery-address-dialog.component.css'], encapsulation: ViewEncapsulation.None 
})
export class SelectDeliveryAddressDialogComponent implements OnInit {

  deliveryInfo = new FormGroup({
    deliveryAddress: new FormControl(''),
    postalCode: new FormControl(''),
    siteContact: new FormControl(''),
    notes: new FormControl(''),
    unitNo: new FormControl('')
  });

  allAddresses: DeliveryAddress[] = [];
  checkout: ICheckout;
  customerId: number;
  franchiseId: number;
  public isPageReload: string;
  
  notes: string = "";

  currentAddress: number;
  constructor(private _snackBar: MatSnackBar, public fb: FormBuilder, private dataService: DataService,
    private ngxUiLoaderService: NgxUiLoaderService, private helperService: HelperService,
    private dialogRef: MatDialogRef<SelectDeliveryAddressDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
    this.customerId = data.customerId;
    this.franchiseId = data.franchiseId;
    this.currentAddress = data.addressId;
  }

  ngOnInit() {
    this.getDeliveryAddress();
    this.isPageReload =localStorage.getItem('order_deliveryAddress');

    if (this.deliveryInfo.valid) {
      this.updateAddress();
    }

    this.deliveryInfo.valueChanges.pipe(
      debounceTime(5000)
    ).subscribe((value) => {
      //if (this.deliveryInfo.valid) {

      this.updateAddress();

      //}
    });
  }

  getDeliveryAddress() {
    this.dataService.getCustomerDeliveryAddressList(this.customerId, this.franchiseId)
      .subscribe(
        (data: DeliveryAddress[]) => {
          this.allAddresses = data;
        },
        (err: any) => console.log(err),
        () => {
          console.log("units Data Recived");
        }
      );
  }

  updateAddress(): void {

    if (this.notes != null && this.notes != undefined) {
      this.dataService.updateNotesAddress(this.notes, this.customerId)
        .subscribe(
          (data: IFranchsiePosalCode) => {
          },
          (err: any) => {
          },
          () => {
            console.log("delivery options selected");
          }
        );
    }

  }
  saveChanges(address: DeliveryAddress): void {

    this.dataService.selectDeliveryAddress(address.addressId, this.customerId).subscribe(
      (data: ResponseClass) => {
        localStorage.setItem('order_deliveryAddress', 'true');
        this.ngxUiLoaderService.start();
        this.dataService.getCheckout(this.customerId)
        .subscribe(
          (checkoutData: ICheckout) => {
            this.ngxUiLoaderService.stop();
            if (checkoutData != null) {
              this.checkout = checkoutData;
              this.updateAddress();
              this.helperService.checkoutDetails=this.checkout;
              this.helperService.changecheckoutValue.next();
              localStorage.setItem('checkout', JSON.stringify(this.checkout));
              localStorage.setItem('deliveryAddress_status', 'false');
              localStorage.setItem('order_deliveryAddress', 'true');
            }}
            );
        
        this.dialogRef.close();
      },
      (err: any) => console.log(err)
    );
  }

}
