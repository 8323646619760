import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DataService } from '../shared/data.service';
import { Router } from '@angular/router';
import { customerOrdersList } from '../shared/Interfaces/IInterfaces';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { UpcomingRenewalsTemp } from '../shared/Interfaces/ILogin';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-auto-renewals',
  templateUrl: './auto-renewals.component.html',
  styleUrls: ['./auto-renewals.component.css'], encapsulation: ViewEncapsulation.None
})
export class AutoRenewalsComponent implements OnInit {

  constructor(private titleService: Title, private ngxUiLoaderService: NgxUiLoaderService, private router: Router, private dataService: DataService) {
    dataService.getCurrentCart('orders');
    this.titleService.setTitle("TheRentalGuys.Ca - Billing Profiles");




  }

  customerId;
  customerRenewals: UpcomingRenewalsTemp[];

  ngOnInit()
  {
    this.customerId = Number(localStorage.getItem("customerId"));
    this.ngxUiLoaderService.start();
    this.dataService.getAutoRenewals(this.customerId)
      .subscribe(
        (data: UpcomingRenewalsTemp[]) => {
          this.ngxUiLoaderService.stop();
          this.customerRenewals = data;
        },
        (err: any) => console.log(err),
        () => {
          this.ngxUiLoaderService.stop();
          console.log("postalcode Data Recived");
        }
      );
  }


  cancelRenewal(item: UpcomingRenewalsTemp) {
    let nextBilling = item.nextBillingDate.toString();
    localStorage.setItem("endBillingDate", nextBilling);
    this.router.navigate(['/completeStandardRental/' + item.orderId]);
  }

}
