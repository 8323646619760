import { Component, OnInit} from '@angular/core';
import { DataService } from '../shared/data.service';
import { Ifaq } from '../shared/Interfaces/faq';


@Component({
  selector: 'app-faq-page',
  templateUrl: './faq-page.component.html',
  styleUrls: ['./faq-page.component.css']
})
export class FaqPageComponent implements OnInit {
  Ifaq:Ifaq[]=[];
  constructor(private api:DataService) {

  }
  Getfaq

  panelOpenState = false
  panelOpenState2 = false
  panelOpenState3 = false
  panelOpenState4 = false
  panelOpenState5 = false
  panelOpenState6 = false
  panelOpenState7 = false
  panelOpenState8 = false
  panelOpenState9 = false
  panelOpenState10 = false
  panelOpenState11 = false
  panelOpenState12 = false
  panelOpenState13 = false
  panelOpenState14 = false
  panelOpenState15 = false
  panelOpenState16 = false
  panelOpenState17 = false
  panelOpenState18 = false
  panelOpenState19 = false
  panelOpenState20 = false


  getfaq(){
    this.api.Getfaq().subscribe((data:Ifaq[])=>{
      this.Ifaq=data;
      
      
    })
  }

  ngOnInit() {
  

this.getfaq();
  }

}
