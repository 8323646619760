import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { IContact } from '../shared/Interfaces/IInterfaces';
import { DataService } from '../shared/data.service';
import { MatSnackBar } from '@angular/material';
import { ICompanyData, ICompany } from '../shared/Interfaces/ILogin';
import { Title } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';

declare var $: any;

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  contactus = "assets/images/cu.PNG";
  contactusenvelop = "assets/images/contactusenvelop.PNG";


  contact: IContact = new IContact();

  constructor(@Inject(PLATFORM_ID) private _platformId: Object, private titleService: Title, private _snackBar: MatSnackBar, private dataService: DataService) {
    //this.titleService.setTitle("TheRentalGuys.Ca - Contact Us");
  }


  ngOnInit() {
    setTimeout(() => {


      if (isPlatformBrowser(this._platformId)) {

        try {
           $(document).ready(function () {
             $('#contact-area').css("background-image", `url(assets/theme/contactBG/Contactbackground.jpg) no-repeat center top')`)
          });
        }
        catch (e) {

        }

        let tempThis = this;

        var retrievedObject = localStorage.getItem('company');
        if (retrievedObject != undefined && retrievedObject != null) {
          this.companyData = JSON.parse(retrievedObject);
          this.company = this.companyData.company;

          //this.company.contactUsBackgroundUrl = this.dataService.baseurlAdmin + '/theme/contactBG/' + this.company.headerLogo;
          //$(document).ready(function () {
          //  $('#contact-area').css("background-image", `url('${tempThis.company.contactUsBackgroundUrl}')`)
          //});
        }
        else {
          this.getCompany();
        }
      }
    }, 5000);
  }

  companyData: ICompanyData;
  company: ICompany;

  getCompany() {
    localStorage.removeItem('company');
    this.dataService.getCompanyData(false)
      .subscribe(
        (data: ICompanyData) => {
          //data.company.contactUsBackgroundUrl = this.dataService.baseurlAdmin + '/theme/contactBG/' + data.company.contactUsBackgroundUrl;
          this.company = data.company;
          let tempThis = this;
          //$(document).ready(function () {
          //  $('#contact-area').css("background-image", `url('${data.company.contactUsBackgroundUrl}')`)
          //});
        },
        (err: any) => console.log(err),
        () => {
          console.log("");
        }
      );
  }

  sendContact() {
    if (this.contact.email != undefined && this.contact.fullname != undefined && this.contact.location != undefined && this.contact.message != undefined && this.contact.phone != undefined) {
      this.contact.currentFranchise = localStorage.getItem("currentDeliveryCity");
      this.dataService.contactRequest(this.contact)
        .subscribe(
          (data: IContact) => {
            this.contact = new IContact();
            let message = "We will respond soon, Thankyou";
            let action = "Ok";
            this._snackBar.open(message, action, {
              duration: 2000,
            });
          },
          (err: any) => console.log(err),
          () => {
            console.log("Data Recived");
          }
        );
    }
    else {
      let message = "Please enter all fields";
      let action = "Ok";
      this._snackBar.open(message, action, {
        duration: 2000,
      });
    }
  }

}





