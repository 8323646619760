import { Component, OnInit } from '@angular/core';
import { IStandardProducts, IRentalProductBookings, responseCustomerVerify, responseCustomerId } from '../shared/Interfaces/ILogin';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../shared/data.service';
import { SecurityService } from '../security/security.service';
import Swal from 'sweetalert2';
import { NgxUiLoaderService } from 'ngx-ui-loader';

declare var $: any;

@Component({
  selector: 'app-standard-product',
  templateUrl: './standard-product.component.html',
  styleUrls: ['./standard-product.component.css']
})
export class StandardProductComponent implements OnInit {

  ProductModel: IStandardProducts = new IStandardProducts();

  constructor(private ngxUiLoaderService: NgxUiLoaderService,private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router, private dataService: DataService,
    private securityService: SecurityService) {
    dataService.getCurrentCart('standard');
    let ss = securityService.checkLoginStatus();
    if (ss) {
      this.IsLoggedIn = true;
    }
  }

  IsPageLoaded = false;
  productId = 0;
  franchiseId;
  DeliveryCharge = "$0";
  IsLoggedIn = false;
  booking: IRentalProductBookings = new IRentalProductBookings();
  DeliveryCity = "";

  ngOnInit() {

    this.productId = Number(localStorage.getItem("sproductId"));//parseInt(this.route.snapshot.params['id']);
    if (this.productId != undefined)
    {
      try {
        this.productId = parseInt(this.route.snapshot.params['id']);
      } catch (e) {

      }
    } 

    this.DeliveryCity = localStorage.getItem("currentDeliveryCity");
    this.DeliveryCharge = localStorage.getItem("currentDeliveryCharge");
    this.franchiseId = Number(localStorage.getItem("franchiseId"));


    this.booking.customerId = Number(localStorage.getItem("customerId"));
    this.booking.productId = this.productId;
    this.booking.franchiseId = this.franchiseId;
    this.booking.bookingDate = new Date();
    this.booking.totalNumberOfDays = 1;
    this.booking.quantity = 1;
    this.booking.productSerialNo = "";
    this.booking.ordersNotes = "";
    this.booking.paymentStatus = "";
    this.booking.ipaddress = "";

    if (this.productId != undefined && this.productId!=0) {
      this.loadCalender();
      this.dataService.getStandardProducts(this.productId)
        .subscribe(
          (data: IStandardProducts) => {
            this.ProductModel = data;
            this.booking.totalAmount = data.price.toString();
            this.IsPageLoaded = true;
          },
          (err: any) => console.log(err),
          () => {
            console.log("rental product Data Recived");
          }
        );
    }
    else
    {
      this.router.navigate(["/"]);
    }
    

    

  }

  loadCalender() {
    let tmpThis = this;
    let currentUnavaliable = [];
    $(document).ready(function () {

      var maxium_quantity = tmpThis.ProductModel.stock;

      var flag = true;
      //$('#datepicker').prop('disabled', true);
      $("#datepicker_div").hide();

      $("#TotalNumberOfDays").val('1');
      var Quantity = $("#Quantity").val("1");

      if (flag == true) {
        //InitilizeDisableDates();
        flag = false;
      }

      var disabledDays = [];

      $("#datepicker").datepicker({

        minDate: 0,
        beforeShowDay: function (date) {

          var today = new Date();


          if (today.getDate() == date.getDate() && today.getMonth() == date.getMonth() && today.getFullYear() == date.getFullYear()) {
            try {
              var todayavb = $("#IsSameDayDeliveryAvb").val();
              var string = $.datepicker.formatDate('mm-dd-yy', date);
              var IsAfterCutOffTime = $("#IsAfterCutOffTime").val();
              var isDisabled = ($.inArray(string, disabledDays) != -1);
              if (todayavb == "false" || todayavb == false || isDisabled || IsAfterCutOffTime) {
                return [false, ".ui-datepicker td.ui-state-disabled-previous-dates > span", "Already Booked"];
              } else {
                return [true, "Avaliable", "This day is avaliable for booking"];
              }
            } catch (e) { }
          }




          var Quantity = $("#TotalNumberOfDays").val();
          var checkdate = new Date(date);
          var result = IsInBetween(checkdate, disabledDays);

          if (result) {
            var string = $.datepicker.formatDate('mm-dd-yy', date);
            var isDisabled = ($.inArray(string, disabledDays) != -1);

            if (isDisabled) {
              return [false, ".ui-datepicker td.ui-state-disabled > span", "Already Booked"];
            }
            return [false, ".ui-datepicker td.ui-state-disabled > span", "Sorry we are already booked for this day"];
            //return [true, "dp-highlight-unselectable", "Sorry we are already booked for this day"];
          }

          var StartDate = $("#StartDate").val();
          var EndDate = $("#EndDate").val();
          var date1 = $.datepicker.parseDate($.datepicker._defaults.dateFormat, StartDate);
          var date2 = $.datepicker.parseDate($.datepicker._defaults.dateFormat, EndDate);



          var day = date.getDay();
          var string = $.datepicker.formatDate('mm-dd-yy', date);

          var isDisabled = ($.inArray(string, disabledDays) != -1);

          var isAvaliable = true;

          var isHighlight = date1 && ((date.getTime() == date1.getTime()) || (date2 && date >= date1 && date <= date2));

          if (isDisabled) {
            return [!isDisabled, ".ui-datepicker td.ui-state-disabled > span", "Sorry we are already booked for this day"];
          }
          else if (isHighlight) {

            return [true, isHighlight ? "dp-highlight" : ""];
          }
          else {

            //if (flag == true) {

            //  flag = false;
            //}

            return [isAvaliable, "Avaliable", "This day is avaliable for booking"];

          }
        }
      })
        .on("change", function () {

          var date = new Date($(this).val());
          var string = $.datepicker.formatDate('mm-dd-yy', date);

          var isDisabled = ($.inArray(string, this.currentUnavaliable) != -1);


          if (isDisabled) {
            $("#datepicker_message").css("visibility", "visible");
            $("#datepicker_message").css("position", "relative");
            $("#datepicker_message").text("Sorry this date isnt avalible for your date range");
          }
          else {
            $("#datepicker_message").css("position", "absolute");
            $("#datepicker_message").css("visibility", "hidden");

            flag = true;

            var Quantity = $("#TotalNumberOfDays").val();

            $("#StartDate").val($(this).val());

            tmpThis.booking.startDate = $(this).val();

            var enddate = new Date(date);


            var fullDate = new Date(enddate);

            fullDate.setDate(fullDate.getDate() + Number(Quantity));

            var twoDigitMonth = (fullDate.getMonth() + 1) + "";
            if (twoDigitMonth.length == 1) {
              twoDigitMonth = "0" + twoDigitMonth;
            }
            var twoDigitDate = fullDate.getDate() + "";
            if (twoDigitDate.length == 1) {
              twoDigitDate = "0" + twoDigitDate;
            }
            var currentDate = twoDigitMonth + "/" + twoDigitDate + "/" + fullDate.getFullYear();

            $("#EndDate").val(currentDate);

            tmpThis.booking.endDate = currentDate;

            $(this).datepicker();
            $("#RentNow").prop('disabled', false);
            $("#RentNowUnloggedIn").prop('disabled', false);
            $("#AddtoCart").css('pointer-events', 'visible');
            $("#AddtoCart").css('opacity', '1');

            $("#AddtoCartUnloggedIn").css('pointer-events', 'visible');
            $("#AddtoCartUnloggedIn").css('opacity', '1');

          }

        });

      $("#TotalNumberOfDays").on("change", function () {

        if (Number($("#TotalNumberOfDays").val()) <= 28) {
          update_price();
          UpdateNumberOfDays();
          $("#DaysMaxReached").css("visibility", "hidden");
        } else {
          $("#TotalNumberOfDays").val("28");
          update_price();
          UpdateNumberOfDays();
          $("#DaysMaxReached").css("visibility", "visible");
        }
      });

      $("#Quantity").on("change", function () {
        var qua = Number($("#Quantity").val());
        if (qua < tmpThis.ProductModel.stock) {
          update_price();
          UpdateNumberOfDays();
        } else {
          $("#Quantity").val("" + tmpThis.ProductModel.stock);
          update_price();
          UpdateNumberOfDays();
          $("#MaxReached").css("visibility", "visible");
        }
      });

      $("#EndDate").change("change", function () {

        $("#RentNow").prop('disabled', false);
      });

      $('#plus_days').click(function (e) {

        if (Number($("#TotalNumberOfDays").val()) < 28) {
          $("#TotalNumberOfDays").val(Number($("#TotalNumberOfDays").val()) + 1);
          UpdateNumberOfDays();
          update_price()
          $("#DaysMaxReached").css("visibility", "hidden");
        }
        else {

          $("#DaysMaxReached").css("visibility", "visible");
        }

      });

      $('#minus_days').click(function (e) {

        if (Number($("#TotalNumberOfDays").val()) > 1) {
          $("#TotalNumberOfDays").val(Number($("#TotalNumberOfDays").val()) - 1);
          UpdateNumberOfDays();
          update_price();
          $("#DaysMaxReached").css("visibility", "hidden");
        }
        if (Number($("#TotalNumberOfDays").val()) > 28) {
          $("#DaysMaxReached").css("visibility", "visible");
        }
      });

      $('#plus_quantity').click(function (e) {

        var qua = Number($("#Quantity").val());
        if (qua < tmpThis.ProductModel.stock) {
          $("#Quantity").val(Number($("#Quantity").val()) + 1);
          $("#MaxReached").css("visibility", "hidden");
          UpdateNumberOfDays();
          update_price();

        }
        else {
          $("#MaxReached").css("visibility", "visible");
        }
      });

      $('#minus_quantity').click(function (e) {

        if (Number($("#Quantity").val()) > 1) {
          $("#Quantity").val(Number($("#Quantity").val()) - 1);
          UpdateNumberOfDays();
          update_price();
          $("#MaxReached").css("visibility", "hidden");
        }

        var qua = Number($("#Quantity").val());
        if (qua > tmpThis.ProductModel.stock) { $("#MaxReached").css("visibility", "visible"); }
      });

      function update_price() {
        var quantity = Number($("#Quantity").val());
        var TotalNumberOfDays = Number($("#TotalNumberOfDays").val());
        //var temp = tmpThis.ProductModel.price * quantity; //$("#Day_" + TotalNumberOfDays).val();
        //var Computed_Unit_Price = temp;//Number(temp.replace('$', ''));

        var Total_ammount: number = quantity * tmpThis.ProductModel.price;

        var num = Total_ammount;// parseFloat(Total_ammount);
        var new_num = num.toFixed(2);

        $("#Total").text('$' + new_num)
      }

      function UpdateNumberOfDays() {
        //Loader(true);
        $("#datepicker").datepicker('setDate', null);
        $("#StartDate").val("");
        $("#EndDate").val("");
        $("#EndDate").text("");

        flag = true;

        var Quantity = $("#TotalNumberOfDays").val();

        var date = $("#datepicker").val();


        currentUnavaliable = [];
        $("#datepicker").datepicker("setDate", date);

        //Loader(false);
        var enddate = new Date(date);
        var fullDate = new Date(enddate);

        fullDate.setDate(fullDate.getDate() + Number(Quantity - 1));

        var twoDigitMonth = (fullDate.getMonth() + 1) + "";
        if (twoDigitMonth.length == 1) {
          twoDigitMonth = "0" + twoDigitMonth;
        }
        var twoDigitDate = fullDate.getDate() + "";
        if (twoDigitDate.length == 1) {
          twoDigitDate = "0" + twoDigitDate;
        }
        var currentDate = twoDigitMonth + "/" + twoDigitDate + "/" + fullDate.getFullYear();


      }
      $('#datepicker').datepicker();
      var date = $("#datepicker").val();
      $("#datepicker").datepicker("setDate", date);
      //$('#ProductID').val(data.productID);
      $("#datepicker_div").show();

      //function InitilizeDisableDates() {
      //  var ProductID = tmpThis.productId;
      //  //var ProductID = 1000578;
      //  var ProductSerials = $('#ProductSerialNo').text();
      //  var Quantity = tmpThis.booking.quantity;
      //  //var Quantity = 1;
      //  var FranchiseId = Number(localStorage.getItem("franchiseId"));
      //  currentUnavaliable = [];
      //  disabledDays = [];

      //  $.ajax({
      //    type: "GET",
      //    url: `${tmpThis.dataService.baseurl}/api/RentalBooking/?ProductID=${ProductID}&Quantity=${Quantity}&FranchiseId=${FranchiseId}`,
      //    contentType: "application/json",
      //    dataType: "json",
      //    success: function (data) {
      //      if (data.maxQuantity > 0) {
      //        disabledDays = data.bookingslist;
      //        maxium_quantity = data.maxQuantity;
      //        $('#AvaliableSerialsList').val(data.serialsFor);
      //        $('#datepicker').datepicker();
      //        var date = $("#datepicker").val();
      //        $("#datepicker").datepicker("setDate", date);
      //        $('#ProductID').val(data.productID);
      //        $("#datepicker_div").show();
      //      }
      //      else {
      //        var franchiseName = $('#FranchiseId :selected').text();
      //        alert("Sorry for inconvience, We dont have this product in stock for " + franchiseName);
      //        $("#datepicker_div").hide();
      //      }
      //    },
      //    error: function (data) {
      //      alert("Error occured on Initilize Datebase API");
      //    }

      //  });
      //}
    });


    $(function () {
      $("#datepicker").datepicker();
    });

    function makeDate(Objdate) {
      var fullDate = new Date(Objdate);

      var twoDigitMonth = (fullDate.getMonth() + 1) + "";
      if (twoDigitMonth.length == 1) {
        twoDigitMonth = "0" + twoDigitMonth;
      }
      var twoDigitDate = fullDate.getDate() + "";
      if (twoDigitDate.length == 1) {
        twoDigitDate = "0" + twoDigitDate;
      }
      var currentDate = twoDigitMonth + "/" + twoDigitDate + "/" + fullDate.getFullYear();

      return currentDate;
    }

    function IsInBetween(date, disabledDays) {
      var Quantity = $("#TotalNumberOfDays").val();
      if (Quantity > 1) {
        var possibleStartdate = new Date(date);

        var possibleEnddate = new Date(date);

        possibleEnddate.setDate(possibleEnddate.getDate() + Number(Quantity - 1));


        for (var chk = 0; chk < disabledDays.length; chk++) {

          var c = disabledDays[chk].split("-");

          var day = c[1];
          var year = c[2];
          var month = c[0] - 1;

          var check = new Date(year, month, day);
          {

            if (check.getDate() == possibleStartdate.getDate() && check.getMonth() == possibleStartdate.getMonth() && check.getFullYear() == possibleStartdate.getFullYear()) {
              return true;
            }
            else if (check.getDate() == possibleEnddate.getDate() && check.getMonth() == possibleEnddate.getMonth() && check.getFullYear() == possibleEnddate.getFullYear()) {
              var fullDate = new Date(possibleStartdate);


              var twoDigitMonth = (fullDate.getMonth() + 1) + "";
              if (twoDigitMonth.length == 1) {
                twoDigitMonth = "0" + twoDigitMonth;
              }
              var twoDigitDate = fullDate.getDate() + "";
              if (twoDigitDate.length == 1) {
                twoDigitDate = "0" + twoDigitDate;
              }
              var currentDate = twoDigitMonth + "-" + twoDigitDate + "-" + fullDate.getFullYear();

              currentUnavaliable.push(currentDate);
              return true;
            }
            else if (check > possibleStartdate && check < possibleEnddate) {

              var fullDate = new Date(possibleStartdate);

              var twoDigitMonth = (fullDate.getMonth() + 1) + "";
              if (twoDigitMonth.length == 1) {
                twoDigitMonth = "0" + twoDigitMonth;
              }
              var twoDigitDate = fullDate.getDate() + "";
              if (twoDigitDate.length == 1) {
                twoDigitDate = "0" + twoDigitDate;
              }
              var currentDate = twoDigitMonth + "-" + twoDigitDate + "-" + fullDate.getFullYear();

              currentUnavaliable.push(currentDate);

              return true;
            }
          }

        }
      }

      return false;
    }
  }

  addToCart = false;
  bookProductCart() {
    this.addToCart = true;
    this.bookProduct();
  }

  bookProduct(): void {
    if (this.booking.startDate == undefined || this.booking.startDate == '') {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Please select Delivery Date',
        showConfirmButton: false,
        timer: 1500
      });
    } else {
      this.franchiseId = Number(localStorage.getItem("Banner_franchiseId"));
      this.booking.franchiseId = this.franchiseId;
      this.dataService.bookStandardProduct(this.booking).subscribe(
        (data: void) => {
          let message = "Successfully Booked";
          let action = "Ok";
          this._snackBar.open(message, action, {
            duration: 2000,
          });

        this.dataService.changeShowCartValue(true);
          // if (this.addToCart) {
          //   this.router.navigate(["/"]);
          // } else {
          //   this.router.navigate(["/checkout"]);
          // }
        },
        (err: any) => console.log(err)
      );
    }
  }


  gotoLogin() {

    localStorage.setItem("comingFromProduct", 'true');
    localStorage.setItem("bookingType", 'SP');
    localStorage.setItem('booking', JSON.stringify(this.booking));

    this.router.navigate(['/login']);

    //let prodName = this.ProductModel.name.replace(/\s/g, '').trim();

    //let url = `/productdetails/${prodName}`;
    //this.router.navigate(['/login'], {
    //  queryParams: { returnUrl: url }
    //});
  }


  gotoLoginCart() {

    if (this.booking.startDate == undefined || this.booking.startDate == '') {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Please select Delivery Date',
        showConfirmButton: false,
        timer: 1500
      });
    }
    else {

      var unloggedInCustomer = localStorage.getItem("unloggedInCustomer");

      if (unloggedInCustomer != undefined) {
        this.ngxUiLoaderService.start();
        this.dataService.getVerifyCustomer(Number(unloggedInCustomer)).subscribe(
          (data: responseCustomerVerify) => {
            if (data.response) {
              localStorage.setItem("unloggedInCustomer", `${unloggedInCustomer}`);
              localStorage.setItem("customerId", `${unloggedInCustomer}`);
              this.ngxUiLoaderService.stop();
              this.bookUnlogged();
            }
          },
          (err: any) => console.log(err)
        );
      }
      else {
        this.ngxUiLoaderService.start();
        this.dataService.getUnLoggedCustomer().subscribe(
          (data: responseCustomerId) => {
            localStorage.setItem("unloggedInCustomer", `${data.customerId}`);
            localStorage.setItem("customerId", `${data.customerId}`);
            this.booking.customerId = data.customerId;
            this.bookUnlogged();
          },
          (err: any) => console.log(err)
        );
      }
    }


  }

  bookUnlogged() {
    this.ngxUiLoaderService.start();
    this.franchiseId = Number(localStorage.getItem("Banner_franchiseId"));
    this.booking.franchiseId = this.franchiseId;
    this.dataService.StandardProductBookingUnloggedIn(this.booking).subscribe(
      (data: void) => {
        this.ngxUiLoaderService.stop();
        //this.router.navigate(["/"]);
        let message = "Successfully Booked";
        let action = "Ok";
        this._snackBar.open(message, action, {
          duration: 2000,
        });


        this.dataService.changeShowCartValue(true);
      },
      (err: any) => console.log(err)
    );
  }

}
