import { Component, OnInit } from '@angular/core';
import { SecurityService } from '../../security/security.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../../shared/data.service';
import { MatSnackBar } from '@angular/material';
import { responseMsg } from '../../shared/Interfaces/IInterfaces';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Title } from '@angular/platform-browser';

declare var $: any;

@Component({
  selector: 'app-change-delivery-date',
  templateUrl: './change-delivery-date.component.html',
  styleUrls: ['./change-delivery-date.component.css']
})
export class ChangeDeliveryDateComponent implements OnInit {


  constructor(private titleService: Title, private ngxUiLoaderService: NgxUiLoaderService,private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router, private dataService: DataService,
    private securityService: SecurityService) {
    dataService.getCurrentCart('changeDeliveryDate');
    this.orderId = parseInt(this.route.snapshot.params['id']);
    this.titleService.setTitle("TheRentalGuys.Ca - Change Delivery Date");
  }


  orderId;
  currentDeliveryDate;
  newDeliveryDate: string;


  totalDays = 0;


  ngOnInit() {


    $(document).ready(function () {

      if (window.matchMedia('(max-width: 380px)').matches) {
        $(".cc-form__input").css({ 'padding-left': '2px' });

      }

    });

    let tmpThis = this;

    this.currentDeliveryDate = localStorage.getItem("currentDeliveryDate");

    var disabledDays = [];
    var currentUnavaliable = [];

    $(document).ready(function () {

      InitilizeDisableDates();

      $("#datepicker_div").hide();


      $("#datepicker").datepicker({

        minDate: 1,
        beforeShowDay: function (date) {
          try {
            var today = new Date();

            var checkdate = new Date(date);
            var result = IsInBetween(checkdate, disabledDays);

            if (result) {
              var string = $.datepicker.formatDate('mm-dd-yy', date);
              var isDisabled = ($.inArray(string, disabledDays) != -1);

              if (isDisabled) {
                return [false, ".ui-datepicker td.ui-state-disabled > span", "Already Booked"];
              }

              return [false, ".ui-datepicker td.ui-state-disabled > span", "Already Booked"];
              //return [true, "dp-highlight-unselectable", "Sorry we are already booked for this day"];
            }

            try {
              var days = 15;
              var result15Days = new Date();
              result15Days.setDate(result15Days.getDate() + days);

              if (date.getDate() == 22) {
                var varbreak = 0;
              }

              if (date.getDate() > result15Days.getDate() || date.getMonth() > result15Days.getMonth() || date.getFullYear() > result15Days.getFullYear()) {
                return [false, ".ui-datepicker td.ui-state-disabled > span", "Already Booked"];
              }
            } catch (e) {

            }

            var day = date.getDay();
            var string = $.datepicker.formatDate('mm-dd-yy', date);

            var isDisabled = ($.inArray(string, disabledDays) != -1);

            var isAvaliable = true;

            if (isDisabled) {
              return [false, ".ui-datepicker td.ui-state-disabled > span", "Already Booked"];
              //return [!isDisabled, ".ui-datepicker td.ui-state-disabled > span", "Sorry we are already booked for this day"];
            }
            else {
              return [isAvaliable, "Avaliable", "This day is avaliable for booking"];
            }
          }
          catch (Err) {
            alert(Err.message);
          }
        }
      }).on("change", function () {

        var date = new Date($(this).val());
        $("#Selecteddatepicker").val($(this).val());

        tmpThis.newDeliveryDate = $(this).val();
      });

    });



    function InitilizeDisableDates() {

      var TotalOrderID = tmpThis.orderId;
      $.ajax({
        type: "GET",
        url: `${tmpThis.dataService.baseurl}/api/AvbChangeDeliveryDate/?OrderId=` + TotalOrderID,
        contentType: "application/json",
        dataType: "json",
        success: function (data) {
          disabledDays = [];
          disabledDays = data.bookingslist;
          tmpThis.totalDays = data.totalDays;

          $("#datepicker").datepicker('setDate', null);

          $('#AvaliableSerialsList').val(data.serialsFor);
          $('#datepicker').datepicker();
          var date = $("#datepicker").val();
          $("#datepicker_div").show();
        },
        error: function (data) {
        }

      });
    }


    function IsInBetween(date, disabledDays) {
      var Quantity = 1;
      Quantity = tmpThis.totalDays;


      if (Quantity >= 1) {
        var possibleStartdate = new Date(date);

        var possibleEnddate = new Date(date);

        possibleEnddate.setDate(possibleEnddate.getDate() + Number(Quantity - 1));


        for (var chk = 0; chk < disabledDays.length; chk++) {

          var c = disabledDays[chk].split("-");


          var check = new Date(c);
          {

            if (check.getDate() == possibleStartdate.getDate() && check.getMonth() == possibleStartdate.getMonth() && check.getFullYear() == possibleStartdate.getFullYear()) {
              return true;
            }
            else if (check.getDate() == possibleEnddate.getDate() && check.getMonth() == possibleEnddate.getMonth() && check.getFullYear() == possibleEnddate.getFullYear()) {
              var fullDate = new Date(possibleStartdate);


              var twoDigitMonth = (fullDate.getMonth() + 1) + "";
              if (twoDigitMonth.length == 1) {
                twoDigitMonth = "0" + twoDigitMonth;
              }
              var twoDigitDate = fullDate.getDate() + "";
              if (twoDigitDate.length == 1) {
                twoDigitDate = "0" + twoDigitDate;
              }
              var currentDate = twoDigitMonth + "-" + twoDigitDate + "-" + fullDate.getFullYear();

              currentUnavaliable.push(currentDate);
              return true;
            }
            else if (check > possibleStartdate && check < possibleEnddate) {

              var fullDate = new Date(possibleStartdate);

              var twoDigitMonth = (fullDate.getMonth() + 1) + "";
              if (twoDigitMonth.length == 1) {
                twoDigitMonth = "0" + twoDigitMonth;
              }
              var twoDigitDate = fullDate.getDate() + "";
              if (twoDigitDate.length == 1) {
                twoDigitDate = "0" + twoDigitDate;
              }
              var currentDate = twoDigitMonth + "-" + twoDigitDate + "-" + fullDate.getFullYear();

              currentUnavaliable.push(currentDate);

              return true;
            }
          }

        }
      }

      return false;
    }

  }


  updateDeliveryDate()
  {
    if (this.newDeliveryDate != null) {
      this.ngxUiLoaderService.start();
      this.dataService.updateDeliveryDate(this.orderId, this.newDeliveryDate)
        .subscribe(
          (data: responseMsg) => {
            this.ngxUiLoaderService.stop();
            let message = "Successfully Updated";
            let action = "Ok";
            this._snackBar.open(message, action, {
              duration: 2000,
            });

            this.router.navigate(['/ordersDetail/' + this.orderId]);
          },
          (err: any) => {
            this.ngxUiLoaderService.stop();
            console.log(err);
          },
          () => {
            console.log("Data Recived");
          }
        );
    }
    else
    {
      let message = "Please select date";
      let action = "Ok";
      this._snackBar.open(message, action, {
        duration: 2000,
      });
    }
  }

}
