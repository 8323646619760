import { Injectable, Output, EventEmitter, Inject, PLATFORM_ID } from '@angular/core';
import { ILoginResult, ILogin } from '../shared/Interfaces/ILogin';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { DataService } from '../shared/data.service';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { tap, map, catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})

@Injectable()
export class SecurityService {

  @Output() getLoggedIn: EventEmitter<any> = new EventEmitter();

  serurityObj: ILogin = new ILogin();
  authResult: ILoginResult = new ILoginResult();
  private loginStatus = new BehaviorSubject<boolean>(this.checkLoginStatus());

  constructor(@Inject(PLATFORM_ID) private _platformId: Object,private _snackBar: MatSnackBar, private dataService: DataService, private http: HttpClient) { }

  login(entity: ILogin): Observable<ILoginResult> {
    this.resetSecurityObject();
    var url = this.dataService.baseurl + this.dataService.accountUrl;
    return this.http.post<ILoginResult>(url, entity, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }).pipe(
      tap(
        resp => {

          if (resp.response == 'Success') {
            this.authResult.isAuthenticated = true;
            Object.assign(this.authResult, resp);
            localStorage.setItem("username", entity.Email);
            localStorage.setItem("password", entity.Password);
            localStorage.setItem("token", resp.token);

            try {
              localStorage.setItem("tokenExpiry", resp.expiration.toString());
            } catch (e) {
              console.log(e);
            }
            localStorage.setItem('customerId', resp.customer.customerId.toString());
            localStorage.setItem('customer', JSON.stringify(resp.customer));

            localStorage.setItem("currentPostalCode", resp.customer.currentFranchise);
            //localStorage.setItem("franchiseId", resp.customer.currentFranchiseId.toString());
            //localStorage.setItem('franchiseList', JSON.stringify(resp.franchiseList));

            this.getLoggedIn.emit();
          }
          else {
            let message = "Invalid Credentials";
            let action = "Ok";
            this._snackBar.open(message, action, {
              duration: 2000,
            });
          }
        }),
      catchError(
        () => this.errorHandler)
    );
  }

  loginUnloggedIn(entity: ILogin): Observable<ILoginResult> {
    this.resetSecurityObject();
    var url = this.dataService.baseurl + this.dataService.accountUnloggedInUrl;
    return this.http.post<ILoginResult>(url, entity, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }).pipe(
      tap(
        resp => {

          if (resp.response == 'Success') {
            this.authResult.isAuthenticated = true;
            Object.assign(this.authResult, resp);
            if (isPlatformBrowser(this._platformId)) {
              localStorage.setItem("username", entity.Email);
              localStorage.setItem("password", entity.Password);
              localStorage.setItem("token", resp.token);

              try {
                localStorage.setItem("tokenExpiry", resp.expiration.toString());
              } catch (e) {
                console.log(e);
              }
              localStorage.setItem('customerId', resp.customer.customerId.toString());
              localStorage.setItem('customer', JSON.stringify(resp.customer));

              localStorage.setItem("currentPostalCode", resp.customer.currentFranchise);
              //localStorage.setItem("franchiseId", resp.customer.currentFranchiseId.toString());
              //localStorage.setItem('franchiseList', JSON.stringify(resp.franchiseList));
            }
            this.getLoggedIn.emit();
          }
          else {
            let message = "Invalid Credentials";
            let action = "Ok";
            this._snackBar.open(message, action, {
              duration: 2000,
            });
          }
        }),
      catchError(
        () => this.errorHandler)
    );
  }


  verifyLogin(): Observable<ILoginResult> {

    let entity: ILogin = new ILogin();
    if (isPlatformBrowser(this._platformId)) {
      entity.Email = localStorage.getItem("username");
      entity.Password = localStorage.getItem("password");
    }

    //this.resetSecurityObject();
    var url = this.dataService.baseurl + this.dataService.accountUrl;
    return this.http.post<ILoginResult>(url, entity, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }).pipe(
      tap(
        resp => {
          if (resp.response == 'Success') {
            this.authResult.isAuthenticated = true;
            Object.assign(this.authResult, resp);
            localStorage.setItem("username", entity.Email);
            localStorage.setItem("password", entity.Password);
            localStorage.setItem("token", resp.token);

            try {
              localStorage.setItem("tokenExpiry", resp.expiration.toString());
            } catch (e) {
              console.log(e);
            }
            localStorage.setItem('customerId', resp.customer.customerId.toString());
            localStorage.setItem('customer', JSON.stringify(resp.customer));

            localStorage.setItem("currentPostalCode", resp.customer.currentFranchise);
            //localStorage.setItem("franchiseId", resp.customer.currentFranchiseId.toString());
            //localStorage.setItem('franchiseList', JSON.stringify(resp.franchiseList));

            this.getLoggedIn.emit();
          }
          else {
            try {
              //this.resetSecurityObject();
            } catch (e) {

            }
          }
        }),
      catchError(
        () => this.errorHandler)
    );
  }

  loginOtp(user: number, code: string): Observable<ILoginResult> {

    this.resetSecurityObject();
    var url = this.dataService.baseurl + `/api/OtpLogin/?user=${user}&code=${code}`;

    return this.http.get<ILoginResult>(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }).pipe(
      tap(
        resp => {

          if (resp.response == 'Success') {
            this.authResult.isAuthenticated = true;
            Object.assign(this.authResult, resp);
            localStorage.setItem("username", resp.customer.emailAddress);
            localStorage.setItem("password", resp.password);
            localStorage.setItem("token", resp.token);

            try {
              localStorage.setItem("tokenExpiry", resp.expiration.toString());
            } catch (e) {
              console.log(e);
            }
            localStorage.setItem('customerId', resp.customer.customerId.toString());
            localStorage.setItem('customer', JSON.stringify(resp.customer));
            localStorage.setItem("currentPostalCode", resp.customer.currentFranchise);
            this.getLoggedIn.emit();
          }
          else {
            let message = "Invalid Credentials";
            let action = "Ok";
            this._snackBar.open(message, action, {
              duration: 2000,
            });
          }
        }),
      catchError(
        () => this.errorHandler)
    );
  }

  errorHandler(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }

    let message = "Invalid Credentials";
    let action = "Ok";
    this._snackBar.open(message, action, {
      duration: 2000,
    });

    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }

  logout(): void {
    this.resetSecurityObject();
  }

  resetSecurityObject(): void {
    if (isPlatformBrowser(this._platformId)) {
      this.serurityObj.Email = null;
      this.authResult.isAuthenticated = false;
      localStorage.removeItem("username");
      localStorage.removeItem("password");
      localStorage.removeItem("customerId");
      //localStorage.removeItem("unloggedInCustomer");
      localStorage.removeItem("token");
      localStorage.removeItem("tokenExpiry");
      localStorage.removeItem("franchiseName");
      localStorage.removeItem("franchiseId");
      localStorage.removeItem("franchiseList");
    }
  }

  checkLoginStatus(): boolean {
    if (isPlatformBrowser(this._platformId)) {
      if (localStorage.getItem('token') === null || localStorage.getItem('token') === undefined) {
        return false;
      }
      else return true;
    }
    else return true;
    //const date = new Date(0);

    //let expiry = localStorage.getItem("tokenExpiry");

    //let tokenExpDate = new Date(expiry);

    //if (tokenExpDate.valueOf() > new Date().valueOf()) {
    //  return true;
    //}

    //console.log("NEW DATE " + new Date().valueOf());
    //console.log("Token DATE " + tokenExpDate.valueOf());
  }

  hasTokenExpied(): boolean {
    const date = new Date(0);

    let expiry = "";

    if (isPlatformBrowser(this._platformId)) {
      expiry = localStorage.getItem("tokenExpiry");
    }
    let tokenExpDate = new Date(expiry);

    console.log(tokenExpDate.valueOf());
    console.log(new Date().valueOf());
    if (tokenExpDate.valueOf() < new Date().valueOf()) {
      return true;
    }
    //console.log("NEW DATE " + new Date().valueOf());
    //console.log("Token DATE " + tokenExpDate.valueOf());
    return false;
  }

  getNewRefreshToken(): Observable<any> {

    let username = "";
    let password = "";
    if (isPlatformBrowser(this._platformId)) {
      username = localStorage.getItem('username');
      password = localStorage.getItem('password');
    }
    var url = this.dataService.baseurl + this.dataService.accountUrl;
    let entity: ILogin = new ILogin();

    entity.Email = username;
    entity.Password = password;

    return this.http.post<any>(url, entity).pipe(
      map(result => {
        if (result && result.token) {
          this.loginStatus.next(true);
          this.authResult.isAuthenticated = true;
          if (isPlatformBrowser(this._platformId)) {
            Object.assign(this.authResult, result);
            localStorage.setItem("username", entity.Email);
            localStorage.setItem("password", entity.Password);
            localStorage.setItem("token", result.token);

            try {
              localStorage.setItem("tokenExpiry", result.expiration.toString());
            } catch (e) {
              console.log(e);
            }
          }
          //localStorage.setItem("franchiseName", result.franchiseName);
          //localStorage.setItem("franchiseId", result.franchiseId.toString());
          //localStorage.setItem('franchiseList', JSON.stringify(result.franchiseList));
        }

        return <any>result;
      })
    );

  }

}
