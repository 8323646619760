import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialog-content-delete-dialog',
  templateUrl: './dialog-content-delete-dialog.component.html',
  styleUrls: ['./dialog-content-delete-dialog.component.css']
})
export class DialogContentDeleteDialogComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
