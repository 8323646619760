import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { SecurityService } from '../../security/security.service';
import { DataService } from '../../shared/data.service';
import { IDeliverySlots } from '../../shared/Interfaces/ILogin';
import { responseMsg, ICustomerProfile } from '../../shared/Interfaces/IInterfaces';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-change-delivery-time',
  templateUrl: './change-delivery-time.component.html',
  styleUrls: ['./change-delivery-time.component.css']
})
export class ChangeDeliveryTimeComponent implements OnInit {

  constructor(private titleService: Title, private ngxUiLoaderService: NgxUiLoaderService,private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router, private dataService: DataService,
    private securityService: SecurityService) {
    dataService.getCurrentCart('changeDeliveryTime');
    this.orderId = parseInt(this.route.snapshot.params['id']);
    this.titleService.setTitle("TheRentalGuys.Ca - Change Delivery Time");
  }

  orderId;

  avbSlots: IDeliverySlots[];

  newDeliverySlotId: number;
  deliveryCost: number=0;

  taxPerCentage:string;
  taxName: string;

  taxLabel:string = '';

  totalTax: number=0;
  totalCharge: number=0;

  slotSelected = false;

  customerId;
  customerProfile: ICustomerProfile = new ICustomerProfile();

  ngOnInit() {

    this.taxPerCentage = localStorage.getItem("taxPerCentage");
    this.taxName = localStorage.getItem("taxName");

    this.taxLabel = `${this.taxName} (${this.taxPerCentage}%)`
    this.ngxUiLoaderService.start();
    this.dataService.avbPriorityDelivery(this.orderId)
      .subscribe(
        (data: IDeliverySlots[]) => {
          this.avbSlots = data;
          this.ngxUiLoaderService.stop();
        },
        (err: any) => {
          this.ngxUiLoaderService.stop();
          console.log(err);
        },
        () => {
          console.log("Data Recived");
        }
    );

    this.customerId = Number(localStorage.getItem("customerId"));
    let franchsie = Number(localStorage.getItem("currentOrder_franchiseId"));

    this.dataService.currentBilling(this.customerId, franchsie)
      .subscribe(
        (data: ICustomerProfile) => {
          this.customerProfile = data;
        },
        (err: any) => {
          this.ngxUiLoaderService.stop();
          console.log(err);
        },
        () => {
          console.log("Data Recived");
        }
      );
  }

  checkDeliverySlot(id:number, deliveryCost:string)
  {
    this.newDeliverySlotId = id;
    this.deliveryCost = Number(deliveryCost);

    let taxP = Number(this.taxPerCentage);
    this.totalTax = this.deliveryCost * taxP / 100;
    this.totalCharge = this.totalTax + this.deliveryCost;

    this.slotSelected = true;
  }

  updateDeliveryTime() {
    if (this.newDeliverySlotId != null) {
      this.ngxUiLoaderService.start();
      this.dataService.updateDeliveryTime(this.orderId, this.newDeliverySlotId, this.totalCharge, this.deliveryCost)
        .subscribe(
          (data: responseMsg) => {
            this.ngxUiLoaderService.stop();
            let message = "Successfully Updated";
            let action = "Ok";
            this._snackBar.open(message, action, {
              duration: 2000,
            });

            this.router.navigate(['/ordersDetail/' + this.orderId]);
          },
          (err: any) => console.log(err),
          () => {
            console.log("Data Recived");
          }
        );
    }
    else {
      let message = "Please select date";
      let action = "Ok";
      this._snackBar.open(message, action, {
        duration: 2000,
      });
    }
  }
}
