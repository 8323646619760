import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { DeliveryAddress, ResponseClassWCode, ICustomer } from '../../shared/Interfaces/ILogin';
import { NgForm, Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { DataService } from '../../shared/data.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-add-new-delivery-address',
  templateUrl: './add-new-delivery-address.component.html',
  styleUrls: ['./add-new-delivery-address.component.css']
})
export class AddNewDeliveryAddressComponent implements OnInit {

  address: DeliveryAddress = new DeliveryAddress();

  form: FormGroup;

  driverName: string;

  driverControl = new FormControl('', [Validators.required]);

  customerId: number;
  franchsieId: number;

  PostalCodeDNE = false;

  response;

  loadScripts() {
    const dynamicScripts = [
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyAqZKz5nGJ1fA5viy9H2qfIuBo7tqEqRas&libraries=places&language=en',
      'https://www.googletagmanager.com/gtag/js?id=UA-115005957-1'
    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = true;
      node.defer = true;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }

  @ViewChild("placesRef", { static: true }) placesRef: GooglePlaceDirective;

  public handleAddressChange(address: Address) {
    //this.postalCode = address.(address, "postal_code");
    console.log(address);

    for (let component of address.address_components) {
      for (let type of component.types) {
        if (type == "postal_code") {
          this.address.postalCodeZip = component.long_name;
        }

        if (type == "locality") {
          this.address.city = component.long_name;
        }

        if (type == "administrative_area_level_1") {
          this.address.stateProvince = component.long_name;
        }

        if (type == "street_number") {
          this.address.addressLine1 = component.long_name;
        }

        if (type == "route") {
          this.address.addressLine1 = this.address.addressLine1 + " " + component.long_name;
        }

      }
    }
    //this.deliveryAddress = address.formatted_address;

    //this.updateAddress();
  }

  constructor(private route: ActivatedRoute, private router: Router, private _snackBar: MatSnackBar, public fb: FormBuilder, private dataService: DataService)
  {
    this.loadScripts();
    this.customerId = Number(localStorage.getItem("customerId"));
    this.franchsieId = parseInt(this.route.snapshot.params['id']);;
  }

  ngOnInit() {

    this.address.fullName = "";

    this.form = this.fb.group({
      driver: this.address
    });

    try {
      this.dataService.getCustomer(this.customerId)
        .subscribe(
          (data: ICustomer) => {
            this.address.fullName = data.firstName + " " + data.lastName;
            this.address.telephoneNumber = data.phone;
          },
          (err: any) => console.log(err),
          () => {
            console.log("Data Recived");
          }
        );
    } catch (e) {

    }
  }

  onSubmit(form: NgForm) {
    if (this.address.fullName == undefined || this.address.fullName == '') {
      let message = "Please enter fullname";
      let action = "Ok";
      this._snackBar.open(message, action, {
        duration: 20000,
      });
    }
    else if (this.address.addressLine1 == undefined || this.address.addressLine1 == '') {
      let message = "Please enter addressLine 1";
      let action = "Ok";
      this._snackBar.open(message, action, {
        duration: 20000,
      });
    }
    else if (this.address.city == undefined || this.address.city == '') {
      let message = "Please enter city";
      let action = "Ok";
      this._snackBar.open(message, action, {
        duration: 20000,
      });
    }
    else if (this.address.postalCodeZip == undefined || this.address.postalCodeZip.trim() == '') {
      let message = "Please enter postal code";
      let action = "Ok";
      this._snackBar.open(message, action, {
        duration: 20000,
      });
    }
    else if (this.address.stateProvince == undefined || this.address.stateProvince == '') {
      let message = "Please enter State/Province";
      let action = "Ok";
      this._snackBar.open(message, action, {
        duration: 20000,
      });
    }
    else {

      this.saveChanges()
    }
  }

  saveChanges(): void {

    this.address.customerId = this.customerId;
    this.address.franchiseId = this.franchsieId;
    this.address.isDefault = true;

    this.dataService.addCustomerDeliveryAddress(this.address).subscribe(
      (data: ResponseClassWCode) => {

        if (data.code == 200) {
          this.router.navigate(["/checkout"]);
          this.PostalCodeDNE = false;
        }
        else {
          this.PostalCodeDNE = true;

          this.response = data.result;

          let message = data.result;
          let action = "Ok";
          this._snackBar.open(message, action, {
            duration: 20000,
          });
        }
      },
      (err: any) => console.log(err)
    );
  }

}
