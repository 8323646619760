import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { ICompany, ICompanyData } from '../shared/Interfaces/ILogin';
import { DataService } from '../shared/data.service';
import { Title } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {

  constructor(@Inject(PLATFORM_ID) private _platformId: Object, private titleService: Title, private dataService: DataService) {
    this.titleService.setTitle("TheRentalGuys.Ca - Terms & Conditions");
  }

  company: ICompany;
  companyData: ICompanyData;

  ngOnInit() {
    if (isPlatformBrowser(this._platformId)) {
      this.getCompany();
    }
    //if (isPlatformBrowser(this._platformId)) {
    //  var retrievedObject = localStorage.getItem('company');
    //  if (retrievedObject != undefined && retrievedObject != null) {
    //    this.companyData = JSON.parse(retrievedObject);
    //    this.company = this.companyData.company;
    //  }
    //  else {
    //    this.getCompany();
    //  }
    //}
  }

  getCompany() {
    localStorage.removeItem('company');
    this.dataService.getCompanyData(true)
      .subscribe(
        (data: ICompanyData) => {
          //data.company.headerLogo = this.dataService.baseurlAdmin + '/theme/headerLogo/' + data.company.headerLogo;
          //data.company.headerLogoMobile = this.dataService.baseurlAdmin + '/theme/headerLogoMobile/' + data.company.headerLogoMobile;
          //data.company.footerLogo = this.dataService.baseurlAdmin + '/theme/footerLogo/' + data.company.footerLogo;
          localStorage.setItem('company', JSON.stringify(data));
          this.company = data.company;
        },
        (err: any) => console.log(err),
        () => {
          console.log("");
        }
      );
  }
}
