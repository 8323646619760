import { Component, OnInit, ViewChild } from '@angular/core';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { Router, ActivatedRoute } from '@angular/router';
import { SecurityService } from '../../security/security.service';
import { MatSnackBar } from '@angular/material';
import { DataService } from '../../shared/data.service';
import { IStandardProductCatagory, IResponse } from '../../shared/Interfaces/ILogin';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ICustomerProfile } from '../../shared/Interfaces/IInterfaces';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-relocation',
  templateUrl: './relocation.component.html',
  styleUrls: ['./relocation.component.css']
})
export class RelocationComponent implements OnInit {

  constructor(private titleService: Title, private ngxUiLoaderService: NgxUiLoaderService,private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router, private dataService: DataService,
    private securityService: SecurityService) {
    dataService.getCurrentCart('relocation');
    this.orderId = parseInt(this.route.snapshot.params['id']);
    this.titleService.setTitle("TheRentalGuys.Ca - Relocation");
    
  }

  @ViewChild("placesRef", { static: true }) placesRef: GooglePlaceDirective;

  todaydate: Date = new Date();

  postalCode;
  deliveryAddress: string;

  totalAmount = 0;
  totalTax = 0;
  total = 0;
  orderId;
  taxTag = "";
  relocationDate: Date;

  prod: IStandardProductCatagory;

  customerId;
  customerProfile: ICustomerProfile = new ICustomerProfile();

  public handleAddressChange(address: Address) {
    console.log(address);

    for (let component of address.address_components) {
      for (let type of component.types) {
        if (type == "postal_code") {
          this.postalCode = component.long_name;
        }
      }
    }
    this.deliveryAddress = address.formatted_address;
  }

  ngOnInit() {

    let hour = this.todaydate.getHours();

    if (hour >= 12)
    {
      this.todaydate.setDate(this.todaydate.getDate() + 1);
    }

    let tax=Number(localStorage.getItem("taxPerCentage"));
    let taxName =localStorage.getItem("taxName");

    this.taxTag = taxName + `(${tax}%)`

    this.dataService.relocationCostDetails(this.orderId)
      .subscribe(
        (data: IStandardProductCatagory) => {
          this.prod = data;

          this.totalAmount = Number(data.price);
          this.totalTax = this.totalAmount * tax / 100;

          this.total = this.totalAmount + this.totalTax;
        },
        (err: any) => console.log(err),
        () => {
          console.log("Data Recived");
        }
    );

    this.customerId = Number(localStorage.getItem("customerId"));
    let franchsie = Number(localStorage.getItem("currentOrder_franchiseId"));

    this.dataService.currentBilling(this.customerId, franchsie)
      .subscribe(
        (data: ICustomerProfile) => {
          this.customerProfile = data;
        },
        (err: any) => {
          this.ngxUiLoaderService.stop();
          console.log(err);
        },
        () => {
          console.log("Data Recived");
        }
      );
  }

  relocateProduct()
  {
    if (this.relocationDate != undefined && this.deliveryAddress != undefined && this.postalCode != undefined) {
      this.ngxUiLoaderService.start();
      this.dataService.relocationProduct(this.orderId, this.deliveryAddress, this.postalCode, this.relocationDate)
        .subscribe(
          (data: IResponse) => {
            if (data.result.includes("Successfull"))
            {
              let message = "Successfully Updated";
              let action = "Ok";
              this._snackBar.open(message, action, {
                duration: 2000,
              });

              let totalOrderId = localStorage.getItem("orderId");

              this.router.navigate(['/ordersDetail/' + totalOrderId]);
            }
            else {
              let message = data.result;
              let action = "Ok";
              this._snackBar.open(message, action, {
                duration: 2000,
              });
            }
            this.ngxUiLoaderService.stop();
          },
          (err: any) => {
            console.log(err);
            this.ngxUiLoaderService.stop();
          },
          () => {
            console.log("Data Recived");
          }
        );
    }
    else
    {
      let action = "Ok";
      this._snackBar.open("Please enter all fields", action, {
        duration: 2000,
      });
    }
  }
  
  
}
