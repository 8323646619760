import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder } from '@angular/forms';
import { DataService } from '../../shared/data.service';
import { SignaturePartComponent } from '../signature-part/signature-part.component';
import { SingleOrder } from '../../shared/Interfaces/IInterfaces';
import { ICheckout, ICompany, IFranchise, ITotalOrder } from '../../shared/Interfaces/ILogin';
import { NgxUiLoaderService } from 'ngx-ui-loader';

declare var $: any;

@Component({
  selector: 'app-sign-dialog',
  templateUrl: './sign-dialog.component.html',
  styleUrls: ['./sign-dialog.component.css']
})
export class SignDialogComponent implements OnInit {

  franchiseId;
  orderId;
  currentStep = 1;
  order: ITotalOrder;
  company: ICompany;
  checkout: ICheckout = new ICheckout();

  signTermRequested1 = false;
  signTermRequested2 = false;
  signTermRequested3 = false;
  signTermRequested4 = false;
  signTermRequested5 = false;
  signTermRequested6 = false;
  signTermRequested7 = false;
  signTermRequested8 = false;
  signTermRequested9 = false;
  signTermRequested10 = false;
  signTermRequested11 = false;
  signAerialRequested = false;
  signFull = false;
  agreementChecked = false;

  signatureImageTerms = '';
  signatureImageTermsInit = '';

  franchise: IFranchise = new IFranchise();

  limitedDamage = false;

  today;

  customerName;

  @ViewChild("signatureSlotDiv", { static: true }) signature: SignaturePartComponent;

  constructor(private ngxUiLoaderService: NgxUiLoaderService, private _snackBar: MatSnackBar, public fb: FormBuilder, private dataService: DataService,
    private dialogRef: MatDialogRef<SignDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
    this.franchiseId = data.franchiseId;
    this.orderId = data.orderId;
    this.checkout = data.checkout;
    this.order = this.checkout.data[0];

    try {
      if (this.order.isStandardRentalOrder == null) {
        this.order.isStandardRentalOrder = false;
      }
    } catch (e) {

    }
    this.checkout.franchise.provinceName
    this.company = data.company;
    this.limitedDamage = data.limitedDamage;
    this.order.acceptLimitedWavier = this.limitedDamage;
    this.today = new Date();

    //this.customerName = this.signature.fullname; //this.checkout.customer.firstName +" "+ this.checkout.customer.lastName
  }

  agreementCheck() {
    this.agreementChecked = true;
    this.signAerialRequested = true;
  }

  signTerm(id: number) {
    this.signatureImageTerms = this.dataService.baseurl + "/Signature/" + localStorage.getItem("SignedUrl");
    this.signatureImageTermsInit = this.dataService.baseurl + "/Initials/" + localStorage.getItem("SignedInitUrl");

    if (id == 1) {
      this.signTermRequested1 = true;
    }
    if (id == 2) {
      this.signTermRequested2 = true;
    }
    if (id == 3) {
      this.signTermRequested3 = true;
    }
    if (id == 4) {
      this.signTermRequested4 = true;
    }
    if (id == 5) {
      this.signTermRequested5 = true;
    }
    if (id == 6) {
      this.signTermRequested6 = true;
    }
    if (id == 7) {
      this.signTermRequested7 = true;
    }
    if (id == 8) {
      this.signTermRequested8 = true;
    }
    if (id == 9) {
      this.signTermRequested9 = true;
    }
    if (id == 10) {
      this.signTermRequested10 = true;
    }
    if (id == 11) {
      this.signTermRequested11 = true;
    }
    if (id == 12) {
      this.signFull = true;
    }

    if (!this.checkout.franchise.hasAerialContract) {
      this.signAerialRequested = true;
    }
  }

  signAerial() {
    //this.signatureImageTerms = this.dataService.baseurl + "/Signature/" + localStorage.getItem("SignedUrl");
    this.signAerialRequested = true;
  }

  nextStep(id) {

    let result = true;

    if (id == 2) {
      this.ngxUiLoaderService.start();
      result = this.signature.sign();
      this.customerName = this.signature.fullname;
      this.ngxUiLoaderService.stop();
    }

    if (id == 0) {
      let flag = false;

      if (!this.agreementChecked) {
        flag = true;
        $(document).ready(function () {
          $('#AgreementCheck').focus();
          $("#agreementDiv").css("border-style","solid");
          $("#agreementDiv").css("border-color", "orangered");

        });
        let message = "Please click the checkbox";
        let action = "Ok";
        this._snackBar.open(message, action, {
          duration: 20000,
        });
      }
      else {
        if (!this.signTermRequested1) {
          flag = true;
          $(document).ready(function () {
            $('#signTermRequested1').focus();
          });
        }

        if (!this.signTermRequested2) {
          flag = true;
          $(document).ready(function () {
            $('#signTermRequested2').focus();
          });
        }

        if (!this.signTermRequested3) {
          flag = true;
          $(document).ready(function () {
            $('#signTermRequested3').focus();
          });
        }

        if (!this.signTermRequested4) {
          flag = true;
          $(document).ready(function () {
            $('#signTermRequested4').focus();
          });
        }

        if (!this.signTermRequested5) {
          flag = true;
          $(document).ready(function () {
            $('#signTermRequested5').focus();
          });
        }

        //if (!this.signTermRequested6) {
        //  flag = true;
        //  $(document).ready(function () {
        //    $('#signTermRequested6').focus();
        //  });
        //}

        //if (!this.signTermRequested7) {
        //  flag = true;
        //  $(document).ready(function () {
        //    $('#signTermRequested7').focus();
        //  });
        //}

        if (!this.signTermRequested6 && this.limitedDamage) {
          flag = true;
          $(document).ready(function () {
            $('#signTermRequested6').focus();
          });
        }

        if (!this.signTermRequested7 && !this.limitedDamage) {
          flag = true;
          $(document).ready(function () {
            $('#signTermRequested7').focus();
          });
        }

        if (!this.signTermRequested8) {
          flag = true;
          $(document).ready(function () {
            $('#signTermRequested8').focus();
          });
        }

        if (!this.signTermRequested9) {
          flag = true;
          $(document).ready(function () {
            $('#signTermRequested9').focus();
          });
        }

        if (!this.signFull) {
          flag = true;
          $(document).ready(function () {
            $('#signTermRequested12').focus();
          });
        }

        if (flag) {
          let message = "Please sign all initials.";
          let action = "Ok";
          this._snackBar.open(message, action, {
            duration: 20000,
          });
        }
        else {
          this.dialogRef.close(true);
        }
      }
    }
    else if (result) {

      if (id == 1) {
        this.signTermRequested1 = false;
        this.signTermRequested2 = false;
        this.signTermRequested3 = false;
        this.signTermRequested4 = false;
        this.signTermRequested5 = false;
        this.signTermRequested6 = false;
        this.signTermRequested7 = false;
        this.signTermRequested8 = false;
        this.signTermRequested9 = false;
        this.signTermRequested10 = false;
        this.signTermRequested11 = false;
      }

      this.currentStep = id;
    }
  }

  cancel() {
    this.currentStep = 1;
    this.dialogRef.close(false);
  }

  //getOrder()
  //{
  //  this.dataService.getSingleOrder(this.orderId)
  //    .subscribe(
  //      (data: SingleOrder) => {
  //        data.totalOrder.signatureImageUrl = this.dataService.baseurl + "/Signature/" + data.totalOrder.signatureImageUrl;
  //        this.order = data;          
  //      },
  //      (err: any) => {
  //        console.log(err);
  //      },
  //      () => {
  //        console.log("Data Recived");
  //      }
  //    );
  //}

  ngOnInit() {
    //$(document).ready(function () {
    //  var height = $("#Div1stPart").innerHeight();
    //  console.log(`height of 1st div ${height}`)
    //});
  }

}
