import { Component, OnInit, Inject } from '@angular/core';
import { DeliveryAddress, ResponseClass } from '../../../shared/Interfaces/ILogin';
import { MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder } from '@angular/forms';
import { DataService } from '../../../shared/data.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-change-delivery-address-order',
  templateUrl: './change-delivery-address-order.component.html',
  styleUrls: ['./change-delivery-address-order.component.css']
})
export class ChangeDeliveryAddressOrderComponent implements OnInit {

  allAddresses: DeliveryAddress[] = [];

  customerId: number;
  franchiseId: number;
  orderId;
  currentAddress: number;

  constructor(private titleService: Title, private _snackBar: MatSnackBar, public fb: FormBuilder, private dataService: DataService,
    private dialogRef: MatDialogRef<ChangeDeliveryAddressOrderComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
    this.customerId = data.customerId;
    this.franchiseId = data.franchiseId;
    this.currentAddress = data.addressId;
    this.orderId = data.orderId;
    this.titleService.setTitle("TheRentalGuys.Ca - Change Delivery Address");
  }

  ngOnInit() {
    this.getDeliveryAddress();
  }

  getDeliveryAddress() {
    this.dataService.getCustomerDeliveryAddressList(this.customerId, this.franchiseId)
      .subscribe(
        (data: DeliveryAddress[]) => {
          this.allAddresses = data;
        },
        (err: any) => console.log(err),
        () => {
          console.log("units Data Recived");
        }
      );
  }

  saveChanges(address: DeliveryAddress): void {
    this.updateOrderDeliveryAddress(address);
  }

  updateOrderDeliveryAddress(address: DeliveryAddress) {

    this.dataService.selectOrderDeliveryAddress(this.orderId, address.addressId)
      .subscribe(
        (data: ResponseClass) => {
          this.dialogRef.close();
        },
        (err: any) => console.log(err),
        () => {
          console.log("units Data Recived");
        }
      );
  }

}
