import { Component, ElementRef, ViewChild, Inject, PLATFORM_ID } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../shared/data.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { IFranchsiePosalCode, ICatagory, ICompany, ICompanyData, ICatagoryWithProduct, RentalProductsCatagory, IBannerData } from '../shared/Interfaces/ILogin';
import { PostalcodedialogComponent } from './postalcodedialog/postalcodedialog.component';
import { PostalcodeNFDialogComponent } from './postalcode-nfdialog/postalcode-nfdialog.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Location, isPlatformBrowser } from '@angular/common';
import { parse } from 'url';
import { Title, Meta } from '@angular/platform-browser';
import { SecurityService } from '../security/security.service';
import { FormGroup, FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { SeoService } from '@miaguila/common';
import Swal from 'sweetalert2';
import { StorageDialogueComponent } from '../storage-dialogue/storage-dialogue.component';

declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent {

  onImgError(event, img: string) {
    event.target.src = this.dataService.baseCatagory + img;
  }

  ngAfterViewInit() {
  }

  productsRented = 19308;
  citiesServed = 140;
  trucksOnRoad = 14;
  customersServed = 9719;
  BackgroundBookyourrental = "../../assets/images/Bookyourrentalonline.PNG";
  searchbtn = "../../assets/images/searchbutton.PNG";
  wearesocial = "../../assets/images/wearesocial.PNG";
  mobileaboutusimg = "../../assets/images/aboutusimg.png";
  Bookyourrentalonline2 = "../../assets/images/Bookyourrentalonline2.PNG";
  step1counter = "../../assets/images/step1.PNG";
  step2counter = "../../assets/images/step2.PNG";
  step3counter = "../../assets/images/step3.PNG";
  step4counter = "../../assets/images/step4.PNG";
  landscapeBanner = "../../assets/images/lsgban.png";
  equipmentBanner = "../../assets/images/eqBanner.jpg";




  constructor(private seoService: SeoService, @Inject(PLATFORM_ID) private _platformId: Object, private securityService: SecurityService, private meta: Meta, private titleService: Title, private route: ActivatedRoute, private ngxUiLoaderService: NgxUiLoaderService, private router: Router,
    private dataService: DataService,
    public dialog: MatDialog, private locationReload: Location) {

    if (isPlatformBrowser(this._platformId)) {
      dataService.getCurrentCart('home');
      try {
        let tmpThis = this;

        if (isPlatformBrowser(this._platformId)) {
          try {
            $(document).ready(function () {
              //$('link[rel="canonical"]').remove()//.attr('href', 'NEW_HREF_GOES_HERE');

              //tmpThis.seoService.generateCanonicalURL();
            });
          } catch (e) { }
        }

      } catch (e) {

      }
    }
  }

  getStaticFullImage(img: string) {
    return `../assets/serverImages/staticCatImages/${img}`;
  }

  getFullImage(img: string) {
    return `../../assets/theme/CatagoryImages/${img}`;
  }

  location;

  youtubeLink: string = '';

  postalcodeResult: IFranchsiePosalCode;

  staticShowCatagories = true;

  franchiseShowCatagories = false;
  bannerText: string;

  selectCatagory(item: ICatagory): void {
    localStorage.setItem("catagoryId", item.catagoryId.toString());
    localStorage.setItem("franchiseId", this.postalcodeResult.franchiseId.toString());

    let catName = item.catagoryName.replace(/\s/g, '').trim();

    this.router.navigate(["../products", this.postalcodeResult.franchiseName.toLowerCase(), catName.toLowerCase()]);
  }

  postalCodeUpdated = false;

  searchPostalCode(): void {
    $('html, body').animate({
      scrollTop: 0
    }, 0);
    if (this.location != undefined && this.location != "" && this.location.toString().trim() != "") {

      try {
        this.location = this.location.replace(" ", "");
      } catch (e) {

      }

      this.postalCodeUpdated = false;

      this.ngxUiLoaderService.start();
      if (this.location.length < 6) {
        alert("Please enter 6 digit postal code.");
      } else {
        this.dataService.getAllPostalCode(this.location)
          .subscribe(
            (data: IFranchsiePosalCode) => {
              this.ngxUiLoaderService.stop();

              if (data == null) {
                this.postalCodeUpdated = false;

                if (isPlatformBrowser(this._platformId)) {
                  this.openNFDialog();
                }
              }
              else {
                this.postalCodeUpdated = true;
                this.postalcodeResult = data;
                localStorage.setItem("currentPostalCode", this.location);
                localStorage.setItem("currentDeliveryCity", data.title);
                localStorage.setItem("currentDeliveryCharge", data.deliveryCharge.toString());
                localStorage.setItem("franchiseId", data.franchiseId.toString());
                localStorage.setItem("Banner_franchiseId", data.franchiseId.toString());
                if (data.deliveryCharge == 0) {
                  this.bannerText = "Free Delivery in " + data.title;
                }
                else {
                  //this.bannerText = "$" + data.deliveryCharge + " Delivery in " + data.title;
                  this.bannerText = "EQUIPMENT RENTAL IN " + data.title;
                }
                this.franchiseShowCatagories = true;
                this.staticShowCatagories = false;

                let comingFromCalgary = localStorage.getItem("comingFromFranchise");

                if (comingFromCalgary == "Yes") {
                  localStorage.removeItem("comingFromFranchise");
                } else {
                  this.locationReload.replaceState("/home/" + data.franchiseName.toLowerCase());
                }
                //if (isPlatformBrowser(this._platformId)) {
                //  $('html, body').animate({
                //    scrollTop: 450
                //  }, 1000);
                //}

                try {
                  let term = this.searchForm.controls["searchProduct"].value;
                  if (term != '') {
                    this.searchProduct();
                  }
                } catch (e) {

                }

              }
            },
            (err: any) => {
              this.openNFDialog();
              console.log(err);
            },
            () => {
              this.ngxUiLoaderService.stop();
              console.log("postalcode Data Recived");
            }

          );
      }
    }
  }

  @ViewChild('videoPlayer', { static: true }) videoplayer: ElementRef;

  companyData: ICompanyData = new ICompanyData();
  company: ICompany;
  staticCatagories: ICatagory[];

  //staticProducts: RentalProductsCatagory[];
  productImageUrl;

  videoUrl: string = '';
  contactUsBackgroundUrl: string = '';
  headerLogo: string = '';
  headerLogoMobile: string = '';
  footerLogo: string = '';
  homePageTopBackgroundUrl: string = '';
  mobileBanner: string = '';
  loadContactUs = false;

  searchTerm: string = "";

  searchForm = new FormGroup({
    searchProduct: new FormControl('')
  });

  filterProducts;

  searchProduct() {
    if (this.location != '' && this.location != undefined && this.postalcodeResult == undefined) {
      this.searchPostalCode();
    }
    else {
      let term = this.searchForm.controls["searchProduct"].value;
      if (term != '') {
        if (this.postalcodeResult != undefined) {
          this.filterProducts = [];
          if (this.searchTerm == undefined) {
            this.searchTerm = '';
          }

          if (term == '') {
            //this.filterProducts = this.allProducts.standardList;
          } else {
            this.filterProducts = this.postalcodeResult.franchiseProducts.filter(option => (option.name != undefined && option.name.toLowerCase().includes(term)) || (option.shortName != undefined && option.shortName.toLowerCase().includes(term)));
          }
        }
        else {
          this.searchForm.controls["searchProduct"].setValue('');
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'Please enter postal code',
            showConfirmButton: false,
            timer: 1500
          });
        }
      }
      else {
        this.filterProducts = [];
      }
    }
  }

  getBannerData() {
    this.dataService.getBannerData()
      .subscribe(
        (data: IBannerData) => {
          this.productsRented = data.productsRented;
          this.citiesServed = data.citiesServed;
          this.trucksOnRoad = data.trucksOnRoad;
          this.customersServed = data.totalCustomersServed;
          console.log("Refreshing Banner");
        },
        (err: any) => console.log(err),
        () => {
          console.log("");
        }
      );
  }

  intervalId;

  ngOnDestroy() {
    console.log("Terminating Banner");
    try {
      clearInterval(this.intervalId);
    } catch (e) {

    }
  }

  ngOnInit() {
    if (isPlatformBrowser(this._platformId)) {
      try {
        this.intervalId = setInterval(() => {
          this.getBannerData();
        }, 10000);
      } catch (e) {

      }
    }

    var RefreshRequired = localStorage.getItem("RefreshRequired");

    if (RefreshRequired == "Yes") {
      localStorage.removeItem("RefreshRequired");
      location.reload();
    }

    this.searchForm.valueChanges.pipe(
      debounceTime(500)
    ).subscribe((value) => {
      if (isPlatformBrowser(this._platformId)) {
        this.searchProduct();
      }
    });

    setTimeout(() => {
      this.loadContactUs = true;
    }, 5000);

    try {
      let Email = localStorage.getItem("username");
      let Password = localStorage.getItem("password");

      if (Email != undefined && Password != undefined) {
        if (this.securityService.checkLoginStatus()) {
          this.securityService.verifyLogin()
            .subscribe(
              resp => {
                if (!resp.isAuthenticated) {
                  this.securityService.resetSecurityObject();
                  window.location.reload();
                }
              },
              (err: any) => {
              }
            )
        }
      }
    } catch (e) {

    }

    try {
      if (localStorage.getItem("isOTPLoggedIn") == "Yes") {
        localStorage.removeItem("isOTPLoggedIn");
        this.securityService.logout();
        this.securityService.resetSecurityObject();
        window.location.reload();
      }
    } catch (e) {

    }

    if (isPlatformBrowser(this._platformId)) {
      try {
        setTimeout(() => {
          $(document).ready(function () {
            $('#s01').css("background-image", `url(../../assets/theme/homeBannerBG/homeBannerBG.png)`)
          });
        }, 100);
      } catch (e) {

      }
    }


    var retrievedObject = '';
    if (isPlatformBrowser(this._platformId)) {
      retrievedObject = localStorage.getItem('company');
    }
    if (retrievedObject != undefined && retrievedObject != null && retrievedObject != '') {
      this.companyData = JSON.parse(retrievedObject);
      this.company = this.companyData.company;

      if (this.company.hasVideo) {
        setTimeout(() => {
          this.videoplayer.nativeElement.src = '../../assets/theme/homeVideo/vid1.mp4';//this.company.videoUrl;//this.dataService.baseurlAdmin + '/theme/homeVideo/' + this.company.videoUrl; //'../../assets/images/HomeProducts/vid1.mp4';
          this.videoplayer.nativeElement.play();
        }, 5000);
      }
      let tempThis = this;

      //this.titleService.setTitle("Test title");
      try {
        this.titleService.setTitle(this.company.pageTitle);
        this.meta.updateTag({ name: 'keywords', content: this.company.seoKeywords });
        this.meta.updateTag({ name: 'description', content: this.company.seoDescription });

      } catch (e) {

      }
      this.productImageUrl = "";
      this.productImageUrl = this.company.adminApiURL + "/productImage/";
      if (isPlatformBrowser(this._platformId)) {
        this.headerLogo = localStorage.getItem('headerLogo');
        this.headerLogoMobile = localStorage.getItem('headerLogoMobile');
        this.footerLogo = localStorage.getItem('footerLogo');
        this.contactUsBackgroundUrl = localStorage.getItem('contactUsBackgroundUrl');
        this.videoUrl = localStorage.getItem('videoUrl');
        this.homePageTopBackgroundUrl = localStorage.getItem('homePageTopBackgroundUrl');
        this.mobileBanner = localStorage.getItem('mobileBanner');
      }

      this.staticCatagories = this.companyData.catagories;
    }
    else {
      console.log("Company Data requested");
      this.getCompany();
    }



    try {
      let CurrentFranchise = "";

      try {
        CurrentFranchise = (this.route.snapshot.params['id']).toString();
      } catch (e) {

      }

      let comingFromCalgary = localStorage.getItem("comingFromFranchise");

      if (comingFromCalgary == "Yes") {
        let code = localStorage.getItem("currentPostalCode");

        if (code != undefined) {
          this.location = code;
          setTimeout(() => {
            this.searchPostalCode();
          }, 500);
        }
      } else {
        if (CurrentFranchise.toLowerCase() == 'calgary') {
          this.location = 'T2P1J9';
          setTimeout(() => {
            this.searchPostalCode();
          }, 500);
        }
        else if (CurrentFranchise.toLowerCase() == 'edmonton') {
          this.location = 'T5J0J4';
          setTimeout(() => {
            this.searchPostalCode();
          }, 500);
        }
        else if (CurrentFranchise.toLowerCase() == 'vancouver') {
          this.location = 'V6Z2N2';
          setTimeout(() => {
            this.searchPostalCode();
          }, 500);
        }
        else if (CurrentFranchise.toLowerCase() == 'guelph') {
          this.location = 'N1H1N4';
          setTimeout(() => {
            this.searchPostalCode();
          }, 500);
        }
        else if (CurrentFranchise.toLowerCase() == 'gtawest') {
          this.location = 'M3H5J8';
          setTimeout(() => {
            this.searchPostalCode();
          }, 500);
        }
        else if (CurrentFranchise.toLowerCase() == 'gtaeast') {
          this.location = 'M1B4Y7';
          setTimeout(() => {
            this.searchPostalCode();
          }, 500);
        }
        else {
          let code = localStorage.getItem("currentPostalCode");

          if (code != undefined && code != null) {
            this.location = code;
            setTimeout(() => {
              this.searchPostalCode();
            }, 500);
          }
          else {
            //try {
            //  if (localStorage.getItem('continueShopping') == 'true') {
            //    this.location = localStorage.getItem("comingFromPostalCode");
            //    setTimeout(() => {
            //      this.searchPostalCode();
            //    }, 500);
            //  }
            //} catch (e) {

            //}
          }
        }
      }
    } catch (e) {
      try {
        let code = localStorage.getItem("currentPostalCode");
        if (code != undefined) {
          this.location = code;
          setTimeout(() => {
            this.searchPostalCode();
          }, 500);
        }
      } catch (e) {
      }
    }



    var mouse_is_inside = false;

    var showing = false;
    if (isPlatformBrowser(this._platformId)) {
      setTimeout(() => {
        $(document).ready(function () {

          $('#searchcategory').on('click', function () {

            if (showing) { $(".global-search__search-results").hide(); }
            else {
              $(".global-search__search-results").show();
            }
            showing = !showing;
          });

          $('.global-search__search-results').hover(function () {
            mouse_is_inside = true;
          }, function () {
            mouse_is_inside = false;
          });

          $("body").mouseup(function () {
            if (!mouse_is_inside) $('.global-search__search-results').hide();
          });
        });
      }, 5000);
    }
    this.dataService.changeShowCartValue(false);
  }

  productLength;

  getCompany() {
    if (isPlatformBrowser(this._platformId)) {
      localStorage.removeItem('company');
    }
    this.dataService.getCompanyData(false)
      .subscribe(
        (data: ICompanyData) => {
          try {

            this.company = data.company;
            this.mobileBanner = data.mobileBanner;
            console.log("ran till here");
            this.productImageUrl = "";
            this.productImageUrl = data.company.adminApiURL + "/productImage/";
            this.companyData = data;



            this.company = data.company;
            this.staticCatagories = data.catagories;



            try {
              this.titleService.setTitle(this.company.pageTitle);
              this.meta.updateTag({ name: 'keywords', content: this.company.seoKeywords });
              this.meta.updateTag({ name: 'description', content: this.company.seoDescription });

            } catch (e) {

            }

            this.productLength = data.products.length;

            //$(document).ready(function () {
            //  $('#s01').css("background-image", `url('${data.homePageTopBackgroundUrl}')`)
            //});
            if (isPlatformBrowser(this._platformId)) {
              localStorage.setItem('company', JSON.stringify(data));
              localStorage.setItem('headerLogo', data.headerLogo);
              localStorage.setItem('headerLogoMobile', data.headerLogoMobile);
              localStorage.setItem('footerLogo', data.footerLogo);
              localStorage.setItem('contactUsBackgroundUrl', data.contactUsBackgroundUrl);
              localStorage.setItem('videoUrl', data.videoUrl);
              localStorage.setItem('homePageTopBackgroundUrl', data.homePageTopBackgroundUrl);
              localStorage.setItem('mobileBanner', data.mobileBanner);
            }


            if (this.company.hasVideo) {
              setTimeout(() => {
                this.videoplayer.nativeElement.src = '../../assets/theme/homeVideo/vid1.mp4';//this.dataService.baseurlAdmin + '/theme/homeVideo/' + this.company.videoUrl; //'../../assets/images/HomeProducts/vid1.mp4';
                try {
                  this.videoplayer.nativeElement.play();
                } catch (e) {

                }

              }, 10000);
            }
          } catch (e) {
            console.log(e);
          }

        },
        (err: any) => console.log(err),
        () => {
          console.log("");
        }
      );
  }

  showDialog(): void {
    this.openNewDialog();
  }

  openNewDialog(): void {
    const dialogRef = this.dialog.open(PostalcodedialogComponent, {
      height: '350px',
      width: '325px',
      disableClose: false
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let result_location: string = result.location;
        if (result_location != undefined) {
          this.location = result_location;
          this.searchPostalCode();
        }
      }
    });
  }

  openNFDialog(): void {
    const dialogRef = this.dialog.open(PostalcodeNFDialogComponent, {
      //height: '350px',
      width: '510px',
      disableClose: false
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }


  openContainerDialog(): void {
    const dialogRef = this.dialog.open(StorageDialogueComponent, {
      //height: '350px',
      width: '510px',
      disableClose: false
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  selectProduct(item: RentalProductsCatagory): void {

    let prodName = '';
    try {
      prodName = item.shortName.replace(/\s/g, '').trim();
    } catch (e) {

    }

    if (item.isStandardRental) {
      localStorage.setItem("productId", item.productId.toString());
      this.router.navigate(["/standardRental", this.postalcodeResult.franchiseName, prodName]);
    }
    //else if (item.factorType == 'Week') {
    //  localStorage.setItem("productIdWeekly", item.productId.toString());
    //  this.router.navigate(["/fourWeekProduct", prodName, item.productId]);
    //}
    else {
      localStorage.setItem("productId", item.productId.toString());
      this.router.navigate(["/productdetails", this.postalcodeResult.franchiseName, prodName]);
    }
  }

}
