import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Renderer2 } from '@angular/core';
import { SeoServiceModule } from '@miaguila/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
//import { BrowserModule } from '@angular/platform-browser';
//import { NgModule, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
//import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { AppfooterComponent } from './appfooter/appfooter.component';
import { DataService } from './shared/data.service';
import { HelperService } from './shared/Helper.service';
import { ProductsComponent } from './products/products.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { AboutComponent } from './about/about.component';
import { CancellationPolicyComponent } from './cancellation-policy/cancellation-policy.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { MyDashboardComponent } from './my-dashboard/my-dashboard.component';
import { ProfileCompleteComponent } from './profile-complete/profile-complete.component';
import { CartComponent } from './cart/cart.component';
import { EmptyCartComponent } from './empty-cart/empty-cart.component';
import { TermsComponent } from './terms/terms.component';
import { OperationsManualComponent } from './operations-manual/operations-manual.component';
import {
  MatAutocompleteModule, MatInputModule, MatButtonModule, MatListModule, MatButtonToggleModule, MatCardModule, MatChipsModule, MatCheckboxModule, MatDatepickerModule, MatStepperModule, MatDialogModule, MatGridListModule, MatIconModule, MatExpansionModule,
  MatProgressBarModule, MatNativeDateModule, MatSliderModule, MatRippleModule, MatRadioModule, MatMenuModule, MatPaginatorModule, MatSidenavModule, MatProgressSpinnerModule, MatSelectModule, MatTooltipModule, MatSlideToggleModule, MatSortModule, MatSnackBarModule, MatTabsModule, MatTableModule, MatToolbarModule
} from '@angular/material';
import { PostalcodedialogComponent } from './home/postalcodedialog/postalcodedialog.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { UserService } from './shared/user.service';
import { AuthGuard } from './security/auth.guard';
import { SecurityService } from './security/security.service';
import { BannerComponent } from './banner/banner.component';
import { DeliveryPolicyComponent } from './delivery-policy/delivery-policy.component';
import { CustomerCartComponent } from './customer-cart/customer-cart.component';
import { StandardProductComponent } from './standard-product/standard-product.component';
import { KitProductComponent } from './kit-product/kit-product.component';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { SignaturePadModule } from 'angular2-signaturepad';
//import { AngularFireModule } from '@angular/fire';
//import { AngularFireMessagingModule } from '@angular/fire/messaging';
//import { PushNotificationService } from './shared/push-notification.service';

//import * as firebase from 'firebase';
import { SignaturecompComponent } from './signaturecomp/signaturecomp.component';
import { OrdersComponent } from './orders/orders.component';
import { QoutationsComponent } from './qoutations/qoutations.component';
import { OrdersDetailsComponent } from './orders-details/orders-details.component';
import { CommonModule } from '@angular/common';
import { ExtendProductComponent } from './orders-details/extend-product/extend-product.component';
import { ChangeDeliveryDateComponent } from './orders-details/change-delivery-date/change-delivery-date.component';
import { ChangeDeliveryInfoComponent } from './orders-details/change-delivery-info/change-delivery-info.component';
import { ChangeDeliveryTimeComponent } from './orders-details/change-delivery-time/change-delivery-time.component';
import { AddProductComponent } from './orders-details/add-product/add-product.component';
import { RelocationComponent } from './orders-details/relocation/relocation.component';
//import { CardModule } from 'ngx-card/ngx-card';
import { NgPaymentCardModule } from 'ng-payment-card';
import { BillingProfileComponent } from './my-dashboard/billing-profile/billing-profile.component';
import { PostalCodeFranchiseDialogComponent } from './qoutations/postal-code-franchise-dialog/postal-code-franchise-dialog.component';
import { QouteDetailsComponent } from './qoutations/qoute-details/qoute-details.component';
import { AddProductToQouteComponent } from './qoutations/add-product-to-qoute/add-product-to-qoute.component';
import { PostalcodeNFDialogComponent } from './home/postalcode-nfdialog/postalcode-nfdialog.component';
import { HttpInterceptorModule } from './security/http-interceptor.module';
import { OrderConfirmationComponent } from './checkout/order-confirmation/order-confirmation.component';
import { NgxUiLoaderModule, NgxUiLoaderHttpModule, NgxUiLoaderConfig, POSITION, SPINNER, PB_DIRECTION } from 'ngx-ui-loader';
import { CookieLawModule } from 'angular2-cookie-law';
import { FourWeeklyProductComponent } from './product-details/four-weekly-product/four-weekly-product.component';
import { TestcardComponent } from './my-dashboard/testcard/testcard.component';
import { AdwordComponent } from './adword/adword.component';
import { InvoicesignComponent } from './invoicesign/invoicesign.component';
import { SignDialogComponent } from './checkout/sign-dialog/sign-dialog.component';
import { SignaturePartComponent } from './checkout/signature-part/signature-part.component';
import { StandardRentalComponent } from './product-details/standard-rental/standard-rental.component';
import { WeelyRentalComponent } from './product-details/weely-rental/weely-rental.component';
import { CompleteStandardRentalComponent } from './orders-details/complete-standard-rental/complete-standard-rental.component';
import { AutoRenewalsComponent } from './auto-renewals/auto-renewals.component';
import { NoSanitizePipe } from './shared/CustomPipe/NoSanitizePipe';
import { SelectDeliveryAddressDialogComponent } from './checkout/select-delivery-address-dialog/select-delivery-address-dialog.component';
import { DeliveryAddressListComponent } from './my-dashboard/delivery-address-list/delivery-address-list.component';
import { AddDeliveryAddressComponent } from './my-dashboard/delivery-address-list/add-delivery-address/add-delivery-address.component';
import { EditDeliveryAddressComponent } from './my-dashboard/delivery-address-list/edit-delivery-address/edit-delivery-address.component';
import { ChangeDeliveryAddressOrderComponent } from './orders-details/change-delivery-info/change-delivery-address-order/change-delivery-address-order.component';
import { AddDeliveryAddressOrderComponent } from './orders-details/change-delivery-info/add-delivery-address-order/add-delivery-address-order.component';
import { BillingProfilesComponent } from './my-dashboard/billing-profiles/billing-profiles.component';
import { DialogContentDeleteDialogComponent } from './my-dashboard/billing-profiles/dialog-content-delete-dialog/dialog-content-delete-dialog.component';
import { ChangePasswordComponent } from './my-dashboard/change-password/change-password.component';
import { CareerComponent } from './career/career.component';
import { CareerdetailsComponent } from './careerdetails/careerdetails.component';
import { OtpLoginComponent } from './login/otp-login/otp-login.component';
import { NewComponentComponent } from './new-component/new-component.component';
import { RentalAgreementComponent } from './rental-agreement/rental-agreement.component';
import { DrawSignatureComponent } from './checkout/signature-part/draw-signature/draw-signature.component';
import { CalgaryComponent } from './calgary/calgary.component';
import { EdmontonComponent } from './edmonton/edmonton.component';
import { OakvilleComponent } from './oakville/oakville.component';
import { GuelphComponent } from './guelph/guelph.component';
import { VancouverComponent } from './vancouver/vancouver.component';
import { MississaugaComponent } from './mississauga/mississauga.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { AddNewDeliveryAddressComponent } from './checkout/add-delivery-address/add-new-delivery-address.component';
import { OrderSignatureComponent } from './order-signature/order-signature.component';
import { SignatureConfirmationComponent } from './order-signature/signature-confirmation/signature-confirmation.component';
import { ReviewsSliderComponent } from './home/reviews-slider/reviews-slider.component';
import { DeclineLWDDialogComponent } from './checkout/decline-lwddialog/decline-lwddialog.component';
import { ProdBannerComponent } from './banner/prod-banner/prod-banner.component';
import { AddMobileComponent } from './checkout/add-mobile/add-mobile.component';
import { ItemAddedDialogComponent } from './product-details/itemAddedDialog/itemAddedDialog.component';
import { FaqPageComponent } from './faq-page/faq-page.component';
import { CartSideBarComponent } from './cart-sidebar/cart-sidebar.component';
import { StorageDialogueComponent } from './storage-dialogue/storage-dialogue.component';





const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  "bgsColor": " #8BC400",
  "bgsOpacity": 0.5,
  "bgsPosition": "bottom-right",
  "bgsSize": 5,
  "bgsType": "ball-spin-clockwise",
  "blur": 5,
  "delay": 0,
  "fgsColor": " #8BC400",
  "fgsPosition": "center-center",
  "fgsSize": 120,
  "fgsType": "rectangle-bounce-pulse-out-rapid",
  "gap": 24,
  "logoPosition": "center-center",
  "logoSize": 120,
  "logoUrl": "",
  "masterLoaderId": "master",
  "overlayBorderRadius": "0",
  "overlayColor": "rgba(40, 40, 40, 0.8)",
  "pbColor": " #8BC400",
  "pbDirection": "ltr",
  "pbThickness": 10,
  "hasProgressBar": true,
  "text": "",
  "textColor": "#FFFFFF",
  "textPosition": "center-center",
  "maxTime": 3000,
  "minTime": 500
};

@NgModule({
  exports: [
    MatAutocompleteModule, ReactiveFormsModule, MatInputModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule, CommonModule, MatIconModule
  ],
  declarations: [],
  imports: [NgbModule]
})
export class MaterialModule { }


//@NgModule({
//  declarations: [
//    AppComponent
//  ],
//  imports: [
//    BrowserModule.withServerTransition({ appId: 'serverApp' }),
//    AppRoutingModule
//  ],
//  providers: [],
//  bootstrap: [AppComponent]
//})
@NgModule({

  declarations: [StorageDialogueComponent,CartSideBarComponent, FaqPageComponent, ItemAddedDialogComponent, AddMobileComponent, ReviewsSliderComponent, DrawSignatureComponent, RentalAgreementComponent, OtpLoginComponent, CareerdetailsComponent, CareerComponent, ChangePasswordComponent, DialogContentDeleteDialogComponent, BillingProfilesComponent, NoSanitizePipe, SelectDeliveryAddressDialogComponent, DeliveryAddressListComponent, AddDeliveryAddressComponent, EditDeliveryAddressComponent,
    AppComponent, PostalcodedialogComponent, BillingProfileComponent, PostalCodeFranchiseDialogComponent, SignaturePartComponent,
    NavMenuComponent, StandardProductComponent, KitProductComponent, QouteDetailsComponent, AdwordComponent,
    HomeComponent, DeliveryPolicyComponent, OrderConfirmationComponent, CompleteStandardRentalComponent,
    CounterComponent, ProductsComponent, LoginComponent, AutoRenewalsComponent,
    FetchDataComponent, AppfooterComponent, ProductDetailsComponent, SignatureConfirmationComponent,
    RegisterComponent, LoginComponent, PrivacyPolicyComponent, AboutComponent, OrderSignatureComponent,
    CancellationPolicyComponent, ContactUsComponent, TestcardComponent,
    MyDashboardComponent, ProfileCompleteComponent, BannerComponent, ProdBannerComponent, AddNewDeliveryAddressComponent,
    CartComponent, EmptyCartComponent, OperationsManualComponent, OrdersDetailsComponent,
    ForgotPasswordComponent, TermsComponent, CheckoutComponent, CustomerCartComponent, SignaturecompComponent, OrdersComponent, QoutationsComponent
    , ExtendProductComponent, ChangeDeliveryDateComponent, ChangeDeliveryInfoComponent, ChangeDeliveryTimeComponent,
    AddProductComponent, RelocationComponent, AddProductToQouteComponent, PostalcodeNFDialogComponent, ForgotPasswordComponent, FourWeeklyProductComponent
    , InvoicesignComponent, SignDialogComponent, StandardRentalComponent, WeelyRentalComponent,
    ChangeDeliveryAddressOrderComponent, AddDeliveryAddressOrderComponent, CalgaryComponent, EdmontonComponent, OakvilleComponent, GuelphComponent, VancouverComponent, MississaugaComponent
  ],
  imports: [
    //AngularFireModule.initializeApp({ /* See project settings in Firebase Console for this information */
    //  apiKey: "AIzaSyDVCrClRHcMKCleXZvRDwkKp0lOj3c7svw",
    //  projectId: "trgcustomerapp",
    //  storageBucket: "trgcustomerapp.appspot.com",
    //  messagingSenderId: "336805473318",
    //  appId: "1:336805473318:web:afa19c36cac95fe1334ae7"
    //}),
    //MaterialModule,
    LazyLoadImageModule, SeoServiceModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    SignaturePadModule, HttpInterceptorModule, CookieLawModule,
    NgPaymentCardModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule, BrowserAnimationsModule, GooglePlaceModule,
    SignaturePadModule,
    MaterialModule, SignaturePadModule,
    FormsModule, NgbModule, NgxUiLoaderModule.forRoot(ngxUiLoaderConfig), NgxUiLoaderHttpModule,
    RouterModule.forRoot([
      { path: '', pathMatch: 'full', redirectTo: 'home' },
      { path: 'home', component: HomeComponent, pathMatch: 'full' },
      { path: 'calgary', component: CalgaryComponent, pathMatch: 'full' },
      { path: 'edmonton', component: EdmontonComponent, pathMatch: 'full' },
      { path: 'oakville', component: OakvilleComponent, pathMatch: 'full' },
      { path: 'guelph', component: GuelphComponent, pathMatch: 'full' },
      { path: 'vancouver', component: VancouverComponent, pathMatch: 'full' },
      { path: 'mississauga', component: MississaugaComponent, pathMatch: 'full' },
      { path: 'home/:id', component: HomeComponent, pathMatch: 'full' },
      //{ path: '**', component: HomeComponent, pathMatch: 'full' },
      { path: 'login', component: LoginComponent, pathMatch: 'full' },
      { path: 'loginOtp/:user/:code/:orderId', component: OtpLoginComponent, pathMatch: 'full' },
      { path: 'register', component: RegisterComponent, pathMatch: 'full' },
      { path: 'forgotpassword', component: ForgotPasswordComponent, pathMatch: 'full' },
      { path: 'checkout', component: CheckoutComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'privacy', component: PrivacyPolicyComponent, pathMatch: 'full' },
      { path: 'operations', component: OperationsManualComponent, pathMatch: 'full' },
      { path: 'terms', component: TermsComponent, pathMatch: 'full' },
      { path: 'about', component: AboutComponent, pathMatch: 'full' },
      { path: 'faq', component: FaqPageComponent, pathMatch: 'full' },
      { path: 'cancellation', component: CancellationPolicyComponent, pathMatch: 'full' },
      { path: 'contactus', component: ContactUsComponent, pathMatch: 'full' },
      { path: 'dashboard', component: MyDashboardComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'profilecomplete', component: ProfileCompleteComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'cart', component: CartComponent, pathMatch: 'full' },
      { path: 'emptycart', component: EmptyCartComponent, pathMatch: 'full' },
      { path: 'products/:city/:catagory', component: ProductsComponent, pathMatch: 'full' },
      { path: 'productdetails/:city/:product', component: ProductDetailsComponent, pathMatch: 'full' },
      { path: 'standardRental/:city/:product', component: StandardRentalComponent, pathMatch: 'full' },
      { path: 'weeklyProductdetails/:city/:product', component: WeelyRentalComponent, pathMatch: 'full' },
      { path: 'adword/:city/:catagory/:location', component: AdwordComponent, pathMatch: 'full' },
      { path: 'standard/:product/:id', component: StandardProductComponent, pathMatch: 'full' },
      { path: 'kit/:product/:id', component: KitProductComponent, pathMatch: 'full' },
      { path: 'delivery', component: DeliveryPolicyComponent, pathMatch: 'full' },
      { path: 'orders', component: OrdersComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'ordersDetail/:id', component: OrdersDetailsComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'extendProduct/:id/:productId', component: ExtendProductComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'changeDeliveryDate/:id', component: ChangeDeliveryDateComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'changeDeliveryInfo/:id', component: ChangeDeliveryInfoComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'changeDeliveryTime/:id', component: ChangeDeliveryTimeComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'addProduct/:id', component: AddProductComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'relocation/:id', component: RelocationComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'qoutations', component: QoutationsComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'billing', component: BillingProfileComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'qouteDetails/:id', component: QouteDetailsComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'addProductQoute/:id', component: AddProductToQouteComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'invoiceSign/:id', component: InvoicesignComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'forgotPassword', component: ForgotPasswordComponent, pathMatch: 'full' },
      { path: 'orderConfirmation', component: OrderConfirmationComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'fourWeekProduct/:product/:id', component: FourWeeklyProductComponent, pathMatch: 'full' },
      { path: 'completeStandardRental/:id', component: CompleteStandardRentalComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'autoRenewal', component: AutoRenewalsComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'billingProfiles', component: BillingProfilesComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'changePassword', component: ChangePasswordComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'career', component: CareerComponent, pathMatch: 'full' },
      { path: 'careerDetails/:id', component: CareerdetailsComponent, pathMatch: 'full' },
      { path: 'orderSignature/:id/:orderId', component: OrderSignatureComponent, pathMatch: 'full' },
      { path: 'addDeliveryAddress/:id', component: AddNewDeliveryAddressComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'rentalAgreement', component: RentalAgreementComponent, pathMatch: 'full' },
      { path: 'signatureConfirmation/:id', component: SignatureConfirmationComponent, pathMatch: 'full' },
      { path: 'addmobile', component: AddMobileComponent, pathMatch: 'full', canActivate: [AuthGuard] },

      { path: '**', redirectTo: '/home' }
    ], {
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'legacy',
      initialNavigation: 'enabled'
    })
  ],
  providers: [
    //  {
    //  provide: Renderer2, useClass: DomHydrationRenderer
    //},
    UserService, DataService, SecurityService, AuthGuard, HelperService],
  exports: [NgbModule, MatTabsModule, GooglePlaceModule, SignaturePadModule, CommonModule, MatIconModule, NgPaymentCardModule],
  bootstrap: [AppComponent, NavMenuComponent],
  entryComponents: [StorageDialogueComponent,DeclineLWDDialogComponent, DialogContentDeleteDialogComponent, SelectDeliveryAddressDialogComponent, PostalcodedialogComponent, PostalCodeFranchiseDialogComponent, PostalcodeNFDialogComponent,
    ChangeDeliveryAddressOrderComponent, AddDeliveryAddressOrderComponent,
    SignDialogComponent, AddDeliveryAddressComponent, EditDeliveryAddressComponent, ItemAddedDialogComponent]
})
export class AppModule { }
