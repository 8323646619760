import { Component, OnInit } from '@angular/core';
import { extendDaysResponse, responseMsg, ICustomerProfile } from '../../shared/Interfaces/IInterfaces';
import { MatSnackBar } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { SecurityService } from '../../security/security.service';
import { DataService } from '../../shared/data.service';
import { IRentalProducts } from '../../shared/Interfaces/ILogin';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-extend-product',
  templateUrl: './extend-product.component.html',
  styleUrls: ['./extend-product.component.css']
})
export class ExtendProductComponent implements OnInit {

  constructor(private ngxUiLoaderService: NgxUiLoaderService,private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router, private dataService: DataService,
    private securityService: SecurityService) {
    dataService.getCurrentCart('extendProd');
    this.orderId = parseInt(this.route.snapshot.params['id']);

    this.productId = parseInt(this.route.snapshot.params['productId']);
  }

  ProductModel: IRentalProducts = new IRentalProducts();

  orderId;

  productId;

  currentDays;

  maxDaysExtendAble;

  requestedExtendDays:number=0;

  TotalDays;

  productType;

  totalAmount = 0;

  totalTax = 0;

  total = 0;

  taxTag = "";

  extendDaysResponse: extendDaysResponse;

  customerId;
  customerProfile: ICustomerProfile = new ICustomerProfile();

  ngOnInit() {

    this.currentDays = Number(localStorage.getItem("currentDays"));

    this.productType = localStorage.getItem("productType");

    this.ngxUiLoaderService.start();

    this.dataService.getRentalProducts(this.productId)
      .subscribe(
        (data: IRentalProducts) => {
          this.ngxUiLoaderService.stop();
          this.ProductModel = data;

          this.getExtendableDays();
        },
        (err: any) => console.log(err),
        () => {
          console.log("rental product Data Recived");
        }
    );

    this.customerId = Number(localStorage.getItem("customerId"));
    let franchsie = Number(localStorage.getItem("currentOrder_franchiseId"));

    this.dataService.currentBilling(this.customerId, franchsie)
      .subscribe(
        (data: ICustomerProfile) => {
          this.customerProfile = data;
        },
        (err: any) => {
          this.ngxUiLoaderService.stop();
          console.log(err);
        },
        () => {
          console.log("Data Recived");
        }
      );

  }


  getExtendableDays()
  {
    this.dataService.extendAbleDaysCount(this.orderId)
      .subscribe(
        (data: extendDaysResponse) => {
          this.maxDaysExtendAble = data.maxQuantity;
          this.extendDaysResponse = data;

          let max = data.maxQuantity

          if (this.ProductModel.factor.type == 'Day') {
            this.maxDaysExtendAble = max;
          }
          else if (this.ProductModel.factor.type == 'Week') {
            this.maxDaysExtendAble = Number(max / 7);
          }
          else if (this.ProductModel.factor.type == '4Week') {
            this.maxDaysExtendAble = Number(Number(max / 7) / 4);
          }
        },
        (err: any) => console.log(err),
        () => {
          console.log("Data Recived");
        }
      );
  }

  extendDaysCheck()
  {
    this.TotalDays = Number(this.currentDays) + Number(this.requestedExtendDays);

    if (this.TotalDays > this.maxDaysExtendAble)
    {
      let message = "Sorry maximum limit is " + this.maxDaysExtendAble;
      let action = "Ok";
      this._snackBar.open(message, action, {
        duration: 2000,
      });
      this.TotalDays = this.maxDaysExtendAble;
      this.requestedExtendDays = this.maxDaysExtendAble;
    }

    let currentCost = this.extendDaysResponse.factorPrice[this.currentDays-1];
    let newCost = this.extendDaysResponse.factorPrice[this.TotalDays-1];
    this.totalAmount = newCost - currentCost;

    this.totalTax = this.totalAmount * Number(this.extendDaysResponse.tax.tax) / 100;

    this.taxTag = this.extendDaysResponse.tax.name + `(${this.extendDaysResponse.tax.tax}%)`

    this.total = this.totalAmount + this.totalTax;
  }


  extendProduct()
  {
    this.dataService.extendOrder(this.orderId, this.TotalDays, this.productType)
      .subscribe(
        (data: responseMsg) => {
          if (data.msg.includes("Successfull")) {
            let message = "Successfully Updated";
            let action = "Ok";
            this._snackBar.open(message, action, {
              duration: 2000,
            });

            let totalOrderId = localStorage.getItem("orderId");

            this.router.navigate(['/ordersDetail/' + totalOrderId]);
          }
          else
          {
            let message = data.msg;
            let action = "Ok";
            this._snackBar.open(message, action, {
              duration: 2000,
            });
          }
        },
        (err: any) => console.log(err),
        () => {
          console.log("Data Recived");
        }
      );
  }
}
