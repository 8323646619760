import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Company } from './user.model';
import { LoginModel } from './login.model';

@Injectable()
export class UserService {
  readonly rootUrl = 'https://localhost:44362';
  constructor(private http: HttpClient) { }

  registerUser(user: Company) {
    const body: Company = {
      Password: user.Password,
      Email: user.Email,
      Name: user.Name,
      Phone: user.Phone
    }
    //var reqHeader = new HttpHeaders({'No-Auth':'True'});
    //return this.http.post(this.rootUrl + '/api/User/Register', body,{headers : reqHeader});

    return this.http.get(this.rootUrl + '/api/Account/' + user.Email + '/' + user.Password + '/' + user.Phone + '/' + user.Name);

  }

  userAuthentication(userName, password) {
    //var data = "username=" + userName + "&password=" + password + "&grant_type=password";
    //var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
    //return this.http.post(this.rootUrl + '/token', data, { headers: reqHeader });
    
    const data: LoginModel = {
      Password: password,
      Email: userName,
      RememberMe: false,
      comingFromProduct: false,
      startDate: "",
      productId: 0,
      days: 0,
      quantity: 0,
      productType: "",
      location: "",
      tempCustomerId:0
    }
    var url = this.rootUrl + '/api/Account';

    //var reqHeader = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.rootUrl, data/*, { headers: reqHeader }*/);
  }

  getUserClaims(){
   return  this.http.get(this.rootUrl+'/api/GetUserClaims');
  }
}
