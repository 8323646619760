import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { DataService } from '../../../shared/data.service';
import { HttpClient } from '@angular/common/http';
import { IResponse } from '../../../shared/Interfaces/ILogin';

declare var $: any;

@Component({
  selector: 'app-draw-signature',
  templateUrl: './draw-signature.component.html',
  styleUrls: ['./draw-signature.component.css']
})
export class DrawSignatureComponent implements OnInit {

  @ViewChild("signature", { static: true }) signaturePad: SignaturePad;

  @ViewChild("signatureInitial", { static: true }) signatureInitial: SignaturePad;

  constructor(private router: Router, private _snackBar: MatSnackBar, private dataService: DataService, private http: HttpClient) {
    dataService.getCurrentCart('signComp');
  }

  customerId;
  comingFromInvoiceSignature = false;
  invoiceSignatureOrderID = 0;
  @Input() initials = "";
  @Input() fullname = "";

  ngOnInit() {

    try {
      let temp1 = localStorage.getItem("comingFromInvoiceSignature");
      let temp2 = localStorage.getItem("InvoiceSignatureOrderID");

      if (temp1 != undefined) {
        this.comingFromInvoiceSignature = (temp1 === 'true');
      }

      if (temp2 != undefined) {
        this.invoiceSignatureOrderID = parseInt(temp2);
      }


    } catch (e) {

    }

    let tempThis = this;
    //this.signaturePad.set('canvasWidth', '300px');
    //this.signatureInitial.set('canvasWidth', '200px');

    

    

    $(document).ready(function () {

      function checkPaddingForInvoice() {
        if (tempThis.comingFromInvoiceSignature) {
          $('.checkingboxpadding').css({ 'padding-left': '45px' });
          $('.checkingboxpadding').css({ 'padding-right': '30px' });
        }
      }
      function setDrawTab() {
        //try {
        //  tempThis.draw();
        //  const tabGroup = tempThis.SignatureTabs;
        //  tabGroup.selectedIndex = 2;
        //} catch (e) { }
      }

      if (window.matchMedia('(max-width: 380px)').matches) {
        setDrawTab();
        checkPaddingForInvoice();
      }
      //canvas {
      //  background - color: white!important;
      //  width: 200px;
      //  height: unset;
      //  margin - left: 10 % !important;
      //}

      else if (window.matchMedia('(max-width: 580px)').matches) {
        setDrawTab();
        checkPaddingForInvoice();
      }

      else if (window.matchMedia('(max-width: 680px)').matches) {
        setDrawTab(); checkPaddingForInvoice();
      }

      else if (window.matchMedia('(max-width: 850px)').matches) {
        setDrawTab(); checkPaddingForInvoice();
      }
    });

    this.customerId = Number(localStorage.getItem("customerId"));
    this.clearSignature();
    this.clearSignatureInitial();
  }

  drawDateTime() {
    $(document).ready(function () {
      //var ctxSignature = $("canvas")[2].getContext('2d');

      $('canvas').each(function (i, ele) {
        //console.log(i + ': ' + ele);
        try {
          if (i%2 == 0) {
            var ctxSignature;
            try {
              ctxSignature = ele.getContext('2d');
            } catch (e) {

            }

            ctxSignature.font = "1000 15px italic";

            ctxSignature.fillText("____________________________________________", 0, 110);


            var today = new Date();

            var mnth = (today.getMonth() + 1);
            var month = mnth.toString();

            if (month.toString().length == 1) {
              month = "0" + month;
            }

            var dy = today.getDate();

            var day = dy.toString();

            if (day.toString().length == 1) {
              day = "0" + day;
            }

            var date = today.getFullYear() + '-' + month + '-' + day;
            var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            var dateTime = date;//+ ' ' + time;


            ctxSignature.fillText("Date: " + dateTime, 5, 150);
          }
          else if (i%2 == 1) {
            var ctxInitials = ele.getContext('2d');
            ctxInitials.font = "1000 15px italic";
            ctxInitials.fillText("____________________________", 0, 110);
            ctxInitials.fillText("(initial here)", 45, 130);
            var today = new Date();
            var mnth = (today.getMonth() + 1);
            var month = mnth.toString();
            if (month.toString().length == 1) {
              month = "0" + month;
            }

            var dy = today.getDate();

            var day = dy.toString();

            if (day.toString().length == 1) {
              day = "0" + day;
            }

            var date = today.getFullYear() + '-' + month + '-' + day;
            var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            var dateTime = date;//+ ' ' + time;
            ctxInitials.fillText("Date: " + dateTime, 5, 150);
          }
        } catch (e) {

        }
      });
    });
  }

  clearSignatureInitial() {
    try {
      this.signatureInitial.clear();
    } catch (e) {

    }

    this.drawDateTime();
    //$(document).ready(function () {
    //  var ctxInitials;
    //  try {
    //    ctxInitials = $("canvas")[3].getContext('2d');
    //  } catch (e) {

    //  }

    //  ctxInitials.font = "1000 15px italic";
    //  ctxInitials.fillText("_____________________", 5, 120);

    //  ctxInitials.fillText("(initial here)", 25, 135);

    //  var today = new Date();

    //  var mnth = (today.getMonth() + 1);
    //  var month = mnth.toString();

    //  if (month.toString().length == 1) {
    //    month = "0" + month;
    //  }

    //  var dy = today.getDate();

    //  var day = dy.toString();

    //  if (day.toString().length == 1) {
    //    day = "0" + day;
    //  }

    //  var date = today.getFullYear() + '-' + month + '-' + day;
    //  var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    //  var dateTime = date + ' ' + time;

    //  ctxInitials.fillText("" + dateTime, 5, 150);
    //});
  }


  clearSignature() {
    try {
      this.signaturePad.clear();
    } catch (e) {

    }
    this.drawDateTime();
  }

  drawComplete() {
    // will be notified of szimek/signature_pad's onEnd event
    try {
      console.log(this.signaturePad.toDataURL());
    } catch (e) {

    }    
   
  }

  drawStart() {
    // will be notified of szimek/signature_pad's onBegin event
    console.log('begin drawing');
  }

  draw() {

    let tempThis = this;

    try {
      $(document).ready(function () {

        //var pad = document.getElementById("signPad");
        //var canvas = $("#signPad:first-child");
        ////var canvas:any = document.querySelector("canvas");
        ////var parentWidth = canvas.parent().outerWidth();
        //var parentWidth = $('#signPad').outerWidth();

        //canvas.setAttribute("width", 350);

        //var canvas: any = document.querySelector("signature-pad > canvas");
        //canvas.setAttribute("width", 300);
        //tempThis.signaturePad = new SignaturePad(canvas);

        var canvasSign: any = document.querySelectorAll("signature-pad > canvas")[0];
        canvasSign.setAttribute("width", 300);
        //tempThis.signaturePad = new SignaturePad(canvasSign)

        var canvas: any = document.querySelectorAll("signature-pad > canvas")[1];
        canvas.setAttribute("width", 170);
        //tempThis.signatureInitial = new SignaturePad(canvas)

        //$('canvas').each(function (i, ele) {
        //  //console.log(i + ': ' + ele);
        //  try {
        //    if (i % 2 == 0) {
        //      var ctxSignature;
        //      try {
        //        ctxSignature = ele.getContext('2d');
        //      } catch (e) {

        //      }

        //      ctxSignature.canvas.width = 350;

        //    }
        //    else if (i % 2 == 1) {
        //      var ctxInitials = ele.getContext('2d');
        //      ctxInitials.canvas.width = 100;
        //    }
        //  } catch (e) {

        //  }
        //});
      });
    } catch (e) {

    }

    //if (this.selectedTabIndex == 1) {
      this.clearSignature();
      this.clearSignatureInitial();
      this.drawDateTime();
   // }
  }

  sign(): boolean {
   
    let url = `${this.dataService.baseurl}/api/saveSignatureWeb/?id=${this.customerId}&PrintedName=${this.fullname}&PrintedInit=${this.initials}`;
    let urlInitials = `${this.dataService.baseurl}/api/SaveInitials/?id=${this.customerId}&PrintedName=${this.fullname}&PrintedInit=${this.initials}`;

      if (this.comingFromInvoiceSignature) {
        url = `${this.dataService.baseurl}/api/InvoiceSignature/?OrderID=${this.invoiceSignatureOrderID}`;
        urlInitials = `${this.dataService.baseurl}/api/InvoiceSignatureInitials/?OrderID=${this.invoiceSignatureOrderID}`;
        //InvoiceSignatureInitials
      }


        const formDataInitial = new FormData();
        let fileDataInitial = this.signatureInitial.toDataURL();
        formDataInitial.append('file', fileDataInitial);
        this.http.post<IResponse>(urlInitials, formDataInitial)
          .subscribe(res => {
            localStorage.setItem("SignedInitUrl", res.result);

            try {
              const formData = new FormData();
              let fileData = this.signaturePad.toDataURL();
              formData.append('file', fileData);
              this.http.post<IResponse>(url, formData)
                .subscribe(res2 => {
                  console.log(res2);
                  //this.isUploadedSuccessfully = true;
                  localStorage.setItem("SignedUrl", res2.result);
                  localStorage.setItem("IsSigned", 'true');
                  if (this.comingFromInvoiceSignature) {
                  }
                });
            } catch (e) {

            }          

          });

        setTimeout(() => { }, 15000);
        return true;      
    }
}
