import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { ICustomerBilling, responseMsg, IResponseClass } from '../../shared/Interfaces/IInterfaces';
import { MatSnackBar } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { SecurityService } from '../../security/security.service';
import { DataService } from '../../shared/data.service';
import { ICustomer, IFranchsiePosalCode } from '../../shared/Interfaces/ILogin';
import Swal from 'sweetalert2';
import { Title } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';

declare var $: any;

@Component({
  selector: 'app-billing-profile',
  templateUrl: './billing-profile.component.html',
  styleUrls: ['./billing-profile.component.css']
})
export class BillingProfileComponent implements OnInit {

  cardNumber: string;
  cardHolder: string;
  expirationMonth: string;
  expirationYear: string;
  ccv: number;

  constructor(@Inject(PLATFORM_ID) private _platformId: Object,private titleService: Title, private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router, private dataService: DataService,
    private securityService: SecurityService) {
    dataService.getCurrentCart('billing');
    this.titleService.setTitle("TheRentalGuys.Ca - Billing Profile");
  }

  customerBilling: ICustomerBilling = new ICustomerBilling();
  customerId;
  city;

  ngOnInit() {
    if (isPlatformBrowser(this._platformId)) {
      $(document).ready(function () {

        $('.cc-form__label').eq(5).text("MM");
        $('.cc-form__label').eq(6).text("YYYY");

        if (window.matchMedia('(max-width: 380px)').matches) {

          $(".cc-form__input").css({ 'padding-left': '2px' });

          $('.cc-box').delay(1000).css({ 'width': '100%' });

          $('.cc-form__input').css({ 'border': '1px solid rgba(0,0,0,.3)' });

          $('.cc-form__input--embosed').delay(1000).css({ 'font-size': '22px' });

          $('.cc-box__logo').delay(1000).css({ 'color': 'black' });

          $('.cc-form__input--embosed').delay(1000).css({ 'color': 'black' });

          $('.cc-box__logo').delay(1000).css({ 'margin-top': '0px' });

          $('.cc-box__logo').delay(1000).css({ 'color': '#565656' });

          $('.cc-box__logo').delay(1000).css({ 'font-size': '25px' });

          $('.cc-box__logo').delay(1000).css({ 'font-style': 'unset' });

          $(".cc-form__label").css({ 'font-family': 'inherit' });

          $(".cc-form__label").css({ 'line-height': '5px' });


          $(".cc-box__logo").css({ 'margin-bottom': '-15px' });

          $(".cc-form__input").css({ 'color': '#565656' });

          $(".cc-form__input--embosed").css({ 'font-family': 'inherit' });

          $(".cc-form__input--embosed").css({ 'text-shadow': 'none' });

          $(".cc-form__label").css({ 'font-size': '.8em' });

          $('.cc-box__front').delay(1000).css({ 'border': '10px solid' });

          $('.cc-box__back').delay(1000).css({ 'border': '10px solid' });

          $('.cc-box__strip').css({ 'background': '#565656' });

          $(".cc-form__input").first().css({ 'border-radius': '0px' });

          $(".cc-form__input--cursive").css({ 'box-shadow': 'none' });

          $(".cc-form__input--cursive").css({ 'font-size': '30px' });

          $('.cc-form__input').css({ 'background': 'white' });

          $('.cc-form__input').eq(0).css({ 'border': 'none' });

          $('.cc-form__input').eq(1).css({ 'border': 'none' });

          $('.cc-form__input').eq(2).css({ 'border': 'none' });

          $('.cc-form__input').eq(3).css({ 'border': 'none' });

          $(".cc-form__label").css({ 'font-family': 'inherit' });

          $(".cc-box__element").eq(1).css({ 'width': ' 70%' });

          $(".cc-box__element").eq(1).css({ 'width': ' 70%' });

          $(".cc-box__element").eq(1).css({ 'margin-left': ' -10%' });

          $(".cc-box__element").eq(2).css({ 'width': ' 70%' });

          $(".cc-box__element").eq(2).css({ 'margin-left': ' -10%' });

          $(".cc-box__element").eq(3).css({ 'position': 'absolute' });

          $(".cc-box__element").eq(3).css({ 'margin-left': '50px' });

          $(".cc-box__element").eq(3).css({ 'width': '65%' });

          $('.cc-box__strip').css({ 'background': '#565656' });

          $('.cc-box__back').delay(1000).css({ 'border-color': '#565656' });

          //$('.cc-box__front').delay(1000).css({ 'border-radius': '3px' });s

          $(".cc-form__label").first().css({ 'margin-top': '-15px' });

          $(".cc-form__label").first().text("Card #");

          $('.cc-form__label').delay(1000).css({ 'color': '#666666' });

          $(".cc-form").css({ 'margin-top': '95px' });

          $(".cc-form__label").first().css({ 'color': 'black' });

          $(".cc-form__label").eq(1).css({ 'color': 'black' });

          $(".cc-form__label").eq(2).css({ 'color': 'black' });

          $('.cc-box__front').delay(1000).css({ 'border-color': '#565656' });

          $('.cc-box__front').delay(1000).css({ 'height': '215px' });
          $('.cc-box__back').delay(100).css({ 'height': '215px' });

          $('.cc-form__wrapper--long').first().css({ 'margin-top': '-75px' });

          $('.cc-box__back').delay(1000).css({ 'background': "url(assets/images/mobilelock.PNG) no-repeat left 98%" });
          $('.cc-box__front').delay(1000).css({ 'background': "url(assets/images/mobilelock.PNG) no-repeat right 98%" });

          $('.cc-form').delay(1000).css({ 'display': 'block' });

          $('.cc-form').delay(1000).css({ 'width': '100%' });
          $('.cc-form').delay(1000).css({ 'margin-left': '5%' });

          $('.cc-form__button--ripple').css({ 'margin-top': '250px' });
          $('.cc-form__button--ripple').css({ 'z-index': '10' });
          $('.cc-form__button--ripple').css({ 'position': 'absolute' });
          $('.cc-form__button--ripple').delay(1000).css({ 'background': '#33afe2' });
          $('.cc-form__button--ripple').hide();

          $('.cc-form__input').delay(1000).css({ 'border-radius': '0px' });

          $('.cc-form__select').delay(1000).css({ 'border-radius': '0px' });

          $(".cc-form--inline").addClass("row");

          $(".cc-form__wrapper--short").addClass("col-md-4");
        }

        else if (window.matchMedia('(max-width: 580px)').matches) {

          $(".cc-form__input").css({ 'padding-left': '2px' });

          $('.cc-box').delay(1000).css({ 'width': '100%' });

          $('.cc-form__input--embosed').delay(1000).css({ 'font-size': '22px' });

          $('.cc-box__logo').delay(1000).css({ 'color': 'black' });

          $('.cc-form__input--embosed').delay(1000).css({ 'color': 'black' });

          $('.cc-box__logo').delay(1000).css({ 'margin-top': '0px' });

          $('.cc-box__logo').delay(1000).css({ 'color': '#565656' });

          $('.cc-box__logo').delay(1000).css({ 'font-size': '25px' });

          $('.cc-box__logo').delay(1000).css({ 'font-style': 'unset' });

          $(".cc-form__label").css({ 'font-family': 'inherit' });

          $(".cc-form__label").css({ 'line-height': '5px' });


          $(".cc-box__logo").css({ 'margin-bottom': '-15px' });

          $(".cc-form__input").css({ 'color': '#565656' });

          $(".cc-form__input--embosed").css({ 'font-family': 'inherit' });

          $(".cc-form__input--embosed").css({ 'text-shadow': 'none' });

          $(".cc-form__label").css({ 'font-size': '.8em' });

          $('.cc-box__front').delay(1000).css({ 'border': '10px solid' });

          $('.cc-box__back').delay(1000).css({ 'border': '10px solid' });

          $('.cc-box__strip').css({ 'background': '#565656' });

          $(".cc-form__input").first().css({ 'border-radius': '0px' });

          $(".cc-form__input--cursive").css({ 'box-shadow': 'none' });

          $(".cc-form__input--cursive").css({ 'font-size': '30px' });

          $('.cc-form__input').css({ 'background': 'white' });

          $('.cc-form__input').eq(0).css({ 'border': 'none' });

          $('.cc-form__input').eq(1).css({ 'border': 'none' });

          $('.cc-form__input').eq(2).css({ 'border': 'none' });

          $('.cc-form__input').eq(3).css({ 'border': 'none' });

          $(".cc-box__element").eq(1).css({ 'width': ' 70%' });

          $(".cc-box__element").eq(1).css({ 'margin-left': ' -10%' });

          $(".cc-box__element").eq(2).css({ 'width': ' 70%' });

          $(".cc-box__element").eq(2).css({ 'margin-left': ' -10%' });

          $(".cc-box__element").eq(3).css({ 'position': 'absolute' });

          $(".cc-box__element").eq(3).css({ 'margin-left': '50px' });

          $(".cc-box__element").eq(3).css({ 'width': '65%' });

          $('.cc-box__strip').css({ 'background': '#565656' });

          $('.cc-box__back').delay(1000).css({ 'border-color': '#565656' });

          //$('.cc-box__front').delay(1000).css({ 'border-radius': '3px' });s

          $(".cc-form__label").first().css({ 'margin-top': '-15px' });

          $(".cc-form__label").first().text("Card #");

          $('.cc-form__label').delay(1000).css({ 'color': '#666666' });






          $(".cc-form").css({ 'margin-top': '95px' });



          $(".cc-form__label").first().css({ 'color': 'black' });

          $(".cc-form__label").eq(1).css({ 'color': 'black' });

          $(".cc-form__label").eq(2).css({ 'color': 'black' });

          $('.cc-box__front').delay(1000).css({ 'border-color': '#565656' });

          $('.cc-box__front').delay(1000).css({ 'height': '215px' });
          $('.cc-box__back').delay(100).css({ 'height': '215px' });

          $('.cc-form__wrapper--long').first().css({ 'margin-top': '-75px' });

          $('.cc-box__back').delay(1000).css({ 'background': "url(assets/images/mobilelock.PNG) no-repeat left 98%" });
          $('.cc-box__front').delay(1000).css({ 'background': "url(assets/images/mobilelock.PNG) no-repeat right 98%" });

          $('.cc-form').delay(1000).css({ 'display': 'block' });

          $('.cc-form').delay(1000).css({ 'width': '100%' });
          $('.cc-form').delay(1000).css({ 'margin-left': '5%' });

          $('.cc-form__button--ripple').css({ 'margin-top': '250px' });
          $('.cc-form__button--ripple').css({ 'z-index': '10' });
          $('.cc-form__button--ripple').css({ 'position': 'absolute' });
          $('.cc-form__button--ripple').delay(1000).css({ 'background': '#33afe2' });
          $('.cc-form__button--ripple').hide();

          $('.cc-form__input').delay(1000).css({ 'border-radius': '0px' });

          $('.cc-form__select').delay(1000).css({ 'border-radius': '0px' });

          $(".cc-form--inline").addClass("row");

          $(".cc-form__wrapper--short").addClass("col-md-4");



        }

        else if (window.matchMedia('(max-width: 680px)').matches) {

          $(".cc-form__input").css({ 'padding-left': '2px' });

          $(".cc-form__wrapper--last").css({ 'margin-left': '0px' });

          $(".cc-form__wrapper--short").css({ 'width': '30%' });

          $('.cc-box').delay(1000).css({ 'width': '65%' });

          $('.cc-form__input--embosed').delay(1000).css({ 'font-size': '22px' });

          $('.cc-box__logo').delay(1000).css({ 'color': 'black' });

          $('.cc-form__input--embosed').delay(1000).css({ 'color': 'black' });

          $('.cc-box__logo').delay(1000).css({ 'margin-top': '0px' });

          $('.cc-box__logo').delay(1000).css({ 'color': '#565656' });

          $('.cc-box__logo').delay(1000).css({ 'font-size': '25px' });

          $('.cc-box__logo').delay(1000).css({ 'font-style': 'unset' });

          $(".cc-form__label").css({ 'font-family': 'inherit' });

          $(".cc-form__label").css({ 'line-height': '5px' });


          $(".cc-box__logo").css({ 'margin-bottom': '-15px' });

          $(".cc-form__input").css({ 'color': '#565656' });

          $(".cc-form__input--embosed").css({ 'font-family': 'inherit' });

          $(".cc-form__input--embosed").css({ 'text-shadow': 'none' });

          $(".cc-form__label").css({ 'font-size': '.8em' });

          $('.cc-box__front').delay(1000).css({ 'border': '10px solid' });

          $('.cc-box__back').delay(1000).css({ 'border': '10px solid' });

          $('.cc-box__strip').css({ 'background': '#565656' });

          $(".cc-form__input").first().css({ 'border-radius': '0px' });

          $(".cc-form__input--cursive").css({ 'box-shadow': 'none' });

          $(".cc-form__input--cursive").css({ 'font-size': '30px' });

          $('.cc-form__input').css({ 'background': 'white' });

          $('.cc-form__input').eq(0).css({ 'border': 'none' });

          $('.cc-form__input').eq(1).css({ 'border': 'none' });

          $('.cc-form__input').eq(2).css({ 'border': 'none' });

          $('.cc-form__input').eq(3).css({ 'border': 'none' });

          $(".cc-box__element").eq(1).css({ 'width': ' 70%' });

          $(".cc-box__element").eq(1).css({ 'margin-left': ' -10%' });

          $(".cc-box__element").eq(2).css({ 'width': ' 70%' });

          $(".cc-box__element").eq(2).css({ 'margin-left': ' -10%' });

          $(".cc-box__element").eq(3).css({ 'position': 'absolute' });

          $(".cc-box__element").eq(3).css({ 'margin-left': '50px' });

          $(".cc-box__element").eq(3).css({ 'width': '65%' });

          $('.cc-box__strip').css({ 'background': '#565656' });

          $('.cc-box__back').delay(1000).css({ 'border-color': '#565656' });

          //$('.cc-box__front').delay(1000).css({ 'border-radius': '3px' });s

          $(".cc-form__label").first().css({ 'margin-top': '-15px' });

          $(".cc-form__label").first().text("Card #");

          $('.cc-form__label').delay(1000).css({ 'color': '#666666' });



          $(".cc-form").css({ 'margin-top': '95px' });



          $(".cc-form__label").first().css({ 'color': 'black' });

          $(".cc-form__label").eq(1).css({ 'color': 'black' });

          $(".cc-form__label").eq(2).css({ 'color': 'black' });

          $('.cc-box__front').delay(1000).css({ 'border-color': '#565656' });

          $('.cc-box__front').delay(1000).css({ 'height': '240px' });
          $('.cc-box__back').delay(100).css({ 'height': '240px' });

          $('.cc-form__wrapper--long').first().css({ 'margin-top': '-30px' });

          $('.cc-box__back').delay(1000).css({ 'background': "url(assets/images/mobilelock.PNG) no-repeat left 98%" });
          $('.cc-box__front').delay(1000).css({ 'background': "url(assets/images/mobilelock.PNG) no-repeat right 98%" });

          $('.cc-form').delay(1000).css({ 'display': 'block' });

          $('.cc-form').delay(1000).css({ 'width': '100%' });
          $('.cc-form').delay(1000).css({ 'margin-left': '5%' });

          $('.cc-form__button--ripple').css({ 'margin-top': '250px' });
          $('.cc-form__button--ripple').css({ 'z-index': '10' });
          $('.cc-form__button--ripple').css({ 'position': 'absolute' });
          $('.cc-form__button--ripple').delay(1000).css({ 'background': '#33afe2' });
          $('.cc-form__button--ripple').hide();

          $('.cc-form__input').delay(1000).css({ 'border-radius': '0px' });

          $('.cc-form__select').delay(1000).css({ 'border-radius': '0px' });

          $(".cc-form--inline").addClass("row");

          $(".cc-form__wrapper--short").addClass("col-md-4");


          $(".cc-form__label").css({ 'font-family': 'inherit' });
        }

        else if (window.matchMedia('(max-width: 850px)').matches) {

          $(".cc-box__element").eq(2).css({ 'overflow': 'hidden' });

          $(".cc-box__element").eq(3).css({ 'overflow': 'hidden' });

          $(".cc-form__input").css({ 'padding-left': '2px' });

          $(".cc-form__wrapper--last").css({ 'margin-left': '0px' });

          $(".cc-form__wrapper--short").css({ 'width': '30%' });

          $('.cc-box').delay(1000).css({ 'width': '60%' });

          $('.cc-form__input--embosed').delay(1000).css({ 'font-size': '22px' });

          $('.cc-box__logo').delay(1000).css({ 'color': 'black' });

          $('.cc-form__input--embosed').delay(1000).css({ 'color': 'black' });

          $('.cc-box__logo').delay(1000).css({ 'margin-top': '0px' });

          $('.cc-box__logo').delay(1000).css({ 'color': '#565656' });

          $('.cc-box__logo').delay(1000).css({ 'font-size': '25px' });

          $('.cc-box__logo').delay(1000).css({ 'font-style': 'unset' });

          $(".cc-form__label").css({ 'font-family': 'inherit' });

          $(".cc-form__label").css({ 'line-height': '5px' });


          $(".cc-box__logo").css({ 'margin-bottom': '-15px' });

          $(".cc-form__input").css({ 'color': '#565656' });

          $(".cc-form__input--embosed").css({ 'font - family': 'inherit' });

          $(".cc-form__input--embosed").css({ 'text-shadow': 'none' });

          $(".cc-form__label").css({ 'font-size': '.8em' });

          $('.cc-box__front').delay(1000).css({ 'border': '10px solid' });

          $('.cc-box__back').delay(1000).css({ 'border': '10px solid' });

          $('.cc-box__strip').css({ 'background': '#565656' });

          $(".cc-form__input").first().css({ 'border-radius': '0px' });

          $(".cc-form__input--cursive").css({ 'box-shadow': 'none' });

          $(".cc-form__input--cursive").css({ 'font-size': '30px' });

          $('.cc-form__input').css({ 'background': 'white' });

          $('.cc-form__input').eq(0).css({ 'border': 'none' });

          $('.cc-form__input').eq(1).css({ 'border': 'none' });

          $('.cc-form__input').eq(2).css({ 'border': 'none' });

          $('.cc-form__input').eq(3).css({ 'border': 'none' });

          $(".cc-box__element").eq(1).css({ 'width': ' 70%' });

          $(".cc-box__element").eq(1).css({ 'margin-left': ' -10%' });

          $(".cc-box__element").eq(2).css({ 'width': ' 70%' });

          $(".cc-box__element").eq(2).css({ 'margin-left': ' -10%' });

          $(".cc-box__element").eq(3).css({ 'position': 'absolute' });

          $(".cc-box__element").eq(3).css({ 'margin-left': '50px' });

          $(".cc-box__element").eq(3).css({ 'width': '65%' });

          $('.cc-box__strip').css({ 'background': '#565656' });

          $('.cc-box__back').delay(1000).css({ 'border-color': '#565656' });

          //$('.cc-box__front').delay(1000).css({ 'border-radius': '3px' });s

          $(".cc-form__label").first().css({ 'margin-top': '-15px' });

          $(".cc-form__label").first().text("Card #");

          $('.cc-form__label').delay(1000).css({ 'color': '#666666' });

          $(".cc-form").css({ 'margin-top': '95px' });

          $(".cc-form__label").first().css({ 'color': 'black' });

          $(".cc-form__label").eq(1).css({ 'color': 'black' });

          $(".cc-form__label").eq(2).css({ 'color': 'black' });

          $('.cc-box__front').delay(1000).css({ 'border-color': '#565656' });

          $('.cc-box__front').delay(1000).css({ 'height': '240px' });
          $('.cc-box__back').delay(100).css({ 'height': '240px' });

          $('.cc-form__wrapper--long').first().css({ 'margin-top': '-30px' });

          $('.cc-box__back').delay(1000).css({ 'background': "url(assets/images/mobilelock.PNG) no-repeat left 98%" });
          $('.cc-box__front').delay(1000).css({ 'background': "url(assets/images/mobilelock.PNG) no-repeat right 98%" });

          $('.cc-form').delay(1000).css({ 'display': 'block' });

          $('.cc-form').delay(1000).css({ 'width': '100%' });
          $('.cc-form').delay(1000).css({ 'margin-left': '5%' });

          $('.cc-form__button--ripple').css({ 'margin-top': '250px' });
          $('.cc-form__button--ripple').css({ 'z-index': '10' });
          $('.cc-form__button--ripple').css({ 'position': 'absolute' });
          $('.cc-form__button--ripple').delay(1000).css({ 'background': '#33afe2' });
          $('.cc-form__button--ripple').hide();

          $('.cc-form__input').delay(1000).css({ 'border-radius': '0px' });

          $('.cc-form__select').delay(1000).css({ 'border-radius': '0px' });

          $(".cc-form--inline").addClass("row");

          $(".cc-form__wrapper--short").addClass("col-md-4");


        }

        else if (window.matchMedia('(max-width: 1650px)').matches) {



          $(".cc-form__input--embosed").css({ 'width': '300px' });

          $(".cc-box").css({ 'width': '55%' });

          $(".cc-form__label").css({ 'line-height': '15px' });

          $(".cc-form__select").css({ 'width': '125%' });

          $(".cc-form__input").css({ 'padding-left': '2px' });

          $(".cc-form__label").css({ 'font-family': 'inherit' });

          $('.cc-form__input--embosed').delay(1000).css({ 'font-size': '25px' });

          $('.cc-box__logo').delay(1000).css({ 'color': 'black' });

          $('.cc-form__input--embosed').delay(1000).css({ 'color': 'black' });

          $('.cc-box__logo').delay(1000).css({ 'margin-top': '0px' });

          $('.cc-box__logo').delay(1000).css({ 'color': '#565656' });

          $('.cc-box__logo').delay(1000).css({ 'font-size': '25px' });

          $('.cc-box__logo').delay(1000).css({ 'font-style': 'unset' });

          $(".cc-form__label").css({ 'font-family': 'inherit' });

          $(".cc-form__input").css({ 'color': '#565656' });

          $(".cc-form__input--embosed").css({ 'font-family': 'inherit' });

          $(".cc-form__input--embosed").css({ 'text-shadow': 'none' });

          $(".cc-form__label").css({ 'font-size': '1em' });

          $('.cc-box__front').delay(1000).css({ 'border': '10px solid' });

          $('.cc-box__back').delay(1000).css({ 'border': '10px solid' });

          $('.cc-box__strip').css({ 'background': '#565656' });

          $(".cc-form__input").first().css({ 'border-radius': '0px' });

          $(".cc-form__input--cursive").css({ 'box-shadow': 'none' });

          $(".cc-form__input--cursive").css({ 'font-size': '30px' });

          $('.cc-form__input').css({ 'background': 'white' });

          $('.cc-form__input').css({ 'border': 'none' });

          $(".cc-box__element").eq(1).css({ 'width': ' 40%' });

          $(".cc-box__element").eq(1).css({ 'margin-left': ' -40%' });

          $(".cc-box__element").eq(2).css({ 'width': ' 40%' });

          $(".cc-box__element").eq(2).css({ 'margin-left': ' -40%' });

          $(".cc-box__element").eq(3).css({ 'position': 'absolute' });

          $(".cc-box__element").eq(3).css({ 'margin-left': '50px' });

          $(".cc-box__element").eq(3).css({ 'width': '65%' });

          $(".cc-box__element").eq(2).css({ 'overflow': 'hidden' });

          $(".cc-box__element").eq(3).css({ 'overflow': 'hidden' });

          $('.cc-box__strip').css({ 'background': '#565656' });

          $('.cc-box__back').delay(1000).css({ 'border-color': '#565656' });

          //$('.cc-box__front').delay(1000).css({ 'border-radius': '3px' });s

          $(".cc-form__label").first().css({ 'margin-top': '-25px' });

          $(".cc-form__label").first().text("Card #");

          $('.cc-form__label').delay(1000).css({ 'color': '#666666' });


          $(".cc-form__label").first().css({ 'color': 'black' });

          $(".cc-form__label").eq(1).css({ 'color': 'black' });

          $(".cc-form__label").eq(2).css({ 'color': 'black' });

          $('.cc-box__front').delay(1000).css({ 'border-color': '#565656' });

          $('.cc-box__front').delay(1000).css({ 'height': '270px' });
          $('.cc-box__back').delay(100).css({ 'height': '270px' });


          $('.cc-form__wrapper--long').first().css({ 'margin-top': '-75px' });


          $('.cc-box__back').delay(1000).css({ 'background': "url(assets/images/cardlock.PNG) no-repeat left 98%" });
          $('.cc-box__front').delay(1000).css({ 'background': "url(assets/images/cardlock.PNG) no-repeat right 98%" });

          $('.cc-form').delay(1000).css({ 'margin-top': '180px' });
          $('.cc-form').delay(1000).css({ 'display': 'block' });

          $('.cc-form').delay(1000).css({ 'width': '60%' });
          $('.cc-form').delay(1000).css({ 'margin-left': '20%' });

          $('.cc-form__button--ripple').css({ 'margin-top': '250px' });
          $('.cc-form__button--ripple').css({ 'z-index': '10' });
          $('.cc-form__button--ripple').css({ 'position': 'absolute' });
          $('.cc-form__button--ripple').delay(1000).css({ 'background': '#33afe2' });
          $('.cc-form__button--ripple').hide();

          $('.cc-form__input').delay(1000).css({ 'border-radius': '0px' });

          $('.cc-form__select').delay(1000).css({ 'border-radius': '0px' });

          $(".cc-form--inline").addClass("row");

          $(".cc-form__wrapper--short").addClass("col-md-4");


        }

        else {

          $(".cc-box__element").eq(2).css({ 'overflow': 'hidden' });

          $(".cc-form__input--embosed").css({ 'width': '300px' });

          $('.cc-box').delay(1000).css({ 'width': '35%' });

          $(".cc-form__input").css({ 'padding-left': '2px' });

          $(".cc-form__label").css({ 'font-family': 'inherit' });

          $('.cc-form__input--embosed').delay(1000).css({ 'font-size': '25px' });

          $('.cc-box__logo').delay(1000).css({ 'color': 'black' });

          $('.cc-form__input--embosed').delay(1000).css({ 'color': 'black' });

          $('.cc-box__logo').delay(1000).css({ 'margin-top': '0px' });

          $('.cc-box__logo').delay(1000).css({ 'color': '#565656' });

          $('.cc-box__logo').delay(1000).css({ 'font-size': '25px' });

          $('.cc-box__logo').delay(1000).css({ 'font-style': 'unset' });

          $(".cc-form__label").css({ 'font-family': 'inherit' });

          $(".cc-form__input").css({ 'color': '#565656' });

          $(".cc-form__input--embosed").css({ 'font-family': 'inherit' });

          $(".cc-form__input--embosed").css({ 'text-shadow': 'none' });

          $(".cc-form__label").css({ 'font-size': '1em' });



          $('.cc-box__front').delay(1000).css({ 'border': '10px solid' });

          $('.cc-box__back').delay(1000).css({ 'border': '10px solid' });

          $('.cc-box__strip').css({ 'background': '#565656' });

          $(".cc-form__input").first().css({ 'border-radius': '0px' });

          $(".cc-form__input--cursive").css({ 'box-shadow': 'none' });

          $(".cc-form__input--cursive").css({ 'font-size': '30px' });

          $('.cc-form__input').css({ 'background': 'white' });

          $('.cc-form__input').css({ 'border': 'none' });

          $(".cc-box__element").eq(1).css({ 'width': ' 40%' });

          $(".cc-box__element").eq(1).css({ 'margin-left': ' -40%' });

          $(".cc-box__element").eq(2).css({ 'width': ' 40%' });

          $(".cc-box__element").eq(2).css({ 'margin-left': ' -40%' });

          $(".cc-box__element").eq(3).css({ 'position': 'absolute' });

          $(".cc-box__element").eq(3).css({ 'margin-left': '50px' });

          $(".cc-box__element").eq(3).css({ 'width': '65%' });

          $('.cc-box__strip').css({ 'background': '#565656' });

          $('.cc-box__back').delay(1000).css({ 'border-color': '#565656' });

          //$('.cc-box__front').delay(1000).css({ 'border-radius': '3px' });s

          $(".cc-form__label").first().css({ 'margin-top': '-25px' });

          $(".cc-form__label").first().text("Card #");

          $('.cc-form__label').delay(1000).css({ 'color': '#666666' });


          $(".cc-form__label").first().css({ 'color': 'black' });

          $(".cc-form__label").eq(1).css({ 'color': 'black' });

          $(".cc-form__label").eq(2).css({ 'color': 'black' });

          $('.cc-box__front').delay(1000).css({ 'border-color': '#565656' });

          $('.cc-box__front').delay(1000).css({ 'height': '270px' });
          $('.cc-box__back').delay(100).css({ 'height': '270px' });


          $('.cc-form__wrapper--long').first().css({ 'margin-top': '-75px' });


          $('.cc-box__back').delay(1000).css({ 'background': "url(assets/images/cardlock.PNG) no-repeat left 98%" });
          $('.cc-box__front').delay(1000).css({ 'background': "url(assets/images/cardlock.PNG) no-repeat right 98%" });

          $('.cc-form').delay(1000).css({ 'margin-top': '180px' });
          $('.cc-form').delay(1000).css({ 'display': 'block' });

          $('.cc-form').delay(1000).css({ 'width': '60%' });
          $('.cc-form').delay(1000).css({ 'margin-left': '20%' });

          $('.cc-form__button--ripple').css({ 'margin-top': '250px' });
          $('.cc-form__button--ripple').css({ 'z-index': '10' });
          $('.cc-form__button--ripple').css({ 'position': 'absolute' });
          $('.cc-form__button--ripple').delay(1000).css({ 'background': '#33afe2' });
          $('.cc-form__button--ripple').hide();

          $('.cc-form__input').delay(1000).css({ 'border-radius': '0px' });

          $('.cc-form__select').delay(1000).css({ 'border-radius': '0px' });

          $(".cc-form--inline").addClass("row");

          $(".cc-form__wrapper--short").addClass("col-md-4");
        }

        try {
          var o = new Option("2026", "2026");
          $(o).html("2026");
          $("#cc-expiration-year").append(o);

          o = new Option("2027", "2027");
          $(o).html("2027");
          $("#cc-expiration-year").append(o);

          o = new Option("2028", "2028");
          $(o).html("2028");
          $("#cc-expiration-year").append(o);

          o = new Option("2029", "2029");
          $(o).html("2029");
          $("#cc-expiration-year").append(o);

          o = new Option("2030", "2030");
          $(o).html("2030");
          $("#cc-expiration-year").append(o);
        } catch (e) {

        }

      });
    }

    this.customerId = localStorage.getItem("customerId");

    this.customerBilling.customerId = Number(this.customerId);

    this.dataService.getCustomer(this.customerId)
      .subscribe(
        (data: ICustomer) => {
          //this.customer = data;
          this.customerBilling.postalCode = data.postalCode;
          this.customerBilling.route = data.route;
          this.customerBilling.streetAddress = data.streetAddress;
          this.customerBilling.franchisePostalCode = data.currentFranchise;
          //this.billing.postalCode = data.postalCode;

          if (data.currentFranchise != null || data.currentFranchise != "") {
            this.searchPostalCode();
          }
        },
        (err: any) => console.log(err),
        () => {
          console.log("Data Recived");
        }
      );
  }

  searchPostalCode(): void {
    this.dataService.getAllPostalCode(this.customerBilling.franchisePostalCode)
      .subscribe(
        (data: IFranchsiePosalCode) => {

          localStorage.setItem("currentPostalCode", data.postalCode);
          localStorage.setItem("currentDeliveryCity", data.title);
          localStorage.setItem("currentDeliveryCharge", data.deliveryCharge.toString());

          this.customerBilling.currentFranchiseId = data.franchiseId;
          this.city = data.title;
        },
        (err: any) => console.log(err),
        () => {
          console.log("postalcode Data Recived");
        }
      );
  }


  processPayment() {
    this.customerBilling.cardNumber = $('#cc-number').val();
    this.customerBilling.cVV = $('#cc-ccv').val();
    this.customerBilling.expMm = $('#cc-expiration-month').val();
    let expYy: string = $('#cc-expiration-year').val();

    try {
      console.log(expYy);
    } catch (e) {

    }

    this.customerBilling.expYy = expYy.slice(-2);;

    this.dataService.updateBilling(this.customerBilling)
      .subscribe(
        (data: IResponseClass) => {
          //let message = data.msg;
          //let action = "Ok";
          //this._snackBar.open(message, action, {
          //  duration: 2000, verticalPosition: 'top'
          //});

         

          if (data.result === "Approved" || data.result.includes('Successfully')) {

            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: data.result,
              showConfirmButton: false,
              timer: 5000
            });
            if (isPlatformBrowser(this._platformId)) {
              $('#cc-number').val("");
              $('#cc-ccv').val("");
              $('#cc-expiration-month').val("");
              $('#cc-expiration-year').val("");
              $('#cc-holder-name').val("");
            }
            this.city = "";
            this.customerBilling.franchisePostalCode = "";

            let cFC = localStorage.getItem('comingFromCheckout');

            if (cFC == 'yes') {
              localStorage.removeItem('comingFromCheckout');
              this.router.navigate(["/checkout"]);
            }
            else
            {
              this.router.navigate(["/billingProfiles"]);
            }
          }
          else
          {
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: data.result,
              showConfirmButton: false,
              timer: 5000
            });
          }
        },
        (err: any) => console.log(err),
        () => {
          console.log("Data Recived");
        }
      );
  }

}



