import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { ICatagoryWithProduct, RentalProductsCatagory, IKitCatagory, IStandardProductCatagory } from '../shared/Interfaces/ILogin';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../shared/data.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BannerComponent } from '../banner/banner.component';

declare var $: any;

@Component({
  selector: 'app-adword',
  templateUrl: './adword.component.html',
  styleUrls: ['./adword.component.css']
})
export class AdwordComponent implements OnInit {

  //@ViewChildren('singleRangeIdentifier') singleRangeChildComponents: QueryList<SingleRangeComponent>;

  CatagoryModel: ICatagoryWithProduct;

  constructor(private route: ActivatedRoute, private router: Router, private dataService: DataService, private ngxLoader: NgxUiLoaderService) {
    dataService.getCurrentCart('adword');
  }

  IsPageLoaded = false;
  franchiseId;
  catagoryId;

  showRentals = true;
  showStandard = false;
  showKits = false;

  showRental() {
    this.showRentals = true;
    this.showStandard = false;
    this.showKits = false;
  }

  showKit() {
    this.showRentals = false;
    this.showStandard = false;
    this.showKits = true;
  }

  showStandards() {
    this.showRentals = false;
    this.showStandard = true;
    this.showKits = false;
  }

  selectProduct(item: RentalProductsCatagory): void {


    let prodName = item.shortName.replace(/\s/g, '').trim();

    if (item.isWeeklyProduct == null) {
      item.isWeeklyProduct = false;
    }

    if (item.isWeeklyProduct) {
      localStorage.setItem("productIdWeekly", item.productId.toString());
      this.router.navigate(["/fourWeekProduct", prodName, item.productId]);
    } else {
      localStorage.setItem("productId", item.productId.toString());
      this.router.navigate(["/productdetails", this.franchiseName, prodName]);
    }
  }

  selectKProduct(item: IKitCatagory) {
    localStorage.setItem("kproductId", item.packageId.toString());

    let prodName = item.packageName.replace(/\s/g, '').trim();

    this.router.navigate(["/kit", prodName, item.packageId]);
  }

  selectSProduct(item: IStandardProductCatagory) {
    localStorage.setItem("sproductId", item.productId.toString());

    let prodName = item.name.replace(/\s/g, '').trim();

    this.router.navigate(["/standard", prodName, item.productId]);
  }

  franchiseName;
  catagoryName;
  location;

  ngOnInit() {

    this.franchiseName = (this.route.snapshot.params['city']).toString();
    this.catagoryName = (this.route.snapshot.params['catagory']).toString();
    this.location = (this.route.snapshot.params['location']).toString();

    this.ngxLoader.start();
    this.dataService.getCatagoryProductsWNames(this.catagoryName, this.franchiseName)
      .subscribe(
        (data: ICatagoryWithProduct) => {
          if (data == null) {
            this.router.navigate(["/"]);
          } else {
            if (this.franchiseName.toLowerCase() == 'calgary') {
              localStorage.setItem("currentDeliveryCity", this.franchiseName);
              localStorage.setItem("currentPostalCode", "T3k");
              localStorage.setItem("currentDeliveryCharge", "0");
            }
            else if (this.franchiseName.toLowerCase() == 'edmonton') {
              localStorage.setItem("currentDeliveryCity", this.franchiseName);
              localStorage.setItem("currentPostalCode", "T5C");
              localStorage.setItem("currentDeliveryCharge", "0");
            }
            else if (this.franchiseName.toLowerCase() == 'vancouver') {
              localStorage.setItem("currentDeliveryCity", this.franchiseName);
              localStorage.setItem("currentPostalCode", "V7P");
              localStorage.setItem("currentDeliveryCharge", "0");
            }
            else if (this.franchiseName.toLowerCase() == 'guelph') {
              localStorage.setItem("currentDeliveryCity", this.franchiseName);
              localStorage.setItem("currentPostalCode", "N1C");
              localStorage.setItem("currentDeliveryCharge", "0");
            }
            else if (this.franchiseName.toLowerCase() == 'mississauga') {
              localStorage.setItem("currentDeliveryCity", this.franchiseName);
              localStorage.setItem("currentPostalCode", "L4X");
              localStorage.setItem("currentDeliveryCharge", "0");
            }
            this.CatagoryModel = data;
            this.IsPageLoaded = true;
          }
          this.ngxLoader.stop();
        },
        (err: any) => {
          this.ngxLoader.stop();
          console.log(err);
        },
        () => {
          console.log("postalcode Data Recived");
        }
    );

    $(document).ready(function () {

      var color_active = "#44B1E4";
      var color_inactive = " #8BC400";

      $('#StandardProducts').hide();
      $('#Kits').hide();

      $('#rentalbtn').click(function (e) {
        $('#Kits').hide();
        $('#StandardProducts').hide();
        $('#RentalProducts').show();

        $('#rentalbtn').css("background-color", color_active);
        $('#kitbtn').css("background-color", color_inactive);
        $('#standardbtn').css("background-color", color_inactive);

      });

      $('#standardbtn').click(function (e) {
        $('#Kits').hide();
        $('#StandardProducts').show();
        $('#RentalProducts').hide();

        $('#rentalbtn').css("background-color", color_inactive);
        $('#kitbtn').css("background-color", color_inactive);
        $('#standardbtn').css("background-color", color_active);

        $('.animation').css("opacity", "100");

      });

      $('#kitbtn').click(function (e) {
        $('#Kits').show();
        $('#StandardProducts').hide();
        $('#RentalProducts').hide();

        $('#rentalbtn').css("background-color", color_inactive);
        $('#kitbtn').css("background-color", color_active);
        $('#standardbtn').css("background-color", color_inactive);
      });
    });
  }

}
