import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBar, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder } from '@angular/forms';
import { DataService } from '../../shared/data.service';

@Component({
  selector: 'app-decline-lwddialog',
  templateUrl: './decline-lwddialog.component.html',
  styleUrls: ['./decline-lwddialog.component.css']
})
export class DeclineLWDDialogComponent implements OnInit {

  constructor(private _snackBar: MatSnackBar, public fb: FormBuilder, private dataService: DataService,
    private dialogRef: MatDialogRef<DeclineLWDDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
  }

  ngOnInit(): void {
  }

  acceptLDW(): void {
    this.dialogRef.close(true);
  }

  declineLDW(): void {
    this.dialogRef.close(false);
  }

}
