import { Component, OnInit, Inject } from '@angular/core';
import { ResponseClassWCodeAddress, ResponseClass, DeliveryAddress } from '../../../shared/Interfaces/ILogin';
import { NgForm, FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatSnackBar, MatDialogRef } from '@angular/material';
import { DataService } from '../../../shared/data.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-add-delivery-address-order',
  templateUrl: './add-delivery-address-order.component.html',
  styleUrls: ['./add-delivery-address-order.component.css']
})
export class AddDeliveryAddressOrderComponent implements OnInit {

  address: DeliveryAddress = new DeliveryAddress();

  form: FormGroup;

  driverName: string;

  driverControl = new FormControl('', [Validators.required]);

  customerId: number;
  franchsieId: number;

  PostalCodeDNE = false;

  response;

  orderId;

  addressId;

  constructor(private titleService: Title, private _snackBar: MatSnackBar, public fb: FormBuilder, private dataService: DataService,
    private dialogRef: MatDialogRef<AddDeliveryAddressOrderComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
    this.customerId = data.customerId;
    this.franchsieId = data.franchsieId;
    this.orderId = data.orderId;
    this.titleService.setTitle("TheRentalGuys.Ca - Add Delivery Address");

  }

  ngOnInit() {

    //this.allDrivers = JSON.parse(localStorage.getItem('driversList'));

    this.address.fullName = "";

    this.form = this.fb.group({
      driver: this.address
    });
  }

  onSubmit(form: NgForm) {
    if (this.address.fullName == undefined || this.address.fullName == '') {
      let message = "Please enter fullname";
      let action = "Ok";
      this._snackBar.open(message, action, {
        duration: 20000,
      });
    }
    else if (this.address.addressLine1 == undefined || this.address.addressLine1 == '') {
      let message = "Please enter addressLine 1";
      let action = "Ok";
      this._snackBar.open(message, action, {
        duration: 20000,
      });
    }
    else if (this.address.city == undefined || this.address.city == '') {
      let message = "Please enter city";
      let action = "Ok";
      this._snackBar.open(message, action, {
        duration: 20000,
      });
    }
    else if (this.address.stateProvince == undefined || this.address.stateProvince == '') {
      let message = "Please enter State/Province";
      let action = "Ok";
      this._snackBar.open(message, action, {
        duration: 20000,
      });
    }
    else {

      this.saveChanges()
    }
  }

  saveChanges(): void {

    this.address.customerId = this.customerId;
    this.address.franchiseId = this.franchsieId;
    this.dataService.addCustomerDeliveryAddressOrder(this.address).subscribe(
      (data: ResponseClassWCodeAddress) => {

        if (data.code == 200) {
          this.addressId = data.addressId;
          this.updateOrderDeliveryAddress();
          //this.dialogRef.close(this.form.value);
          this.PostalCodeDNE = false;
        }
        else {
          this.PostalCodeDNE = true;

          this.response = data.result;

          let message = data.result;
          let action = "Ok";
          this._snackBar.open(message, action, {
            duration: 20000,
          });
        }
      },
      (err: any) => console.log(err)
    );
  }

  updateOrderDeliveryAddress() {

    this.dataService.selectOrderDeliveryAddress(this.orderId, this.addressId)
      .subscribe(
        (data: ResponseClass) => {
          this.dialogRef.close(true);
        },
        (err: any) => console.log(err),
        () => {
          console.log("units Data Recived");
        }
      );
  }

}
