import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../shared/data.service';
import { SecurityService } from '../security/security.service';
import { ICustomer, ICustomerEmails } from '../shared/Interfaces/ILogin';
import { responseMsg } from '../shared/Interfaces/IInterfaces';
import { CardModule } from 'ngx-card/ngx-card';
import { DialogContentDeleteDialogComponent } from './billing-profiles/dialog-content-delete-dialog/dialog-content-delete-dialog.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Title } from '@angular/platform-browser';

declare var $: any;

@Component({
  selector: 'app-my-dashboard',
  templateUrl: './my-dashboard.component.html',
  styleUrls: ['./my-dashboard.component.css']
})
export class MyDashboardComponent implements OnInit {

  noOfItemsInCart = 0;

  constructor(private titleService: Title, private ngxUiLoaderService: NgxUiLoaderService,private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router, private dataService: DataService,
    private securityService: SecurityService, public dialog: MatDialog) {

    dataService.getCurrentCart('dashboard');
    this.titleService.setTitle("TheRentalGuys.Ca - My Profile");
  }

  customerId;

  newEmail: string;

  customer: ICustomer = new ICustomer();

  ngOnInit() {   

    this.customerId = localStorage.getItem("customerId");

    this.getCustomer();
  }

  getCustomer()
  {
    this.ngxUiLoaderService.start();
    this.dataService.getCustomer(this.customerId)
      .subscribe(
        (data: ICustomer) => {
          this.ngxUiLoaderService.stop();
          this.customer = data;
        },
        (err: any) => console.log(err),
        () => {
          console.log("Data Recived");
        }
      );
  }

  updateCustomerInfo()
  {
    this.dataService.updateCustomer(this.customer)
      .subscribe(
        (data: responseMsg) => {
          let message = data.msg;//"Successfully Updated";
          let action = "Ok";
          this._snackBar.open(message, action, {
            duration: 2000,
          });

          //this.router.navigate(['/ordersDetail/' + this.orderId]);
        },
        (err: any) => console.log(err),
        () => {
          console.log("Data Recived");
        }
      );
  }

  addEmailAddress()
  {

    if (this.newEmail.includes('.') && this.newEmail.includes('@')) {

      let email: ICustomerEmails = new ICustomerEmails();
      email.customerId = this.customerId;
      email.emailAddress = this.newEmail;

      this.dataService.addAdditionalEmail(email)
        .subscribe(
          (data: void) => {
            let message = "Successfully Added";
            let action = "Ok";
            this._snackBar.open(message, action, {
              duration: 2000,
            });
            this.newEmail = "";
            this.getCustomer();
          },
          (err: any) => console.log(err),
          () => {
            console.log("Data Recived");
          }
        );
    }
    else
    {
      let message = "Please enter a valid email.";
      let action = "Ok";
      this._snackBar.open(message, action, {
        duration: 2000,
      });
    }
  }

  deleteBilling(id) {
    this.dataService.deleteCustomerEmail(id)
      .subscribe(
        (data: void) => {
          this.getCustomer();
        },
        (err: any) => console.log(err),
        () => {
          console.log("driver deleted");
        }
      );
  }

  openDeleteDialog(id) {
    const dialogRef = this.dialog.open(DialogContentDeleteDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if (result) {
        this.deleteBilling(id)
      }
    });
  }

}
